import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AuthRoutingModule } from './auth-routing.module';
import { LoginPageComponent } from '../components/login-page/login-page.component';
import { CredentialsService } from './credentials.service';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, NgbModule, AuthRoutingModule, FormsModule, TranslateModule.forChild()],
  declarations: [LoginPageComponent],
  providers: [CredentialsService],
})
export class AuthModule {}
