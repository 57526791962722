import { Component, Input } from '@angular/core';
import { XlsxToJsonService } from '../../services/xlsxToJson.service';
import { finalize } from 'rxjs/operators';
import { TouristLocationsPageService } from '../../../tabs/tourist-locations-view/services/tourist-locations-page.service';
import { AddNewTourLocationModel } from '../../../tabs/tourist-locations-view/models/AddNewTourLocation.model';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-import-tourist-locations',
  templateUrl: './import-tourist-locations.component.html',
  styleUrls: ['./import-tourist-locations.component.scss'],
  standalone: true,
})
export class ImportTouristLocationsComponent {
  loading: boolean;
  @Input() staticInfoType: string;

  constructor(
    private xlsxToJsonService: XlsxToJsonService,
    private touristLocationsPageService: TouristLocationsPageService,
  ) {}

  async importTouristLocation(event: Event): Promise<void> {
    const inputElement = event.target as HTMLInputElement;
    const file = inputElement.files?.[0];
    this.loading = true;

    if (file) {
      const data = await this.xlsxToJsonService.convertExcelToJson<AddNewTourLocationModel>(file);
      if (data) {
        this.touristLocationsPageService
          .importTouristLocation({
            data,
          })
          .pipe(
            finalize(() => {
              this.loading = false;
            }),
          )
          .subscribe({
            next: () => {
              Swal.fire('Успіх!', 'Лоцікації імпортовано!', 'success');
            },
            error: () => {
              Swal.fire('Упс...', 'Помилка при імпорті локацій!', 'error');
            },
          });
      }
    }
  }
}
