import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgForOf, NgIf, NgSwitch, NgSwitchCase } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { StaticInfoConfigModel, StaticInfoTableColumnType } from '../../models/static-info-config.model';
import { IAtmLocationsStaticInfoModel } from '../atm-locations/models/i-atm-locations-static-info.model';
import { Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { StaticInfoService } from '../../shared/static-info.service';
import { takeUntil } from 'rxjs/operators';
import { ImportDataComponent } from '../../../../shared/components/import-data/import-data.component';
import { LocationSelectorComponent } from '../../../../shared/components/location-selector/location-selector.component';
import { NestedAttrRenderPipe } from '../../../../shared/pipes/nested-attr-render.pipe';

@Component({
  selector: 'app-static-info',
  standalone: true,
  templateUrl: 'static-info.component.html',
  styleUrls: ['./static-info.component.scss'],
  imports: [
    NgForOf,
    NgIf,
    TranslateModule,
    NgSwitch,
    NgSwitchCase,
    ImportDataComponent,
    LocationSelectorComponent,
    NestedAttrRenderPipe,
  ],
  providers: [NestedAttrRenderPipe],
})
export class StaticInfoComponent implements OnInit, OnDestroy {
  // inputs
  @Input() staticInfoConfig: StaticInfoConfigModel;

  // state
  loading: boolean;
  countryId: string;
  cityId: string;

  // data
  staticInfo: any[] = [];
  /* data - end */
  protected readonly StaticInfoTableColumnType = StaticInfoTableColumnType;
  // unsubscribe
  private unsubscribe$ = new Subject<void>();

  constructor(
    public dialog: MatDialog,
    private staticInfoService: StaticInfoService,
  ) {}

  /* lifecycle hooks */
  ngOnInit(): void {
    this.getData();
  }

  /* lifecycle hooks - end */

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onCountrySelected(countryId: string): void {
    this.countryId = countryId;
    this.getData();
  }

  onCitySelected(cityId: string): void {
    this.cityId = cityId;
    this.getData();
  }

  /* data */
  getData(): void {
    if (this.countryId && this.cityId) {
      this.staticInfoService
        .getStaticInfo(this.staticInfoConfig.s3Key, this.countryId, this.cityId)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((res: IAtmLocationsStaticInfoModel[]) => (this.staticInfo = res));
    }
  }
}
