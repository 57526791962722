<table class="table table-striped mt-3">
  <thead>
    <tr>
      <th scope="col" class="d-none d-lg-table-cell">{{ 'country-page#uniqueId' | translate }}</th>
      <th scope="col">{{ 'country-page#countryInternalName' | translate }}</th>
      <th scope="col" class="d-none d-md-table-cell">{{ 'country-page#countryNameUk' | translate }}</th>
      <th scope="col" class="d-none d-sm-table-cell">{{ 'country-page#countryNameEn' | translate }}</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let country of countries">
      <th scope="row" class="d-none d-lg-table-cell">{{ country.id }}</th>
      <td>
        {{ country.countryInternalName }}
      </td>
      <td class="d-none d-md-table-cell">{{ country.countryMultilanguageName.uk }}</td>
      <td class="d-none d-sm-table-cell">{{ country.countryMultilanguageName.en }}</td>
    </tr>
  </tbody>
</table>
