import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { API, Storage } from 'aws-amplify';

@Injectable({
  providedIn: 'root',
})
export class ImageService {
  constructor(private router: Router) {}

  getLinkImages(imagesKeys: []): any[] {
    if (!imagesKeys.length) {
      return [];
    }
    const promisesList: any[] = [];
    imagesKeys.forEach((imageKey) => {
      const promise = new Promise((resolve, reject) =>
        Storage.get(imageKey)
          .then((url) => {
            const data = {
              url: url,
              imageKey: imageKey,
            };
            resolve(data);
          })
          .catch((error) => reject(error)),
      );
      promisesList.push(promise);
    });
    return promisesList;
  }
}
