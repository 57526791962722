import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthRoutingModule } from './auth/auth-routing.module';
import { MainRoutingModule } from './components/main-page/main-routing.module';

const routes: Routes = [{ path: '**', redirectTo: '', pathMatch: 'full' }];

@NgModule({
  imports: [RouterModule.forRoot(routes), AuthRoutingModule, MainRoutingModule],
  exports: [RouterModule],
})
export class AppRoutingModule {}
