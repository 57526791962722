import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { SupportedLanguagesModel } from '../../models/i-supported-languages.model';
import { IEventConfigModel } from '../../../tabs/events-page/models/event-config.model';
import { TabViewModule } from 'primeng/tabview';
import { NgForOf, NgSwitch, NgSwitchCase } from '@angular/common';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { LanguageISOCodesType } from '../../models/i-language-iso.model';

export interface EventMultiFields {
  [key: string]: Record<LanguageISOCodesType, string> | undefined;
}

@Component({
  selector: 'app-multi-language-tabs',
  templateUrl: './multi-language-tabs.component.html',
  styleUrls: ['./multi-language-tabs.component.scss'],
  standalone: true,
  imports: [TabViewModule, NgForOf, ReactiveFormsModule, NgSwitch, NgSwitchCase, InputTextModule, InputTextareaModule],
  encapsulation: ViewEncapsulation.None,
})
export class MultiLanguageTabsComponent implements OnInit {
  @Input() supportedLanguages: SupportedLanguagesModel[] = [];
  @Input() multiLanguageConfig: IEventConfigModel[] = [];
  @Output() dataChanged = new EventEmitter<EventMultiFields>();
  multiLanguageForm: FormGroup;
  activeIndex = 0;

  constructor(private fb: FormBuilder) {
    this.multiLanguageForm = this.fb.group({});
  }

  private _initialData: EventMultiFields;

  @Input()
  set initialData(value: EventMultiFields) {
    this._initialData = value;
    if (value) {
      this.multiLanguageForm.patchValue({
        NameMultiLanguage: value.NameMultiLanguage,
        ShortDescriptionMultiLanguage: value.ShortDescriptionMultiLanguage,
        LongDescriptionMultiLanguage: value.LongDescriptionMultiLanguage,
      });
    }
  }

  ngOnInit(): void {
    this.initForm();
  }

  initForm(): void {
    this.multiLanguageConfig.forEach((configItem: IEventConfigModel) => {
      const group = new FormGroup({});
      this.supportedLanguages.forEach((language: SupportedLanguagesModel) => {
        const controlData = this.initialData?.[`${configItem.Control}`];
        const languageCode = language.code as LanguageISOCodesType;
        const initialValue = controlData?.[languageCode] || '';
        const control = new FormControl(initialValue);
        group.addControl(language.code, control);
      });
      this.multiLanguageForm.addControl(configItem.Control, group);
    });
  }

  updateParentFormField(): void {
    this.dataChanged.emit(this.multiLanguageForm.value);
  }
}
