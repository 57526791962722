import { Component, OnInit } from '@angular/core';
import { Users } from '../../models/users';
import { RegisteredUsersPageService } from '../../registered-users-page.service';

@Component({
  selector: 'app-mobile-users',
  templateUrl: './mobile-users.component.html',
  styleUrls: ['./mobile-users.component.scss'],
})
export class MobileUsersComponent implements OnInit {
  public mobileUsers: Users[] = [];

  constructor(private registeredUsersPageService: RegisteredUsersPageService) {}

  ngOnInit() {
    this.getMobileUsers();
  }

  getMobileUsers() {
    this.registeredUsersPageService.getMobileUsers().subscribe((res) => {
      this.mobileUsers = res;
    });
  }
}
