import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RestService } from './services/restService.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MapToIterable } from './pipes/mapToIterable.pipe';
import { AuthModule } from './auth';
import { ShellModule } from './components/shell/shell.module';
import { MainModule } from './components/main-page/main.module';
import { ExcelService } from './services/exel.service';
import { EventsService } from './services/events.service';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialogModule } from '@angular/material/dialog';
import * as Sentry from '@sentry/angular';
import { Router } from '@angular/router';
import { TabsModule } from 'ngx-bootstrap/tabs';

@NgModule({ declarations: [AppComponent, MapToIterable],
    bootstrap: [AppComponent], imports: [BrowserModule,
        AppRoutingModule,
        NgbModule,
        FormsModule,
        AuthModule,
        MainModule,
        ShellModule,
        TabsModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
            useDefaultLang: false,
        }),
        ReactiveFormsModule,
        MatDialogModule,
        BrowserAnimationsModule], providers: [
        RestService,
        ExcelService,
        EventsService,
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: true,
            }),
        },
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => { },
            deps: [Sentry.TraceService],
            multi: true,
        },
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule {}

export function HttpLoaderFactory(http: HttpClient): TranslateLoader {
  return new TranslateHttpLoader(http, '../assets/i18n/', '.json');
}
