import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MainPageTabs } from '../../enums/mainPage.tabs';
import { Location } from '@angular/common';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-main-page',
  templateUrl: './main-page.component.html',
  styleUrls: ['./main-page.component.scss'],
})
export class MainPageComponent implements OnInit, OnDestroy {
  public activeIdString = MainPageTabs[0];

  private subscription: Subscription = new Subscription();

  constructor(
    private router: ActivatedRoute,
    private location: Location,
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.router.queryParams.subscribe((params) => {
        if (params.activeTab) {
          this.activeIdString = params.activeTab;
        } else {
          this.location.replaceState(`/main?activeTab=${this.activeIdString}`);
        }
      }),
    );
  }

  updateUrl(event) {
    this.location.replaceState(`/main?activeTab=${event.nextId}`);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
