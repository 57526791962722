import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';

@Injectable({
  providedIn: 'root',
})
export class ExcelService {
  constructor() {}

  public importFromFile(bstr: string): XLSX.AOA2SheetOpts {
    /* read workbook */
    const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

    /* grab first sheet */
    const wsname: string = wb.SheetNames[0];
    const ws: XLSX.WorkSheet = wb.Sheets[wsname];

    /* save data */
    const data = XLSX.utils.sheet_to_json(ws) as XLSX.AOA2SheetOpts;

    return data;
  }

  // tslint:disable-next-line:variable-name
  public exportToFile(fileName: string, json: any) {
    /* create a new blank workbook */
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(json);

    /* Add the worksheet to the workbook */
    XLSX.utils.book_append_sheet(wb, ws, 'exported_places');
    XLSX.writeFile(wb, fileName + '.xlsxToJson.service.ts');
  }
}
