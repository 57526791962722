<app-header></app-header>

<div class="page-header">
  <h4 *ngIf="eventData" class="modal-title" id="modal-basic-title">
    {{ (eventData ? 'events-add#eventEditPage' : 'events-add#eventAddPage') | translate }}
  </h4>
</div>

<form [formGroup]="eventForm">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg p-4">
        <label class="mb-3 mt-3 form-group d-block" for="InternalName"
          >{{ 'events-add#eventInternalName' | translate }}
          <input
            [ngClass]="{ error: eventForm.controls.InternalName.invalid &&  eventForm.controls.InternalName.touched}"
            autocomplete="off"
            class="form-control"
            formControlName="InternalName"
            id="InternalName"
            type="text"
          />
        </label>
        <app-multi-language-tabs
          (dataChanged)="handleMultiLanguageDataChanged($event)"
          [initialData]="multiLanguageInitialData"
          [multiLanguageConfig]="multiLanguageTabs"
          [supportedLanguages]="supportedLanguagesConfig"
        ></app-multi-language-tabs>
        <div class="d-flex row pl-3 mt-3 mb-3">
          <label class="form-group d-block width-fit-content mr-3" for="Type"
            >{{ 'events-add#eventType' | translate }}
            <select class="form-control mt-1" formControlName="Type" id="Type">
              <option value="news">news</option>
              <option value="action">action</option>
              <option value="event">event</option>
            </select>
          </label>
          <label class="form-group d-block width-fit-content mr-3"
            >{{ 'events-add#eventActive' | translate }}
            <select
              [ngClass]="{ error: eventForm.controls.Active.invalid }"
              class="form-control mt-1"
              formControlName="Active"
            >
              <option [ngValue]="true">{{ 'events-add#eventActiveTrue' | translate }}</option>
              <option [ngValue]="false">{{ 'events-add#eventActiveFalse' | translate }}</option>
            </select>
          </label>
          <app-location-selector
            (citySelected)="onCitySelected($event)"
            (countrySelected)="onCountrySelected($event)"
            class="mt-3"
          />
        </div>
      </div>
      <div class="col-lg p-4">
        <div class="d-flex row p-3">
          <label class="mb-3 mr-3 form-group d-block width-fit-content" for="EventStartDate"
            >{{ 'events-add#eventDate' | translate }}
            <input
              [ngClass]="{ error: eventForm.controls.EventStartDate.invalid  && eventForm.controls.EventStartDate.touched}"
              autocomplete="off"
              class="form-control"
              formControlName="EventStartDate"
              id="EventStartDate"
              type="date"
            />
          </label>
          <label class="mb-3 mr-3 form-group d-block width-fit-content" for="EventStartTime"
            >{{ 'events-add#eventTime' | translate }}
            <input
              [ngClass]="{ error: eventForm.controls.EventStartTime.invalid && eventForm.controls.EventStartTime.touched}"
              autocomplete="off"
              class="form-control"
              formControlName="EventStartTime"
              id="EventStartTime"
              type="time"
            />
          </label>
          <label class="mb-3 form-group d-block" for="EventDuration"
            >{{ 'events-add#eventDuration' | translate }}
            <input
              [ngClass]="{ error: eventForm.controls.EventDuration.invalid }"
              autocomplete="off"
              class="form-control"
              formControlName="EventDuration"
              id="EventDuration"
              type="text"
            />
          </label>
        </div>
        <div class="d-flex row pl-3">
          <label class="mb-3 mt-2 mr-3 form-group d-block width-fit-content" for="EventPrice"
            >{{ 'events-add#eventPrice' | translate }}
            <input
              [ngClass]="{ error: eventForm.controls.EventPrice.invalid }"
              autocomplete="off"
              class="form-control"
              formControlName="EventPrice"
              id="EventPrice"
              type="text"
            />
          </label>

          <label class="mb-3 mt-2 form-group d-block width-fit-content"
            >{{ 'events-add#eventCurrency' | translate }}
            <select
              [ngClass]="{ error: eventForm.controls.EventCurrency.invalid }"
              class="form-control mt-1"
              formControlName="EventCurrency"
            >
              <option *ngFor="let currency of supportedCurrencies" [value]="currency.value">
                {{ currency.title }}
              </option>
            </select>
          </label>
        </div>
        <div class="py-3">
          <p><b>{{ 'events-add#eventImage' | translate }}</b></p>
          <app-image-upload
            (imageDeleted)="handleImageDeleted($event)"
            (imagesChanged)="handleImagesChanged($event)"
            [images]="eventImages || []"
          />
        </div>

        <div *ngIf="imageSignedURL" class="py-3">
          <p><b>Зображення заходу</b></p>
          <img alt="event image" class="event-image" src="{{imageSignedURL}}" />
        </div>

        <label class="mb-3 mt-3 form-group d-block" for="EventLink"
          >{{ 'events-add#eventUrl' | translate }}
          <input autocomplete="off" class="form-control" formControlName="EventLink" id="EventLink" type="text" />
        </label>
        <h5>{{ 'events-add#eventSocialLinks' | translate }}</h5>
        <div *ngFor="let item of EventSocialLinks.controls; let i = index" formArrayName="EventSocialLinks">
          <div [formGroupName]="i">
            <label class="mb-3 mt-3 form-group d-block" for="SocialNetworkTitle"
              >{{ 'events-add#eventSocialNetworkTitle' | translate }}
              <input
                autocomplete="off"
                class="form-control"
                formControlName="SocialNetworkTitle"
                id="SocialNetworkTitle"
                type="text"
              />
            </label>
            <label class="mb-3 mt-3 form-group d-block" for="SocialNetworkLink"
              >{{ 'events-add#eventSocialNetworkLink' | translate }}
              <input
                autocomplete="off"
                class="form-control"
                formControlName="SocialNetworkLink"
                id="SocialNetworkLink"
                type="text"
              />
            </label>
          </div>
        </div>
        <button (click)="addSocialLinksToForm()" class="btn btn-primary">
          {{ 'events-add#eventBtnAddSocialLinks' | translate }}
        </button>
      </div>
    </div>
  </div>
</form>

<div class="page-footer mt-5 mb-3 d-flex text-center justify-content-center">
  <button
    [queryParams]="{ activeTab: 'eventTab' }"
    [routerLink]="'/main'"
    class="d-block btn btn-outline-primary"
    type="button"
  >
    {{ 'events-add#eventBtnCencel' | translate }}
  </button>
  <button (click)="updateEvent()" *ngIf="eventData" class="ml-3 d-block btn btn-company btn-primary" type="button">
    {{ 'events-add#eventBtnSaveChange' | translate }}
  </button>
  <button (click)="saveEvent()" *ngIf="!eventData" class="ml-3 d-block btn btn-company btn-primary" type="button">
    {{ 'events-add#eventBtnSaveNew' | translate }}
  </button>
</div>
