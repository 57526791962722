<form [formGroup]="eventSearchForm">
  <div class="col-9 p-3">
    <div class="d-flex row">
      <label for="searchId" class="form-group d-block width-fit-content mr-3">
        {{ 'events-page#filterId' | translate }}
        <input type="text" id="searchId" class="form-control" formControlName="searchId" autocomplete="off" />
      </label>
      <label for="searchEventName" class="form-group d-block width-fit-content mr-3">
        {{ 'events-page#filterInternalName' | translate }}
        <input
          type="text"
          id="searchEventName"
          class="form-control"
          formControlName="searchEventName"
          autocomplete="off"
        />
      </label>
      <app-location-selector class="-mt-3" (citySelected)="onCitySelected($event)" (countrySelected)="onCountrySelected($event)" />
      <label for="itemsPerPage" class="form-group d-block width-fit-content mr-3">
        {{ 'events-page#eventNumberEvents' | translate }}
        <select class="form-control" formControlName="itemsPerPage" id="itemsPerPage">
          <option>{{ 'events-page#eventAll' | translate }}</option>
          <option>10</option>
          <option>20</option>
          <option>50</option>
        </select>
      </label>
      <label for="searchEventType" class="form-group d-block width-fit-content mr-3">
        Фільтрація по типу
        <select class="form-control" formControlName="searchEventType" id="searchEventType">
          <option>всі заходи</option>
          <option>news</option>
          <option>action</option>
          <option>event</option>
        </select>
      </label>
    </div>
  </div>
</form>

<div class="d-flex justify-content-start mb-3">
  <button class="btn btn-primary" (click)="addNewEvent()">Додати подію</button>
</div>

<table class="table table-striped mt-3">
  <thead>
    <tr>
      <th scope="col" class="d-none d-lg-table-cell">{{ 'events-page#uniqueId' | translate }}</th>
      <th scope="col">{{ 'events-page#eventInternalName' | translate }}</th>
      <th scope="col" class="d-none d-sm-table-cell">Тип заходу</th>
      <th scope="col" class="d-none d-sm-table-cell">{{ 'events-page#eventActive' | translate }}</th>
      <th scope="col">{{ 'events-page#eventActions' | translate }}</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let event of filteredEvents">
      <th scope="row" class="d-none d-lg-table-cell" [ngClass]="{ 'disabled-row': !event.Active }">{{ event.Id }}</th>
      <td [ngClass]="{ 'disabled-row': !event.Active }">{{ event.InternalName }}</td>
      <td [ngClass]="{ 'disabled-row': !event.Active }" class="d-none d-sm-table-cell">{{ event.Type }}</td>
      <td [ngClass]="{ 'disabled-row': !event.Active }" class="d-none d-sm-table-cell">
        {{ event.Active ? 'Активний' : 'Неактивний' }}
      </td>
      <td>
        <span *ngIf="event.Active" class="btn btn-primary btn-company" (click)="toggleActive(event)">
          {{ 'events-page#eventActiveFalse' | translate }}
        </span>
        <span
          *ngIf="!event.Active"
          class="btn btn-primary btn-company"
          [ngClass]="{ 'disabled-row': !event.Active }"
          (click)="toggleActive(event)"
        >
          {{ 'events-page#eventActiveTrue' | translate }}
        </span>
        <span class="btn" (click)="eventEdit(event)" [ngClass]="{ 'disabled-row': !event.Active }">
          {{ 'events-page#eventEdit' | translate }}
        </span>
      </td>
    </tr>
  </tbody>
</table>

<ngb-pagination
  *ngIf="ngbPagination"
  class="d-flex justify-content-center"
  [collectionSize]="collectionSize"
  [pageSize]="itemsPerPage"
  [(page)]="page"
  [boundaryLinks]="true"
  (pageChange)="paginationEvents()"
>
</ngb-pagination>

