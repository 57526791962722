import { Injectable } from '@angular/core';
import { from, Observable, throwError } from 'rxjs';
import { API } from 'aws-amplify';
import { catchError } from 'rxjs/operators';
import { IImportStaticInfoDataModel } from '../models/IAddEditStaticInfoData.model';

@Injectable()
export class StaticInfoService {
  importStaticInfo(staticInfo: IImportStaticInfoDataModel): Observable<any> {
    return from(
      API.post('cityOnPalm-service', '/admin/staticInfo/import', {
        body: staticInfo,
      }),
    ).pipe(
      catchError((err) => {
        console.error(err);
        return throwError(err);
      }),
    );
  }

  getStaticInfo(type: string, countryId: string, cityId: string): Observable<any> {
    return from(
      API.get(
        'cityOnPalm-service',
        `/admin/staticInfo/getData?type=${type}&CountryId=${countryId}&CityId=${cityId}`,
        {},
      ),
    ).pipe(
      catchError((err) => {
        console.error(err);
        return throwError(err);
      }),
    );
  }
}
