var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
      label: 0,
      sent: function () {
        if (t[0] & 1) throw t[1];
        return t[1];
      },
      trys: [],
      ops: []
    },
    f,
    y,
    t,
    g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;
  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }
  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");
    while (g && (g = 0, op[0] && (_ = 0)), _) try {
      if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
      if (y = 0, t) op = [op[0] & 2, t.value];
      switch (op[0]) {
        case 0:
        case 1:
          t = op;
          break;
        case 4:
          _.label++;
          return {
            value: op[1],
            done: false
          };
        case 5:
          _.label++;
          y = op[1];
          op = [0];
          continue;
        case 7:
          op = _.ops.pop();
          _.trys.pop();
          continue;
        default:
          if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
            _ = 0;
            continue;
          }
          if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
            _.label = op[1];
            break;
          }
          if (op[0] === 6 && _.label < t[1]) {
            _.label = t[1];
            t = op;
            break;
          }
          if (t && _.label < t[2]) {
            _.label = t[2];
            _.ops.push(op);
            break;
          }
          if (t[2]) _.ops.pop();
          _.trys.pop();
          continue;
      }
      op = body.call(thisArg, _);
    } catch (e) {
      op = [6, e];
      y = 0;
    } finally {
      f = t = 0;
    }
    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};
var __read = this && this.__read || function (o, n) {
  var m = typeof Symbol === "function" && o[Symbol.iterator];
  if (!m) return o;
  var i = m.call(o),
    r,
    ar = [],
    e;
  try {
    while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
  } catch (error) {
    e = {
      error: error
    };
  } finally {
    try {
      if (r && !r.done && (m = i["return"])) m.call(i);
    } finally {
      if (e) throw e.error;
    }
  }
  return ar;
};
var __spreadArray = this && this.__spreadArray || function (to, from, pack) {
  if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
    if (ar || !(i in from)) {
      if (!ar) ar = Array.prototype.slice.call(from, 0, i);
      ar[i] = from[i];
    }
  }
  return to.concat(ar || Array.prototype.slice.call(from));
};
import { Logger } from '@aws-amplify/core';
import { createMultipartUpload, uploadPart, listObjectsV2, listParts, completeMultipartUpload, abortMultipartUpload } from '../AwsClients/S3';
import { isCancelError, CANCELED_ERROR_MESSAGE } from '../AwsClients/S3/utils';
import { calculatePartSize, DEFAULT_PART_SIZE, DEFAULT_QUEUE_SIZE, MAX_OBJECT_SIZE } from '../common/S3ClientUtils';
import { byteLength, isFile } from '../common/StorageUtils';
import { UPLOADS_STORAGE_KEY } from '../common/StorageConstants';
var logger = new Logger('AWSS3UploadTask');
export var AWSS3UploadTaskState;
(function (AWSS3UploadTaskState) {
  AWSS3UploadTaskState[AWSS3UploadTaskState["INIT"] = 0] = "INIT";
  AWSS3UploadTaskState[AWSS3UploadTaskState["IN_PROGRESS"] = 1] = "IN_PROGRESS";
  AWSS3UploadTaskState[AWSS3UploadTaskState["PAUSED"] = 2] = "PAUSED";
  AWSS3UploadTaskState[AWSS3UploadTaskState["CANCELLED"] = 3] = "CANCELLED";
  AWSS3UploadTaskState[AWSS3UploadTaskState["COMPLETED"] = 4] = "COMPLETED";
})(AWSS3UploadTaskState || (AWSS3UploadTaskState = {}));
export var TaskEvents;
(function (TaskEvents) {
  TaskEvents["CANCEL"] = "cancel";
  TaskEvents["UPLOAD_COMPLETE"] = "uploadComplete";
  TaskEvents["UPLOAD_PROGRESS"] = "uploadPartProgress";
  TaskEvents["ERROR"] = "error";
})(TaskEvents || (TaskEvents = {}));
function comparePartNumber(a, b) {
  return a.PartNumber - b.PartNumber;
}
var AWSS3UploadTask = /** @class */function () {
  function AWSS3UploadTask(_a) {
    var s3Config = _a.s3Config,
      file = _a.file,
      emitter = _a.emitter,
      storage = _a.storage,
      params = _a.params,
      level = _a.level,
      prefixPromise = _a.prefixPromise;
    this.queueSize = DEFAULT_QUEUE_SIZE;
    this.partSize = DEFAULT_PART_SIZE;
    this.inProgress = [];
    this.completedParts = [];
    this.queued = [];
    this.bytesUploaded = 0;
    this.totalBytes = 0;
    this.state = AWSS3UploadTaskState.INIT;
    this.prefixPromise = prefixPromise;
    this.s3Config = s3Config;
    this.storage = storage;
    this.storageSync = Promise.resolve();
    if (typeof this.storage['sync'] === 'function') {
      this.storageSync = this.storage['sync']();
    }
    this.params = params;
    this.file = file;
    this.totalBytes = this.file.size;
    this.bytesUploaded = 0;
    this.emitter = emitter;
    this.queued = [];
    this.fileId = this._getFileId(level);
    this._validateParams();
    // event emitter will re-throw an error if an event emits an error unless there's a listener, attaching a no-op
    // function to it unless user adds their own onError callback
    this.emitter.on(TaskEvents.ERROR, function () {});
  }
  Object.defineProperty(AWSS3UploadTask.prototype, "percent", {
    get: function () {
      return this.bytesUploaded / this.totalBytes * 100;
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(AWSS3UploadTask.prototype, "isInProgress", {
    get: function () {
      return this.state === AWSS3UploadTaskState.IN_PROGRESS;
    },
    enumerable: false,
    configurable: true
  });
  AWSS3UploadTask.prototype._listSingleFile = function (_a) {
    var key = _a.key,
      bucket = _a.bucket;
    return __awaiter(this, void 0, void 0, function () {
      var objectKeyPrefix, _b, Contents, obj;
      return __generator(this, function (_c) {
        switch (_c.label) {
          case 0:
            return [4 /*yield*/, this.prefixPromise];
          case 1:
            objectKeyPrefix = _c.sent();
            return [4 /*yield*/, listObjectsV2(this.s3Config, {
              Bucket: bucket,
              Prefix: objectKeyPrefix + key
            })];
          case 2:
            _b = _c.sent().Contents, Contents = _b === void 0 ? [] : _b;
            obj = Contents.find(function (o) {
              return o.Key === "".concat(objectKeyPrefix).concat(key);
            });
            return [2 /*return*/, obj];
        }
      });
    });
  };
  AWSS3UploadTask.prototype._getFileId = function (level) {
    // We should check if it's a File first because File is also instance of a Blob
    if (isFile(this.file)) {
      return [this.file.name, this.file.lastModified, this.file.size, this.file.type, this.params.Bucket, level, this.params.Key].join('-');
    } else {
      return [this.file.size, this.file.type, this.params.Bucket, level, this.params.Key].join('-');
    }
  };
  AWSS3UploadTask.prototype._findCachedUploadParts = function () {
    return __awaiter(this, void 0, void 0, function () {
      var uploadRequests, cachedUploadFileData, _a, Parts, _b, _c;
      var _d;
      return __generator(this, function (_e) {
        switch (_e.label) {
          case 0:
            return [4 /*yield*/, this._listCachedUploadTasks()];
          case 1:
            uploadRequests = _e.sent();
            if (Object.keys(uploadRequests).length === 0 || !Object.prototype.hasOwnProperty.call(uploadRequests, this.fileId)) {
              return [2 /*return*/, {
                parts: [],
                uploadId: null
              }];
            }
            cachedUploadFileData = uploadRequests[this.fileId];
            cachedUploadFileData.lastTouched = Date.now();
            this.storage.setItem(UPLOADS_STORAGE_KEY, JSON.stringify(uploadRequests));
            _b = listParts;
            _c = [this.s3Config];
            _d = {
              Bucket: this.params.Bucket
            };
            return [4 /*yield*/, this.prefixPromise];
          case 2:
            return [4 /*yield*/, _b.apply(void 0, _c.concat([(_d.Key = _e.sent() + this.params.Key, _d.UploadId = cachedUploadFileData.uploadId, _d)]))];
          case 3:
            _a = _e.sent().Parts, Parts = _a === void 0 ? [] : _a;
            return [2 /*return*/, {
              parts: Parts,
              uploadId: cachedUploadFileData.uploadId
            }];
        }
      });
    });
  };
  AWSS3UploadTask.prototype._emitEvent = function (event, payload) {
    this.emitter.emit(event, payload);
  };
  AWSS3UploadTask.prototype._validateParams = function () {
    if (this.totalBytes > MAX_OBJECT_SIZE) {
      throw new Error("File size bigger than S3 Object limit of 5TB, got ".concat(this.totalBytes, " Bytes"));
    }
  };
  AWSS3UploadTask.prototype._listCachedUploadTasks = function () {
    return __awaiter(this, void 0, void 0, function () {
      var tasks;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            return [4 /*yield*/, this.storageSync];
          case 1:
            _a.sent();
            tasks = this.storage.getItem(UPLOADS_STORAGE_KEY) || '{}';
            return [2 /*return*/, JSON.parse(tasks)];
        }
      });
    });
  };
  AWSS3UploadTask.prototype._cache = function (fileMetadata) {
    return __awaiter(this, void 0, void 0, function () {
      var uploadRequests;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            return [4 /*yield*/, this._listCachedUploadTasks()];
          case 1:
            uploadRequests = _a.sent();
            uploadRequests[this.fileId] = fileMetadata;
            this.storage.setItem(UPLOADS_STORAGE_KEY, JSON.stringify(uploadRequests));
            return [2 /*return*/];
        }
      });
    });
  };
  AWSS3UploadTask.prototype._isCached = function () {
    return __awaiter(this, void 0, void 0, function () {
      var _a, _b;
      return __generator(this, function (_c) {
        switch (_c.label) {
          case 0:
            _b = (_a = Object.prototype.hasOwnProperty).call;
            return [4 /*yield*/, this._listCachedUploadTasks()];
          case 1:
            return [2 /*return*/, _b.apply(_a, [_c.sent(), this.fileId])];
        }
      });
    });
  };
  AWSS3UploadTask.prototype._removeFromCache = function () {
    return __awaiter(this, void 0, void 0, function () {
      var uploadRequests;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            return [4 /*yield*/, this._listCachedUploadTasks()];
          case 1:
            uploadRequests = _a.sent();
            delete uploadRequests[this.fileId];
            this.storage.setItem(UPLOADS_STORAGE_KEY, JSON.stringify(uploadRequests));
            return [2 /*return*/];
        }
      });
    });
  };
  AWSS3UploadTask.prototype._onPartUploadCompletion = function (_a) {
    var eTag = _a.eTag,
      partNumber = _a.partNumber,
      chunk = _a.chunk;
    return __awaiter(this, void 0, void 0, function () {
      return __generator(this, function (_b) {
        this.completedParts.push({
          ETag: eTag,
          PartNumber: partNumber
        });
        this.bytesUploaded += byteLength(chunk);
        this._emitEvent(TaskEvents.UPLOAD_PROGRESS, {
          loaded: this.bytesUploaded,
          total: this.totalBytes
        });
        // Remove the completed item from the inProgress array
        this.inProgress = this.inProgress.filter(function (job) {
          return job.uploadPartInput.PartNumber !== partNumber;
        });
        if (this.queued.length && this.state !== AWSS3UploadTaskState.PAUSED) this._startNextPart();
        if (this._isDone()) this._completeUpload();
        return [2 /*return*/];
      });
    });
  };
  AWSS3UploadTask.prototype._completeUpload = function () {
    return __awaiter(this, void 0, void 0, function () {
      var _a, _b, err_1;
      var _c;
      return __generator(this, function (_d) {
        switch (_d.label) {
          case 0:
            _d.trys.push([0, 4,, 5]);
            _a = completeMultipartUpload;
            _b = [this.s3Config];
            _c = {
              Bucket: this.params.Bucket
            };
            return [4 /*yield*/, this.prefixPromise];
          case 1:
            return [4 /*yield*/, _a.apply(void 0, _b.concat([(_c.Key = _d.sent() + this.params.Key, _c.UploadId = this.uploadId, _c.MultipartUpload = {
              // Parts are not always completed in order, we need to manually sort them
              Parts: __spreadArray([], __read(this.completedParts), false).sort(comparePartNumber)
            }, _c)]))];
          case 2:
            _d.sent();
            return [4 /*yield*/, this._verifyFileSize()];
          case 3:
            _d.sent();
            this._emitEvent(TaskEvents.UPLOAD_COMPLETE, {
              key: this.params.Key
            });
            this._removeFromCache();
            this.state = AWSS3UploadTaskState.COMPLETED;
            return [3 /*break*/, 5];
          case 4:
            err_1 = _d.sent();
            logger.error('error completing upload', err_1);
            this._emitEvent(TaskEvents.ERROR, err_1);
            return [3 /*break*/, 5];
          case 5:
            return [2 /*return*/];
        }
      });
    });
  };
  AWSS3UploadTask.prototype._makeUploadPartRequest = function (input, abortSignal) {
    return __awaiter(this, void 0, void 0, function () {
      var res, _a, _b, _c, err_2;
      var _d;
      return __generator(this, function (_e) {
        switch (_e.label) {
          case 0:
            _e.trys.push([0, 4,, 5]);
            _a = uploadPart;
            _b = [__assign(__assign({}, this.s3Config), {
              abortSignal: abortSignal
            })];
            _c = [__assign({}, input)];
            _d = {};
            return [4 /*yield*/, this.prefixPromise];
          case 1:
            return [4 /*yield*/, _a.apply(void 0, _b.concat([__assign.apply(void 0, _c.concat([(_d.Key = _e.sent() + this.params.Key, _d)]))]))];
          case 2:
            res = _e.sent();
            return [4 /*yield*/, this._onPartUploadCompletion({
              eTag: res.ETag,
              partNumber: input.PartNumber,
              chunk: input.Body
            })];
          case 3:
            _e.sent();
            return [3 /*break*/, 5];
          case 4:
            err_2 = _e.sent();
            if (this.state === AWSS3UploadTaskState.PAUSED) {
              logger.log('upload paused');
            } else if (this.state === AWSS3UploadTaskState.CANCELLED) {
              logger.log('upload aborted');
            } else {
              logger.error('error starting next part of upload: ', err_2);
            }
            // xhr transfer handlers' cancel will also throw an error, however we don't need to emit an event in that case as it's an
            // expected behavior
            if (!isCancelError(err_2) && err_2.message !== CANCELED_ERROR_MESSAGE) {
              this._emitEvent(TaskEvents.ERROR, err_2);
              this.pause();
            }
            return [3 /*break*/, 5];
          case 5:
            return [2 /*return*/];
        }
      });
    });
  };
  AWSS3UploadTask.prototype._startNextPart = function () {
    if (this.queued.length > 0 && this.state !== AWSS3UploadTaskState.PAUSED) {
      var abortController = new AbortController();
      var nextPart = this.queued.shift();
      this.inProgress.push({
        uploadPartInput: nextPart,
        s3Request: this._makeUploadPartRequest(nextPart, abortController.signal),
        abortController: abortController
      });
    }
  };
  /**
   * Verify on S3 side that the file size matches the one on the client side.
   *
   * @async
   * @throws throws an error if the file size does not match between local copy of the file and the file on s3.
   */
  AWSS3UploadTask.prototype._verifyFileSize = function () {
    return __awaiter(this, void 0, void 0, function () {
      var valid, obj, e_1;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            _a.trys.push([0, 2,, 3]);
            return [4 /*yield*/, this._listSingleFile({
              key: this.params.Key,
              bucket: this.params.Bucket
            })];
          case 1:
            obj = _a.sent();
            valid = Boolean(obj && obj.Size === this.file.size);
            return [3 /*break*/, 3];
          case 2:
            e_1 = _a.sent();
            logger.log('Could not get file on s3 for size matching: ', e_1);
            // Don't gate verification on auth or other errors
            // unrelated to file size verification
            return [2 /*return*/];
          case 3:
            if (!valid) {
              throw new Error('File size does not match between local file and file on s3');
            }
            return [2 /*return*/];
        }
      });
    });
  };
  AWSS3UploadTask.prototype._isDone = function () {
    return !this.queued.length && !this.inProgress.length && this.bytesUploaded === this.totalBytes;
  };
  AWSS3UploadTask.prototype._createParts = function () {
    var size = this.file.size;
    var parts = [];
    for (var bodyStart = 0; bodyStart < size;) {
      var bodyEnd = Math.min(bodyStart + this.partSize, size);
      parts.push({
        Body: this.file.slice(bodyStart, bodyEnd),
        Key: this.params.Key,
        Bucket: this.params.Bucket,
        PartNumber: parts.length + 1,
        UploadId: this.uploadId
      });
      bodyStart += this.partSize;
    }
    return parts;
  };
  AWSS3UploadTask.prototype._initCachedUploadParts = function (cachedParts) {
    this.bytesUploaded += cachedParts.reduce(function (acc, part) {
      return acc + part.Size;
    }, 0);
    // Find the set of part numbers that have already been uploaded
    var uploadedPartNumSet = new Set(cachedParts.map(function (part) {
      return part.PartNumber;
    }));
    this.queued = this.queued.filter(function (part) {
      return !uploadedPartNumSet.has(part.PartNumber);
    });
    this.completedParts = cachedParts.map(function (part) {
      return {
        PartNumber: part.PartNumber,
        ETag: part.ETag
      };
    });
    this._emitEvent(TaskEvents.UPLOAD_PROGRESS, {
      loaded: this.bytesUploaded,
      total: this.totalBytes
    });
  };
  AWSS3UploadTask.prototype._initMultipartUpload = function () {
    return __awaiter(this, void 0, void 0, function () {
      var res, _a, _b, _c;
      var _d;
      return __generator(this, function (_e) {
        switch (_e.label) {
          case 0:
            _a = createMultipartUpload;
            _b = [this.s3Config];
            _c = [__assign({}, this.params)];
            _d = {};
            return [4 /*yield*/, this.prefixPromise];
          case 1:
            return [4 /*yield*/, _a.apply(void 0, _b.concat([__assign.apply(void 0, _c.concat([(_d.Key = _e.sent() + this.params.Key, _d)]))]))];
          case 2:
            res = _e.sent();
            this._cache({
              uploadId: res.UploadId,
              lastTouched: Date.now(),
              bucket: this.params.Bucket,
              key: this.params.Key,
              fileName: this.file instanceof File ? this.file.name : ''
            });
            return [2 /*return*/, res.UploadId];
        }
      });
    });
  };
  AWSS3UploadTask.prototype._initializeUploadTask = function () {
    return __awaiter(this, void 0, void 0, function () {
      var _a, parts, uploadId, uploadId, err_3;
      return __generator(this, function (_b) {
        switch (_b.label) {
          case 0:
            this.state = AWSS3UploadTaskState.IN_PROGRESS;
            this.partSize = calculatePartSize(this.totalBytes);
            _b.label = 1;
          case 1:
            _b.trys.push([1, 7,, 8]);
            return [4 /*yield*/, this._isCached()];
          case 2:
            if (!_b.sent()) return [3 /*break*/, 4];
            return [4 /*yield*/, this._findCachedUploadParts()];
          case 3:
            _a = _b.sent(), parts = _a.parts, uploadId = _a.uploadId;
            this.uploadId = uploadId;
            this.queued = this._createParts();
            this._initCachedUploadParts(parts);
            if (this._isDone()) {
              this._completeUpload();
            } else {
              this._startUpload();
            }
            return [3 /*break*/, 6];
          case 4:
            if (!!this.uploadId) return [3 /*break*/, 6];
            return [4 /*yield*/, this._initMultipartUpload()];
          case 5:
            uploadId = _b.sent();
            this.uploadId = uploadId;
            this.queued = this._createParts();
            this._startUpload();
            _b.label = 6;
          case 6:
            return [3 /*break*/, 8];
          case 7:
            err_3 = _b.sent();
            if (!isCancelError(err_3)) {
              logger.error('Error initializing the upload task', err_3);
              this._emitEvent(TaskEvents.ERROR, err_3);
            }
            return [3 /*break*/, 8];
          case 8:
            return [2 /*return*/];
        }
      });
    });
  };
  AWSS3UploadTask.prototype.resume = function () {
    if (this.state === AWSS3UploadTaskState.CANCELLED) {
      logger.warn('This task has already been cancelled');
    } else if (this.state === AWSS3UploadTaskState.COMPLETED) {
      logger.warn('This task has already been completed');
    } else if (this.state === AWSS3UploadTaskState.IN_PROGRESS) {
      logger.warn('Upload task already in progress');
      // first time running resume, find any cached parts on s3 or start a new multipart upload request before
      // starting the upload
    } else if (!this.uploadId) {
      this._initializeUploadTask();
    } else {
      this._startUpload();
    }
  };
  AWSS3UploadTask.prototype._startUpload = function () {
    this.state = AWSS3UploadTaskState.IN_PROGRESS;
    for (var i = 0; i < this.queueSize; i++) {
      this._startNextPart();
    }
  };
  AWSS3UploadTask.prototype._cancel = function () {
    return __awaiter(this, void 0, void 0, function () {
      var _a, _b, err_4;
      var _c;
      return __generator(this, function (_d) {
        switch (_d.label) {
          case 0:
            if (!(this.state === AWSS3UploadTaskState.CANCELLED)) return [3 /*break*/, 1];
            logger.warn('This task has already been cancelled');
            return [2 /*return*/, false];
          case 1:
            if (!(this.state === AWSS3UploadTaskState.COMPLETED)) return [3 /*break*/, 2];
            logger.warn('This task has already been completed');
            return [2 /*return*/, false];
          case 2:
            this.pause();
            this.queued = [];
            this.completedParts = [];
            this.bytesUploaded = 0;
            this.state = AWSS3UploadTaskState.CANCELLED;
            _d.label = 3;
          case 3:
            _d.trys.push([3, 7,, 8]);
            _a = abortMultipartUpload;
            _b = [this.s3Config];
            _c = {
              Bucket: this.params.Bucket
            };
            return [4 /*yield*/, this.prefixPromise];
          case 4:
            return [4 /*yield*/, _a.apply(void 0, _b.concat([(_c.Key = _d.sent() + this.params.Key, _c.UploadId = this.uploadId, _c)]))];
          case 5:
            _d.sent();
            return [4 /*yield*/, this._removeFromCache()];
          case 6:
            _d.sent();
            return [2 /*return*/, true];
          case 7:
            err_4 = _d.sent();
            logger.error('Error cancelling upload task', err_4);
            return [2 /*return*/, false];
          case 8:
            return [2 /*return*/];
        }
      });
    });
  };
  /**
   * pause this particular upload task
   **/
  AWSS3UploadTask.prototype.pause = function () {
    var _a;
    if (this.state === AWSS3UploadTaskState.CANCELLED) {
      logger.warn('This task has already been cancelled');
    } else if (this.state === AWSS3UploadTaskState.COMPLETED) {
      logger.warn('This task has already been completed');
    } else if (this.state === AWSS3UploadTaskState.PAUSED) {
      logger.warn('This task is already paused');
    }
    this.state = AWSS3UploadTaskState.PAUSED;
    // Abort the part request immediately
    // Add the inProgress parts back to pending
    var removedInProgressReq = this.inProgress.splice(0, this.inProgress.length);
    removedInProgressReq.forEach(function (req) {
      req.abortController.abort();
    });
    // Put all removed in progress parts back into the queue
    (_a = this.queued).unshift.apply(_a, __spreadArray([], __read(removedInProgressReq.map(function (req) {
      return req.uploadPartInput;
    })), false));
  };
  return AWSS3UploadTask;
}();
export { AWSS3UploadTask };