<div class="upload-image-to-s3-wrapper">
  <input type="file" class="file-input" (change)="onFileSelected($event)" #fileUpload />

  <div class="file-upload">
    {{ fileName || 'Ще немає завантаженого файлу.' }}

    <button mat-mini-fab color="primary" class="upload-btn" (click)="fileUpload.click()">
      <mat-icon>attach_file</mat-icon>
    </button>
  </div>
</div>
