import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AuthAWSGuard } from '../../auth';

import { AuthModule } from '../../auth';
import { ShellComponent } from './shell.component';

@NgModule({
  imports: [CommonModule, NgbModule, AuthModule, RouterModule],
  declarations: [ShellComponent],
  providers: [AuthAWSGuard],
})
export class ShellModule {}
