<app-header></app-header>

<div class="page-header">
  <h4 *ngIf="eventData" class="modal-title" id="modal-basic-title">
    {{ (eventData ? 'events-add#eventEditPage' : 'events-add#eventAddPage') | translate }}
  </h4>
</div>

<form [formGroup]="eventForm">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg p-4">
        <label class="mb-3 mt-3 form-group d-block" for="InternalName"
          >{{ 'events-add#eventInternalName' | translate }}
          <input
            [ngClass]="{ error: eventForm.controls.InternalName.invalid &&  eventForm.controls.InternalName.touched}"
            autocomplete="off"
            class="form-control"
            formControlName="InternalName"
            id="InternalName"
            type="text"
          />
        </label>
        <div class="mt-4 multi-lingual-field">
          <ul #nav="ngbNav" [(activeId)]="activeEventNameTab" class="nav-pills company-pill" ngbNav>
            <li
              *ngFor="let language of supportedLanguages; index as i"
              [ngbNavItem]="i"
              formArrayName="NameMultiLanguage"
            >
              <a ngbNavLink>{{ language.title }}</a>
              <ng-template *ngFor="let item of NameMultiLanguage.controls | slice: i;" ngbNavContent>
                <label [formGroupName]="i" class="form-group d-block" for="NameMultiLanguage"
                  >{{ 'events-add#eventNameInAddition' | translate }}
                  <input
                    [ngClass]="{ error: eventForm.controls.NameMultiLanguage.invalid &&  eventForm.controls.NameMultiLanguage.touched}"
                    autocomplete="off"
                    class="form-control"
                    formControlName="name_{{ language.value }}"
                    id="NameMultiLanguage"
                    maxlength="40"
                    type="text"
                  />
                </label>
              </ng-template>
            </li>
          </ul>
        </div>
        <div [ngbNavOutlet]="nav" class="mt-2"></div>
        <div class="mt-4 multi-lingual-field">
          <ul
            #navShortDescription="ngbNav"
            [(activeId)]="activeShortDescriptionTab"
            class="nav-pills company-pill"
            ngbNav
          >
            <li
              *ngFor="let language of supportedLanguages; index as i"
              [ngbNavItem]="i"
              formArrayName="ShortDescriptionMultiLanguage"
            >
              <a ngbNavLink>{{ language.title }}</a>
              <ng-template *ngFor="let item of ShortDescriptionMultiLanguage.controls | slice: i;" ngbNavContent>
                <label [formGroupName]="i" class="form-group d-block" for="ShortDescriptionMultiLanguage"
                  >{{ 'events-add#eventShortDescription' | translate }}
                  <textarea
                    [ngClass]="{ error: eventForm.controls.ShortDescriptionMultiLanguage.invalid &&  eventForm.controls.ShortDescriptionMultiLanguage.touched}"
                    autocomplete="off"
                    class="form-control mt-1"
                    formControlName="shortDescription_{{ language.value }}"
                    id="ShortDescriptionMultiLanguage"
                    maxlength="140"
                  ></textarea>
                </label>
              </ng-template>
            </li>
          </ul>
        </div>
        <div [ngbNavOutlet]="navShortDescription" class="mt-2"></div>
        <div class="mt-4 multi-lingual-field">
          <ul
            #navLongDescription="ngbNav"
            [(activeId)]="activeLongDescriptionTab"
            class="nav-pills company-pill"
            ngbNav
          >
            <li
              *ngFor="let language of supportedLanguages; index as i"
              [ngbNavItem]="i"
              formArrayName="LongDescriptionMultiLanguage"
            >
              <a ngbNavLink>{{ language.title }}</a>
              <ng-template *ngFor="let item of LongDescriptionMultiLanguage.controls | slice: i;" ngbNavContent>
                <label [formGroupName]="i" class="form-group d-block" for="LongDescriptionMultiLanguage"
                  >{{ 'events-add#eventLongDescription' | translate }}
                  <textarea
                    [ngClass]="{ error: eventForm.controls.LongDescriptionMultiLanguage.invalid &&  eventForm.controls.LongDescriptionMultiLanguage.touched }"
                    autocomplete="off"
                    class="form-control mt-1"
                    formControlName="longDescription_{{ language.value }}"
                    id="LongDescriptionMultiLanguage"
                    maxlength="1200"
                    rows="6"
                  ></textarea>
                </label>
              </ng-template>
            </li>
          </ul>
        </div>
        <div [ngbNavOutlet]="navLongDescription" class="mt-2"></div>
        <label class="mb-3 mt-3 form-group d-block" for="EventAddress"
          >Адреса проведення заходу
          <input autocomplete="off" class="form-control" formControlName="EventAddress" id="EventAddress" type="text" />
        </label>
        <div class="d-flex row pl-3 mt-3 mb-3">
          <label class="form-group d-block width-fit-content mr-3" for="Type"
            >{{ 'events-add#eventType' | translate }}
            <select class="form-control mt-1" formControlName="Type" id="Type">
              <option value="news">news</option>
              <option value="action">action</option>
              <option value="event">event</option>
            </select>
          </label>
          <label class="form-group d-block width-fit-content mr-3"
            >{{ 'events-add#eventActive' | translate }}
            <select
              [ngClass]="{ error: eventForm.controls.Active.invalid }"
              class="form-control mt-1"
              formControlName="Active"
            >
              <option [value]="true">{{ 'events-add#eventActiveTrue' | translate }}</option>
              <option [value]="false">{{ 'events-add#eventActiveFalse' | translate }}</option>
            </select>
          </label>
          <app-location-selector
            (citySelected)="onCitySelected($event)"
            (countrySelected)="onCountrySelected($event)"
            class="mt-3"
          />
        </div>
      </div>
      <div class="col-lg p-4">
        <div class="d-flex row p-3">
          <label class="mb-3 mr-3 form-group d-block width-fit-content" for="EventStartDate"
            >{{ 'events-add#eventDate' | translate }}
            <input
              [ngClass]="{ error: eventForm.controls.EventStartDate.invalid  && eventForm.controls.EventStartDate.touched}"
              autocomplete="off"
              class="form-control"
              formControlName="EventStartDate"
              id="EventStartDate"
              type="date"
            />
          </label>
          <label class="mb-3 mr-3 form-group d-block width-fit-content" for="EventStartTime"
            >{{ 'events-add#eventTime' | translate }}
            <input
              [ngClass]="{ error: eventForm.controls.EventStartTime.invalid && eventForm.controls.EventStartTime.touched}"
              autocomplete="off"
              class="form-control"
              formControlName="EventStartTime"
              id="EventStartTime"
              type="time"
            />
          </label>
          <label class="mb-3 form-group d-block" for="EventDuration"
            >{{ 'events-add#eventDuration' | translate }}
            <input
              [ngClass]="{ error: eventForm.controls.EventDuration.invalid }"
              autocomplete="off"
              class="form-control"
              formControlName="EventDuration"
              id="EventDuration"
              type="text"
            />
          </label>
        </div>
        <div class="d-flex row pl-3">
          <label class="mb-3 mt-2 mr-3 form-group d-block width-fit-content" for="EventPrice"
            >{{ 'events-add#eventPrice' | translate }}
            <input
              [ngClass]="{ error: eventForm.controls.EventPrice.invalid }"
              autocomplete="off"
              class="form-control"
              formControlName="EventPrice"
              id="EventPrice"
              type="text"
            />
          </label>

          <label class="mb-3 mt-2 form-group d-block width-fit-content"
            >{{ 'events-add#eventCurrency' | translate }}
            <select
              [ngClass]="{ error: eventForm.controls.EventCurrency.invalid }"
              class="form-control mt-1"
              formControlName="EventCurrency"
            >
              <option *ngFor="let currency of supportedCurrencies" [value]="currency.value">
                {{ currency.title }}
              </option>
            </select>
          </label>
        </div>
        <div class="py-3">
          <p><b>{{ 'events-add#eventImage' | translate }}</b></p>
          <app-image-upload
            (imageDeleted)="handleImageDeleted($event)"
            (imagesChanged)="handleImagesChanged($event)"
            [images]="eventImages || []"
          />
        </div>

        <div *ngIf="imageSignedURL" class="py-3">
          <p><b>Зображення заходу</b></p>
          <img alt="event image" class="event-image" src="{{imageSignedURL}}" />
        </div>

        <label class="mb-3 mt-3 form-group d-block" for="EventLink"
          >{{ 'events-add#eventUrl' | translate }}
          <input autocomplete="off" class="form-control" formControlName="EventLink" id="EventLink" type="text" />
        </label>
        <h5>{{ 'events-add#eventSocialLinks' | translate }}</h5>
        <div *ngFor="let item of EventSocialLinks.controls; let i = index" formArrayName="EventSocialLinks">
          <div [formGroupName]="i">
            <label class="mb-3 mt-3 form-group d-block" for="SocialNetworkTitle"
              >{{ 'events-add#eventSocialNetworkTitle' | translate }}
              <input
                autocomplete="off"
                class="form-control"
                formControlName="SocialNetworkTitle"
                id="SocialNetworkTitle"
                type="text"
              />
            </label>
            <label class="mb-3 mt-3 form-group d-block" for="SocialNetworkLink"
              >{{ 'events-add#eventSocialNetworkLink' | translate }}
              <input
                autocomplete="off"
                class="form-control"
                formControlName="SocialNetworkLink"
                id="SocialNetworkLink"
                type="text"
              />
            </label>
          </div>
        </div>
        <button (click)="addSocialLinksToForm()" class="btn btn-primary">
          {{ 'events-add#eventBtnAddSocialLinks' | translate }}
        </button>
      </div>
    </div>
  </div>
</form>

<div class="page-footer mt-5 mb-3 d-flex text-center justify-content-center">
  <button
    [queryParams]="{ activeTab: 'eventTab' }"
    [routerLink]="'/main'"
    class="d-block btn btn-outline-primary"
    type="button"
  >
    {{ 'events-add#eventBtnCencel' | translate }}
  </button>
  <button (click)="updateEvent()" *ngIf="eventData" class="ml-3 d-block btn btn-company btn-primary" type="button">
    {{ 'events-add#eventBtnSaveChange' | translate }}
  </button>
  <button (click)="saveEvent()" *ngIf="!eventData" class="ml-3 d-block btn btn-company btn-primary" type="button">
    {{ 'events-add#eventBtnSaveNew' | translate }}
  </button>
</div>
