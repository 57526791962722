<button (click)="addNewTouristLocation()" class="btn btn-danger mt-5 mb-2 mr-3">
  {{ 'TouristLocationsPageComponent#addLocation' | translate }}
</button>
<app-import-tourist-locations />
<p>
  Загальна кількість туристичних місць: <b>{{ touristLocations.length || 0 }}</b>
</p>

<app-location-selector (citySelected)="onCitySelected($event)" (countrySelected)="onCountrySelected($event)" />

<table class="table table-striped mt-3">
  <thead>
    <tr>
      <th scope="col">{{ 'TouristLocationsPageComponent#Location#id' | translate }}</th>
      <th scope="col">{{ 'TouristLocationsPageComponent#Location#hasFoto' | translate }}</th>
      <th scope="col">{{ 'TouristLocationsPageComponent#Location#title' | translate }}</th>
      <th scope="col">{{ 'TouristLocationsPageComponent#Location#active' | translate }}</th>
      <th scope="col">{{ 'TouristLocationsPageComponent#Location#address' | translate }}</th>
      <th scope="col">{{ 'TouristLocationsPageComponent#Location#location' | translate }}</th>
      <th scope="col"></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let touristLocation of touristLocations">
      <td>{{ touristLocation.Id }}</td>
      <td>
        <mat-icon *ngIf="touristLocation.S3Link">check</mat-icon>
      </td>

      <td>{{ touristLocation.TitleMultiLanguage.uk }}</td>
      <td>{{ touristLocation.Active === true ? ('ACTIVE' | translate) : ('NOT#ACTIVE' | translate) }}</td>
      <td>{{ touristLocation.AddressMultiLanguage.uk }}</td>
      <td>
        <a
          href="https://maps.google.com/?q={{ touristLocation.Location.lat }},{{ touristLocation.Location.lng }}"
          target="_blank"
          ><{{ touristLocation.Location.lat }}, {{ touristLocation.Location.lng }}></a
        >
      </td>
      <td>
        <button class="mat-raised-button custom-btn" disabled="{{ httpRequestInProgress }}">
          <mat-icon (click)="deleteTourLocation(touristLocation.UserId, touristLocation.ItemId)" class="pointer"
            >delete
          </mat-icon>
        </button>

        <button class="mat-raised-button custom-btn" disabled="{{ httpRequestInProgress }}">
          <mat-icon (click)="editTourLocation(touristLocation)" class="pointer">edit</mat-icon>
        </button>
      </td>
    </tr>
  </tbody>
</table>
