import { Injectable } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class FormGenerateService {
  public supportedLanguages = environment.supportedLanguages;

  setFocus(element) {
    if (!element) {
      return;
    }
    element.nativeElement.focus();
  }

  onCheckboxChange(e, form, field): UntypedFormArray {
    const resultArray: UntypedFormArray = form.get(field) as UntypedFormArray;
    if (e.target.checked) {
      resultArray.push(new UntypedFormControl(e.target.value));
    } else {
      const index = resultArray.controls.findIndex((x) => x.value === e.target.value);
      resultArray.removeAt(index);
    }
    return resultArray;
  }

  addLanguageForm(language, form, field): UntypedFormArray {
    const resultArray: UntypedFormArray = form.get(field) as UntypedFormArray;
    resultArray.push(new UntypedFormControl(language.value));
    return resultArray;
  }
  removeLanguageForm(language, form, field): UntypedFormArray {
    const resultArray: UntypedFormArray = form.get(field) as UntypedFormArray;
    const index = resultArray.controls.findIndex((x) => x.value === language.value);
    resultArray.removeAt(index);
    return resultArray;
  }

  generateFormControlMultiLanguage(key, valueDefault = '', validationsList = []) {
    const formControls = [];

    const validators = [];

    validationsList.forEach((element) => {
      switch (element.type) {
        case 'required':
          validators.push(Validators.required);
          break;
        case 'maxLength':
          validators.push(Validators.maxLength(element.value));
          break;
        case 'minLength':
          validators.push(Validators.minLength(element.value));
          break;
      }
    });

    this.supportedLanguages.forEach((language) => {
      const control = {};
      control[key + language.value] = new UntypedFormControl(valueDefault, validators);
      const group = new UntypedFormGroup(control);
      formControls.push(group);
    });
    return formControls;
  }
}
