export interface StaticInfoConfigModel {
  s3Key: string;
  tableColumns: StaticInfoTableColumnModel[];
}

export interface StaticInfoTableColumnModel {
  columnLabel: string;
  columnKey: string;
  type: StaticInfoTableColumnType;
}

export enum StaticInfoTableColumnType {
  BOOL = 'BOOL',
  TEXT = 'TEXT',
  LOCATION = 'LOCATION',
  LINKS = 'LINKS',
  PHONES = 'PHONES',
}
