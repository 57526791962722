import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { User } from '../models/user';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss'],
})
export class UserDetailsComponent implements OnInit, OnDestroy {
  public user: User;
  private subscription: Subscription = new Subscription();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.collectUrlParams();
  }

  collectUrlParams() {
    this.subscription.add(
      this.route.queryParams.subscribe((params) => {
        let user = JSON.parse(params.user);
        if (user.id) {
          user.activity = JSON.parse(user.activity);
          user.address = JSON.parse(user.address);
          this.user = user;
        }
      }),
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
