<p-tabView [(activeIndex)]="activeIndex" [scrollable]="false">
  <p-tabPanel *ngFor="let tab of supportedLanguages; let i = index" [header]="tab.label">
    <div [formGroup]="multiLanguageForm">
      <div *ngFor="let configItem of multiLanguageConfig">
        <div [formGroupName]="configItem.Control" class="flex flex-column gap-2 mt-3">
          <label [htmlFor]="configItem.Control + '-' + tab.code" class="cp-input-label">
            {{ configItem.Label }}
          </label>
          <ng-container [ngSwitch]="configItem.Mode">
            <input
              (blur)="updateParentFormField()"
              *ngSwitchCase="'input'"
              [formControlName]="tab.code"
              [id]="configItem.Control + '-' + tab.code"
              pInputText
              type="text"
            />
            <textarea
              (blur)="updateParentFormField()"
              *ngSwitchCase="'textarea'"
              [cols]="configItem.Cols"
              [formControlName]="tab.code"
              [id]="configItem.Control + '-' + tab.code"
              [rows]="configItem.Rows"
              pInputTextarea
            ></textarea>
          </ng-container>
        </div>
      </div>
    </div>
  </p-tabPanel>
</p-tabView>
