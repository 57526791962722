import { Component, Input } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { XlsxToJsonService } from '../../services/xlsxToJson.service';
import { StaticInfoService } from '../../../tabs/static-information-view/shared/static-info.service';
import { IAddEditStaticInfoDataModel } from '../../../tabs/static-information-view/models/IAddEditStaticInfoData.model';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-import-data',
  standalone: true,
  templateUrl: './import-data.component.html',
  styleUrls: ['./import-data.component.scss'],
})
export class ImportDataComponent {
  loading: boolean;
  @Input() staticInfoType: string;

  constructor(
    private xlsxToJsonService: XlsxToJsonService,
    private staticInfoService: StaticInfoService,
  ) {}

  async importData(event: Event): Promise<void> {
    const inputElement = event.target as HTMLInputElement;
    const file = inputElement.files?.[0];
    this.loading = true;

    if (file) {
      const data = await this.xlsxToJsonService.convertExcelToJson<IAddEditStaticInfoDataModel>(file);
      this.staticInfoService
        .importStaticInfo({
          type: this.staticInfoType,
          data,
        })
        .pipe(
          finalize(() => {
            this.loading = false;
          }),
        )
        .subscribe({
          next: () => {
            Swal.fire('Успіх!', 'Дані імпортовано!', 'success');
          },
          error: () => {
            Swal.fire('Упс...', 'Помилка при імпорті даних!', 'error');
          },
        });
    }
  }
}
