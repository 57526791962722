import { StaticInfoTableColumnType } from '../models/static-info-config.model';

export const baseTableColumns = [
  {
    columnLabel: 'Id',
    columnKey: 'Id',
    type: StaticInfoTableColumnType.TEXT,
  },
  {
    columnLabel: 'Назва',
    columnKey: 'TitleMultiLanguage.titleMultiLanguage_uk',
    type: StaticInfoTableColumnType.TEXT,
  },
  {
    columnLabel: 'Активна',
    columnKey: 'Active',
    type: StaticInfoTableColumnType.BOOL,
  },
  {
    columnLabel: 'Адреса',
    columnKey: 'AddressMultiLanguage.addressMultiLanguage_uk',
    type: StaticInfoTableColumnType.TEXT,
  },
  {
    columnLabel: 'Локація',
    columnKey: 'Location',
    type: StaticInfoTableColumnType.LOCATION,
  },
  {
    columnLabel: 'URL',
    columnKey: 'URL',
    type: StaticInfoTableColumnType.TEXT,
  },
  {
    columnLabel: 'Соціальні мережі',
    columnKey: 'SocialLinks',
    type: StaticInfoTableColumnType.LINKS,
  },
];
