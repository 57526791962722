import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { environment } from '../../../../environments/environment';
import Swal from 'sweetalert2';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { EventsService } from '../../../services/events.service';
import { EventData } from '../models/eventData';
import { IImageSignedUrlRequestObjectModel } from '../../tourist-locations-view/models/i-image-signed-url-request-object.model';
import { FileExtension } from '../models/file-extention.model';
import { IImageBaseModel } from '../../../shared/models/i-image-upload.model';
import { multiLanguageTabsConfig } from '../../../shared/configs/multi-langiage-tabs.config';
import { IEventConfigModel } from '../models/event-config.model';
import { supportedLanguagesConfig } from '../../../shared/configs/supported-language.config';
import { EventMultiFieldsModel } from '../models/event.model';

@Component({
  selector: 'app-events-add',
  templateUrl: './events-add.page.html',
  styleUrls: ['./events-add.page.scss'],
})
export class EventsAddPageComponent implements OnInit, OnDestroy {
  public eventData: EventData;
  public countryId: string;
  public cityId: string;
  public images: IImageBaseModel[] = [];
  public supportedLanguages = environment.supportedLanguages;
  public supportedCurrencies = environment.supportedCurrencies;
  public activeEventNameTab = 0;
  public activeShortDescriptionTab = 0;
  public activeLongDescriptionTab = 0;
  public multiLanguageInitialData: EventMultiFieldsModel;
  public eventImages?: string;
  public imageSignedURL: string;
  public selectedEventImage: File;
  public eventForm: FormGroup = this.fb.group({
    InternalName: ['', Validators.required],
    NameMultiLanguage: new FormControl({}),
    EventStartDate: ['', Validators.required],
    EventStartTime: ['', Validators.required],
    ShortDescriptionMultiLanguage: new FormControl({}),
    LongDescriptionMultiLanguage: new FormControl({}),
    EventAddressMultiLanguage: new FormControl({}),
    Active: [false, Validators.required],
    EventPrice: [''],
    EventCurrency: [''],
    EventLink: [''],
    EventSocialLinks: this.fb.array([this.addSocialLinks()]),
    EventDuration: [''],
    Type: [''],
    s3Link: [''],
  });
  protected readonly supportedLanguagesConfig = supportedLanguagesConfig;
  protected readonly multiLanguageTabs: IEventConfigModel[] = multiLanguageTabsConfig;
  private subscription: Subscription = new Subscription();

  constructor(
    private eventsService: EventsService,
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
  ) {}

  get EventSocialLinks(): FormArray {
    return this.eventForm.get('EventSocialLinks') as FormArray;
  }

  get NameMultiLanguage(): FormArray {
    return this.eventForm.get('NameMultiLanguage') as FormArray;
  }

  get ShortDescriptionMultiLanguage(): FormArray {
    return this.eventForm.get('ShortDescriptionMultiLanguage') as FormArray;
  }

  get LongDescriptionMultiLanguage(): FormArray {
    return this.eventForm.get('LongDescriptionMultiLanguage') as FormArray;
  }

  ngOnInit(): void {
    this.initializeEventData();
    if (this.eventData) {
      this.multiLanguageInitialData = {
        NameMultiLanguage: this.eventData.NameMultiLanguage,
        EventAddressMultiLanguage: this.eventData.EventAddressMultiLanguage,
        ShortDescriptionMultiLanguage: this.eventData.ShortDescriptionMultiLanguage,
        LongDescriptionMultiLanguage: this.eventData.LongDescriptionMultiLanguage,
      };
    }
  }

  initializeEventData(): void {
    let Id = this.route.snapshot.queryParams.Id;
    if (Id) {
      Id = JSON.parse(this.route.snapshot.queryParams.Id);
      this.eventsService.getEventById(Id).subscribe((res) => {
        if (res) {
          this.multiLanguageInitialData = {
            NameMultiLanguage: res.NameMultiLanguage,
            ShortDescriptionMultiLanguage: res.ShortDescriptionMultiLanguage,
            LongDescriptionMultiLanguage: res.LongDescriptionMultiLanguage,
            EventAddressMultiLanguage: res.EventAddressMultiLanguage,
          };
          this.eventData = res;
          this.eventImages = this.eventData.s3Link;
          this.collectUrlParams();
        }
      });
    }
  }

  collectUrlParams(): void {
    if (this.eventData) {
      this.eventForm.controls.InternalName.patchValue(this.eventData.InternalName);
      this.eventForm.controls.NameMultiLanguage.patchValue(this.eventData.NameMultiLanguage);
      this.eventForm.controls.ShortDescriptionMultiLanguage.patchValue(this.eventData.ShortDescriptionMultiLanguage);
      this.eventForm.controls.LongDescriptionMultiLanguage.patchValue(this.eventData.LongDescriptionMultiLanguage);
      this.eventForm.controls.EventAddressMultiLanguage.patchValue(this.eventData.EventAddressMultiLanguage);
      this.eventForm.controls.Active.patchValue(this.eventData.Active);
      this.eventForm.controls.Type.patchValue(this.eventData.Type);
      this.eventForm.controls.EventStartDate.patchValue(this.extractDate(this.eventData.EventStartISODateTime));
      this.eventForm.controls.EventStartTime.patchValue(this.extractTime(this.eventData.EventStartISODateTime));
      this.eventForm.controls.EventDuration.patchValue(this.eventData.EventDuration);
      this.eventForm.controls.EventPrice.patchValue(this.eventData.EventPrice);
      this.eventForm.controls.EventCurrency.patchValue(this.eventData.EventCurrency);
      this.eventForm.controls.EventLink.patchValue(this.eventData.EventLink);
      this.eventForm.controls.EventSocialLinks.patchValue(this.eventData.EventSocialLinks);
      this.eventForm.controls.s3Link.patchValue(this.eventData.s3Link);
    }
  }

  addSocialLinks(): FormGroup {
    return this.fb.group({
      SocialNetworkLink: [''],
      SocialNetworkTitle: [''],
    });
  }

  addSocialLinksToForm(): void {
    this.EventSocialLinks.push(this.addSocialLinks());
  }

  handleMultiLanguageDataChanged(data: EventMultiFieldsModel): void {
    if (data) {
      this.eventForm.patchValue({
        NameMultiLanguage: data.NameMultiLanguage,
        EventAddressMultiLanguage: data.EventAddressMultiLanguage,
        ShortDescriptionMultiLanguage: data.ShortDescriptionMultiLanguage,
        LongDescriptionMultiLanguage: data.LongDescriptionMultiLanguage,
      });
    }
  }

  async saveEvent(): Promise<void> {
    const objToSubmit = this.eventForm.value;

    if (this.selectedEventImage) {
      const key = await this.uploadImage(this.selectedEventImage);
      objToSubmit.s3Link = key;
    }

    this.subscription.add(
      this.eventsService.addEvent(objToSubmit, this.countryId, this.cityId).subscribe({
        next: (res) => {
          if (res) {
            if (this.images) {
              this.eventsService.uploadImageToBucket(this.images, res.Id).subscribe({
                next: () => {
                  Swal.fire('Успіх!', 'Картинку додано!', 'success');
                },
                error: () => {
                  Swal.fire('Упс...', 'Виникла помилка під час додавання картинки!', 'error');
                },
              });
            }
            Swal.fire('Успіх!', 'Подію додано!', 'success');
            this.eventForm.reset();
            this.router.navigate([`/main`], { queryParams: { activeTab: 'eventTab' } });
          }
        },
        error: () => {
          Swal.fire({
            icon: 'error',
            title: 'Помилка',
            text: `Виникла помилка під час додавання події`,
            confirmButtonColor: '#fcd900',
          });
        },
      }),
    );
  }

  async updateEvent(): Promise<void> {
    const objToSubmit = this.eventForm.value;
    const id = this.eventData.Id;

    const obj = { content: objToSubmit, id };
    this.subscription.add(
      this.eventsService.updateEvent(obj, this.countryId, this.cityId).subscribe({
        next: (res) => {
          if (res) {
            if (this.images) {
              this.eventsService.uploadImageToBucket(this.images, res.Id).subscribe({
                next: () => {
                  Swal.fire('Успіх!', 'Картинку додано!', 'success');
                },
                error: () => {
                  Swal.fire('Упс...', 'Виникла помилка під час додавання картинки!', 'error');
                },
              });
            }
          }
          Swal.fire('Успіх!', 'Зміни збережено!', 'success');
          this.eventForm.reset();
          this.router.navigate([`/main`], { queryParams: { activeTab: 'eventTab' } });
        },
        error: () => {
          Swal.fire({
            icon: 'error',
            title: 'Помилка',
            text: `Виникла помилка під час редагування заходу`,
            confirmButtonColor: '#fcd900',
          });
        },
      }),
    );
  }

  onFileSelect(file: File): void {
    this.selectedEventImage = file;
    if (file) {
      this.uploadImage(file).then((key) => {
        this.eventForm.patchValue({ s3Link: key });
      });
    }
  }

  async uploadImage(file: File): Promise<string> {
    let signedURL = '';
    let imageName = '';

    if (file.type === 'image/jpg' || file.type === 'image/jpeg' || file.type === 'image/png') {
      try {
        const { UploadURL, Key } = await this.getSignedImageUploadURl(file.type);

        signedURL = UploadURL;
        imageName = Key;

        const newFileImage = new File([file], imageName, {
          type: file.type,
        });
      } catch (e) {
        alert('Сталась помилка при отриманні авторизованого посилання');
      }
    } else {
      alert('Не вірний формат зображення');
    }

    return imageName;
  }

  extractDate(isoDateString: string): string {
    return isoDateString.split('T')[0];
  }

  extractTime(isoDateString: string): string {
    return isoDateString.split('T')[1].split('.')[0];
  }

  onCitySelected(cityId: string): void {
    this.cityId = cityId;
  }

  onCountrySelected(countryId: string): void {
    this.countryId = countryId;
  }

  handleImagesChanged(images: IImageBaseModel[]): void {
    this.images = images;
  }

  handleImageDeleted(imageUrl: string): void {
    if (this.eventData) {
      const id = this.eventData.Id;
      this.eventsService.removeImageFromBucket(imageUrl, id).subscribe(() => {
        this.eventForm.controls.s3Link.patchValue([]);
        Swal.fire('Успіх!', 'Зображення успішно видалено!', 'success');
      });
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private getSignedImageUploadURl(fileExtension: FileExtension): Promise<{ Key: string; UploadURL: string }> {
    const getImageSignedURLRequestObject: IImageSignedUrlRequestObjectModel = {
      FileExtension: fileExtension,
    };

    return new Promise((resolve, reject): void => {
      this.eventsService.getImageSignedURLToUploadImage(getImageSignedURLRequestObject).subscribe({
        next: (res) => {
          resolve(res);
        },
        error: () => {
          reject();
        },
      });
    });
  }
}
