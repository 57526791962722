<app-header></app-header>
<div class="page-header">
  <h4 *ngIf="this.excursionData" class="modal-title" id="modal-basic-title">
    {{'excursion-add#excursionEditPage' | translate}}
  </h4>
  <h4 *ngIf="!this.excursionData" class="modal-title" id="modal-basic-title">
    {{'excursion-add#excursionAddNewPage' | translate}}
  </h4>
</div>

<form [formGroup]="excursionForm">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg p-4">
        <label for="ExcursionName" class="mb-3 mt-3 form-group d-block"
          >{{'excursion-add#excursionInternalName' | translate}}
          <input
            type="text"
            id="ExcursionName"
            class="form-control"
            formControlName="ExcursionName"
            autocomplete="off"
            [ngClass]="{error: excursionForm.controls.ExcursionName.invalid}"
          />
        </label>
        <div class="d-flex row pl-3 mt-3 mb-3">
          <label class="form-group d-block width-fit-content mr-3"
            >{{'excursion-add#excursionActive' | translate}}
            <select
              formControlName="Active"
              class="form-control mt-1"
              [ngClass]="{error: excursionForm.controls.Active.invalid}"
            >
              <option value="{{true}}">{{'excursion-add#excursionActiveTrue' | translate}}</option>
              <option value="{{false}}">{{'excursion-add#excursionActiveFalse' | translate}}</option>
            </select>
          </label>
          <label class="form-group d-block width-fit-content"
            >{{'excursion-add#excursionCityInternal' | translate}}
            <select
              formControlName="ExcursionCityId"
              class="form-control mt-1"
              [ngClass]="{error: excursionForm.controls.ExcursionCityId.invalid}"
            >
              <option value="{{city.id}}" *ngFor="let city of cities">{{city.cityMultilanguageName.uk}}</option>
            </select>
          </label>
        </div>
        <div class="multi-lingual-field">
          <ul ngbNav #nav="ngbNav" [(activeId)]="activeExcursionNameTab" class="nav-pills company-pill">
            <li
              [ngbNavItem]="i"
              *ngFor="let language of supportedLanguages; index as i"
              formArrayName="ExcursionNameMultiLanguage"
            >
              <a ngbNavLink>{{language.title}}</a>
              <ng-template ngbNavContent *ngFor="let item of ExcursionNameMultiLanguage.controls | slice: i;">
                <label for="ExcursionNameMultiLanguage" class="form-group d-block" [formGroupName]="i"
                  >{{'excursion-add#excursionNameInAddition' | translate}}
                  <input
                    type="text"
                    id="ExcursionNameMultiLanguage"
                    class="form-control"
                    maxlength="40"
                    [ngClass]="{error: excursionForm.controls.ExcursionNameMultiLanguage.invalid}"
                    formControlName="excursionName_{{language.value}}"
                    autocomplete="off"
                  />
                </label>
              </ng-template>
            </li>
          </ul>
        </div>
        <div [ngbNavOutlet]="nav" class="mt-3 mb-4"></div>
        <div class="form-group" *ngIf="excursionImages">
          <ul class="list-inline">
            <li class="list-inline-item" *ngFor="let image of excursionImages; index as i">
              <div class="card mb-3" style="width: 200px">
                <img class="card-img-top" src="{{image.url}}" />
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-lg p-4">
        <label for="ExcursionDuration" class="form-group d-block mb-3 mt-3"
          >{{'excursion-add#excursionDuration' | translate}}
          <input
            type="number"
            id="ExcursionDuration"
            class="form-control"
            formControlName="ExcursionDuration"
            [ngClass]="{error: excursionForm.controls.ExcursionDuration.invalid}"
            autocomplete="off"
          />
        </label>
        <div class="mt-4 multi-lingual-field">
          <ul
            ngbNav
            #navShortDescription="ngbNav"
            [(activeId)]="activeShortDescriptionTab"
            class="nav-pills company-pill"
          >
            <li
              [ngbNavItem]="i"
              *ngFor="let language of supportedLanguages; index as i"
              formArrayName="ExcursionShortDescriptionMultiLanguage"
            >
              <a ngbNavLink>{{language.title}}</a>
              <ng-template ngbNavContent *ngFor="let item of ExcursionNameMultiLanguage.controls | slice: i;">
                <label for="ExcursionShortDescriptionMultiLanguage" class="form-group d-block" [formGroupName]="i"
                  >{{'excursion-add#excursionShortDescription' | translate}}
                  <textarea
                    id="ExcursionShortDescriptionMultiLanguage"
                    class="form-control mt-1"
                    maxlength="140"
                    [ngClass]="{error: excursionForm.controls.ExcursionShortDescriptionMultiLanguage.invalid}"
                    formControlName="excursionShortDescription_{{language.value}}"
                    autocomplete="off"
                  ></textarea>
                </label>
              </ng-template>
            </li>
          </ul>
        </div>
        <div [ngbNavOutlet]="navShortDescription" class="mt-2"></div>
        <div class="mt-4 multi-lingual-field">
          <ul
            ngbNav
            #navLongDescription="ngbNav"
            [(activeId)]="activeLongDescriptionTab"
            class="nav-pills company-pill"
          >
            <li
              [ngbNavItem]="i"
              *ngFor="let language of supportedLanguages; index as i"
              formArrayName="ExcursionLongDescriptionMultiLanguage"
            >
              <a ngbNavLink>{{language.title}}</a>
              <ng-template ngbNavContent *ngFor="let item of ExcursionNameMultiLanguage.controls | slice: i;">
                <label for="ExcursionLongDescriptionMultiLanguage" class="form-group d-block" [formGroupName]="i"
                  >{{'excursion-add#excursionLongDescription' | translate}}
                  <textarea
                    id="ExcursionLongDescriptionMultiLanguage"
                    class="form-control mt-1"
                    maxlength="1200"
                    [ngClass]="{error: excursionForm.controls.ExcursionLongDescriptionMultiLanguage.invalid}"
                    rows="6"
                    formControlName="excursionLongDescription_{{language.value}}"
                    autocomplete="off"
                  ></textarea>
                </label>
              </ng-template>
            </li>
          </ul>
        </div>

        <div [ngbNavOutlet]="navLongDescription" class="mt-2"></div>

        <div class="d-flex row pl-3 pt-3 mt-4">
          <label for="ExcursionPrice" class="mr-3 form-group d-block width-fit-content"
            >{{'excursion-add#excursionPrice' | translate}}
            <input
              type="number"
              id="ExcursionPrice"
              class="form-control"
              formControlName="ExcursionPrice"
              [ngClass]="{error: excursionForm.controls.ExcursionPrice.invalid}"
              autocomplete="off"
            />
          </label>

          <label class="form-group d-block width-fit-content"
            >{{'excursion-add#excursionCurrency' | translate}}
            <select
              class="form-control mt-1"
              formControlName="ExcursionCurrency"
              [ngClass]="{error: excursionForm.controls.ExcursionCurrency.invalid}"
            >
              <option *ngFor="let currency of supportedCurrencies" value="{{currency.value}}">
                {{currency.title}}
              </option>
            </select>
          </label>
        </div>
      </div>
    </div>
  </div>
</form>

<div class="page-footer mt-3 mb-3 d-flex text-center justify-content-center">
  <button
    type="button"
    class="d-block btn btn-outline-primary"
    [routerLink]="'/main'"
    [queryParams]="{activeTab: 'excursionTab'}"
  >
    {{'excursion-add#excursionBtnCancel' | translate}}
  </button>
  <button
    type="button"
    class="ml-3 d-block btn btn-company btn-primary"
    (click)="updateExcursion()"
    *ngIf="this.excursionData"
  >
    {{'excursion-add#excursionBtnSaveChange' | translate}}
  </button>
</div>
