import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-upload-to-s3-bucket',
  templateUrl: 'upload-image-to-s3.component.html',
  styleUrls: ['./upload-image-to-s3.component.scss'],
})
export class UploadImageToS3Component implements OnInit {
  @Output() onFileSelect: EventEmitter<File> = new EventEmitter<File>();

  fileName = '';

  constructor() {}

  ngOnInit() {}

  onFileSelected(event) {
    const file: File = event.target.files[0];

    if (file) {
      this.fileName = file.name;

      this.onFileSelect.emit(file);
    }
  }
}
