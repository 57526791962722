<form [formGroup]="excursionSearchForm">
  <div class="col-7 p-3">
    Фільтрація у стані розробки!!!
<!--    <div class="d-flex row">-->
<!--      <label for="searchId" class="form-group d-block width-fit-content mr-3"-->
<!--        >{{ 'excursion-page#filterId' | translate }}-->
<!--        <input-->
<!--          type="text"-->
<!--          id="searchId"-->
<!--          class="form-control"-->
<!--          (ngModelChange)="searchExcursion()"-->
<!--          formControlName="searchId"-->
<!--          autocomplete="off"-->
<!--        />-->
<!--      </label>-->
<!--      <label for="searchExcursionName" class="form-group d-block width-fit-content mr-3"-->
<!--        >{{ 'excursion-page#filterInternalName' | translate }}-->
<!--        <input-->
<!--          type="text"-->
<!--          id="searchExcursionName"-->
<!--          class="form-control"-->
<!--          (ngModelChange)="searchExcursion()"-->
<!--          formControlName="searchExcursionName"-->
<!--          autocomplete="off"-->
<!--        />-->
<!--      </label>-->
<!--      <label for="searchExcursionCity" class="form-group d-block width-fit-content mr-3"-->
<!--        >{{ 'excursion-page#filterCity' | translate }}-->
<!--        <select-->
<!--          id="searchExcursionCity"-->
<!--          class="form-control"-->
<!--          formControlName="searchExcursionCity"-->
<!--          (change)="searchExcursion()"-->
<!--        >-->
<!--          <option-->
<!--            *ngFor="let city of cities"-->
<!--            [ngValue]="city.cityMultilanguageName.uk"-->
<!--            [selected]="cities[0].cityMultilanguageName.uk === city.cityMultilanguageName.uk"-->
<!--          >-->
<!--            {{ city.cityMultilanguageName.uk }}-->
<!--          </option>-->
<!--        </select>-->
<!--      </label>-->
<!--      <label for="searchExcursionDate" class="form-group d-block width-fit-content mr-1">-->
<!--        {{ 'Фільтрація по діапазону дат' }}-->
<!--        <div id="searchExcursionDate" class="flex">-->
<!--          <input-->
<!--            type="date"-->
<!--            id="searchExcursionStartDate"-->
<!--            class="form-control mr-2"-->
<!--            formControlName="searchExcursionStartDate"-->
<!--            (ngModelChange)="searchExcursion()"-->
<!--            autocomplete="off"-->
<!--          />-->
<!--          <input-->
<!--            type="date"-->
<!--            id="searchExcursionEndDate"-->
<!--            class="form-control"-->
<!--            formControlName="searchExcursionEndDate"-->
<!--            (ngModelChange)="searchExcursion()"-->
<!--            autocomplete="off"-->
<!--          />-->
<!--        </div>-->
<!--      </label>-->
<!--    </div>-->
  </div>
</form>

<table class="table table-striped mt-3">
  <thead>
    <tr>
      <th scope="col" class="d-none d-md-table-cell">{{ 'excursion-page#uniqueId' | translate }}</th>
      <th scope="col">{{ 'excursion-page#excursionInternalName' | translate }}</th>
      <th scope="col" class="d-none d-lg-table-cell">{{ 'excursion-page#idCity' | translate }}</th>
      <th scope="col" class="d-none d-md-table-cell">{{ 'Час і дата' }}</th>
      <th scope="col">{{ 'К-ть записаних' }}</th>
    </tr>
  </thead>

  <tbody *ngIf="!filter">
    <tr *ngFor="let excursion of excursions">
      <th scope="row" class="d-none d-md-table-cell">{{ excursion.Id }}</th>
      <td>{{ excursion.Title }}</td>
      <td class="d-none d-lg-table-cell">{{ excursion.CityId }}</td>
      <td class="d-none d-md-table-cell">{{ excursion.Start | date: 'HH:mm | dd.MM.yyyy' }}</td>
      <td class="d-none d-sm-table-cell">{{ excursion.Members.length + '/' + excursion.MaxMembers }}</td>
    </tr>
  </tbody>

  <tbody *ngIf="filter">
    <tr *ngFor="let excursion of filterExcursions">
      <th scope="row" class="d-none d-md-table-cell">{{ excursion.Id }}</th>
      <td>{{ excursion.Title }}</td>
      <td class="d-none d-lg-table-cell">{{ excursion.CityId }}</td>
      <td class="d-none d-md-table-cell">{{ excursion.Start | date: 'HH:mm | dd.MM.yyyy' }}</td>
      <td class="d-none d-sm-table-cell">{{ excursion.Members.length + '/' + excursion.MaxMembers }}</td>
    </tr>
  </tbody>
</table>
