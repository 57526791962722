import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';

@Injectable({
  providedIn: 'root',
})
export class XlsxToJsonService {
  convertExcelToJson<T>(file): Promise<T[]> {
    const reader = new FileReader();
    let workbook;
    let xlRowObject;
    let jsonObject;
    reader.readAsBinaryString(file);
    return new Promise((resolve) => {
      reader.onload = () => {
        const data = reader.result;
        workbook = XLSX.read(data, { type: 'binary' });
        workbook.SheetNames.forEach((sheetName) => {
          xlRowObject = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
          jsonObject = JSON.stringify(xlRowObject);
          resolve(xlRowObject);
        });
      };
    });
  }
}
