import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { RestService } from '../../services/restService.service';
import { Subscription } from 'rxjs';
import { Credentials } from '../../auth';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
})
export class LoginPageComponent implements OnInit, OnDestroy {
  private credentialsKey = environment.credentialsKey;
  public environment = environment;
  public loginForm: UntypedFormGroup = this.fb.group({
    username: ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
    password: ['', [Validators.required]],
  });

  private subscription: Subscription = new Subscription();

  constructor(
    public fb: UntypedFormBuilder,
    private restService: RestService,
    private router: Router,
  ) {}

  loginAttempt() {
    this.subscription.add(
      this.restService.login(this.loginForm.value).subscribe(
        (result) => {
          if (result) {
            this.setCredentials(result.access_token, true).then(() => {
              this.router.navigate(['/main'], { replaceUrl: true });
            });
          }
        },
        (error) => {
          Swal.fire({
            icon: 'error',
            title: 'Виникла помилка...',
            text: `Зверніться до адміністратора і передайте наступні дані. Назва помилки - ${error.name}, повідомлення - ${error.message}`,
            confirmButtonColor: '#fcd900',
          });
        },
      ),
    );
  }

  setCredentials(credentials?: Credentials, remember?: boolean) {
    return new Promise<void>((resolve) => {
      if (credentials) {
        const storage = remember ? localStorage : sessionStorage;
        storage.setItem(this.credentialsKey, JSON.stringify(credentials));
        resolve();
      } else {
        sessionStorage.removeItem(this.credentialsKey);
        localStorage.removeItem(this.credentialsKey);
      }
    });
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
