export const environment = {
  production: true,
  version: 'v. 1.2',
  credentialsKey: 'credentials-tourapp-cms',
  redirectLink:
    'https://city-on-palm-admin.auth.eu-west-2.amazoncognito.com/login?client_id=6007u8k63o9jr78rg9qpl0psip&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=https://master.cityonpalm.com',
  tracesSampleRate: 0.1,
  sentryUrl: 'https://a8e55d37208ff9374b6e5313e8f0a2b5@o4505127376977920.ingest.us.sentry.io/4507854732197888',
  amplifyConfig: {
    Auth: {
      identityPoolId: 'eu-west-2:d3a99744-0170-48d3-a150-ae76b5fe7545',
      region: 'eu-west-2',
      userPoolId: 'eu-west-2_2tbtREnlI',
      userPoolWebClientId: '6007u8k63o9jr78rg9qpl0psip',
      mandatorySignIn: true,
      authenticationFlowType: 'USER_SRP_AUTH',

      oauth: {
        domain: 'city-on-palm-admin.auth.eu-west-2.amazoncognito.com',
        scope: ['phone', 'email', 'openid', 'aws.cognito.signin.user.admin', 'profile'],
        redirectSignIn: 'https://master.cityonpalm.com',
        redirectSignOut: 'https://cityonpalm.com',
        responseType: 'code',
      },
    },
    API: {
      endpoints: [
        {
          name: 'cityOnPalm-service',
          region: 'eu-west-2',
          endpoint: 'https://dksmzfjwt9.execute-api.eu-west-2.amazonaws.com/prod',
        },
      ],
    },
    Storage: {
      AWSS3: {
        bucketUrl: 'https://pub-media-files-prod.s3.eu-west-2.amazonaws.com/public/',
        bucket: 'pub-media-files-prod',
        region: 'eu-west-2',
      },
    },
  },
  supportedLanguages: [
    {
      value: 'uk',
      title: 'UK',
    },
    {
      value: 'en',
      title: 'EN',
    },
    {
      value: 'de',
      title: 'DE',
    },
    {
      value: 'ro',
      title: 'RO',
    },
    {
      value: 'pl',
      title: 'PL',
    },
  ],
  supportedCurrencies: [
    {
      value: 'uah',
      title: 'UAH',
      symbol: '',
    },
    {
      value: 'usd',
      title: 'USD',
      symbol: '',
    },
  ],
  locales: ['uk', 'en'],
  defaultLocale: 'uk',
};
