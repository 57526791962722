import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { environment } from '../../../../environments/environment';
import { FormGenerateService } from '../../../services/form-generate.service';
import { RestService } from '../../../services/restService.service';
import { CityModel } from '../../../components/city-page/models/CityModel';
import { CountryModel } from '../../../components/country-page/models/CountryModel';
import { CategoryResponseModel } from '../../../components/category-page/models/CategoryModel';
import { AddNewTourLocationModel } from '../models/AddNewTourLocation.model';
import { TouristLocation } from '../models/touristLocations';
import { UpdateTourLocationModel } from '../models/UpdateTourLocationModel';
import { TouristLocationsPageService } from '../services/tourist-locations-page.service';
import { IImageSignedUrlRequestObjectModel } from '../models/i-image-signed-url-request-object.model';
import { Storage } from 'aws-amplify';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TouristLocationFormModel } from '../models/tourist-location-form.model';

@Component({
  selector: 'app-add-edit-modal',
  templateUrl: 'add-edit-tourist-location.html',
  styleUrls: ['add-edit-tourist-location.scss'],
})
export class AddEditTouristLocation implements OnInit {
  readonly supportedLanguagesList = environment.supportedLanguages;

  // config
  cities: CityModel[] = [];
  countries: CountryModel[] = [];
  categories: CategoryResponseModel = {
    touristLocationTypeCategories: [],
    businessTypeCategories: [],
  };

  // data
  touristLocation: TouristLocation;
  imageSignedURL: string;
  selectedTourLocationImage: File;

  // state
  httpRequestInProgress = false;

  // form
  touristLocationForm: FormGroup<TouristLocationFormModel>;

  constructor(
    public dialogRef: MatDialogRef<AddEditTouristLocation>,
    public formGenerateService: FormGenerateService,
    public restService: RestService,
    public fb: UntypedFormBuilder,
    private touristLocationsPageService: TouristLocationsPageService,
    @Inject(MAT_DIALOG_DATA) data,
  ) {
    this.initForm();

    this.touristLocation = data?.touristLocation;

    if (this.touristLocation) {
      this.touristLocationForm.patchValue(this.touristLocation as any);

      // get tour location image
      if (this.touristLocation.S3Link) {
        Storage.get(this.touristLocation.S3Link).then((res) => (this.imageSignedURL = res));
      }
    }
  }

  @ViewChild('MultiLanguageElement', {read: ElementRef}) set setFocusElement(input: ElementRef | null) {
    this.formGenerateService.setFocus(input);
  }

  ngOnInit(): void {
    this.getCitiesList();
    this.getCategoryList();
    this.getCountryList();
  }

  /* form */
  private initForm(): void {
    this.touristLocationForm = this.fb.group({
      TitleMultiLanguage: this.createMultiLanguageControl(),
      DescriptionMultiLanguage: this.createMultiLanguageControl(),
      AddressMultiLanguage: this.createMultiLanguageControl(),
      CityId: ['', Validators.required],
      CountryId: ['', Validators.required],
      CategoryId: ['', Validators.required],
      Location: this.fb.group({
        lat: [0, Validators.required],
        lng: [0, Validators.required],
      }),
      Active: [false],
      S3Link: [''],
    });
  }

  private createMultiLanguageControl(): FormGroup<Record<string, FormControl<string>>> {
    const multiLanguageControl = this.fb.group({});

    this.supportedLanguagesList.forEach((language) => {
      multiLanguageControl.addControl(
        language.value,
        new FormControl<string>('', Validators.required),
      );
    });

    return multiLanguageControl;
  }


  /* form - end */

  /* general handlers */
  getCountryList(): void {
    this.restService.getCountries().subscribe((countries: CountryModel[]) => {
      this.countries = countries && countries.length ? countries : [];
    });
  }

  getCitiesList(): void {
    this.restService.getCities().subscribe((cities: CityModel[]) => {
      this.cities = cities && cities.length ? cities : [];
    });
  }

  getCategoryList(): void {
    this.restService.getCategories().subscribe((categories: CategoryResponseModel) => {
      this.categories = categories
        ? categories
        : {
          touristLocationTypeCategories: [],
          businessTypeCategories: [],
        };
    });
  }

  /* general handlers - end */

  addNewTourLocation() {
    const newTourLocation: AddNewTourLocationModel = {
      TitleMultiLanguage: this.touristLocationForm.controls.TitleMultiLanguage.value,
      DescriptionMultiLanguage: this.touristLocationForm.controls.DescriptionMultiLanguage.value,
      Location: {
        lat: this.touristLocationForm.controls.Location.controls.lat.value,
        lng: this.touristLocationForm.controls.Location.controls.lng.value,
      },
      CityId: this.touristLocationForm.controls.CityId.value,
      CountryId: this.touristLocationForm.controls.CountryId.value,
      AddressMultiLanguage: this.touristLocationForm.controls.AddressMultiLanguage.value,
      CategoryId: this.touristLocationForm.value.CategoryId,
    };

    this.httpRequestInProgress = true;

    this.touristLocationsPageService.addTouristLocation(newTourLocation).subscribe(
      (response) => {
        if (response) {
          alert('Нову туристичне місце додано!');
        } else {
          alert('Виникла не передбачування ситуація! Зверніться до адміністратора.');
        }
        this.httpRequestInProgress = false;

        this.dialogRef.close();
      },
      (error) => {
        console.log('error', error);
        alert('Виникла помилка при створенні нового туристичного місця ');
        this.httpRequestInProgress = false;
      },
    );
  }

  async updateTourLocation() {
    const updatedTourLocation: UpdateTourLocationModel = {
      TitleMultiLanguage: this.touristLocationForm.controls.TitleMultiLanguage.value,
      DescriptionMultiLanguage: this.touristLocationForm.controls.DescriptionMultiLanguage.value,
      Location: {
        lat: this.touristLocationForm.controls.Location.controls.lat.value,
        lng: this.touristLocationForm.controls.Location.controls.lng.value,
      },
      AddressMultiLanguage: this.touristLocationForm.controls.AddressMultiLanguage.value,
      ItemId: this.touristLocation.ItemId,
      UserId: this.touristLocation.UserId,
      Active: !!this.touristLocationForm.value.Active,
      S3Link: this.touristLocationForm.value.S3Link,
    };

    // upload image to s3 bucket and update tour location s3 property with link
    if (this.selectedTourLocationImage) {
      const key = await this.uploadImage(this.selectedTourLocationImage);

      updatedTourLocation.S3Link = key;
    }

    this.httpRequestInProgress = true;
    this.touristLocationsPageService.updateTouristLocation(updatedTourLocation).subscribe(
      (response) => {
        if (response) {
          alert('Оновлено інформацію про туристичне місце!');
        } else {
          alert('Виникла не передбачування ситуація! Зверніться до адміністратора.');
        }
        this.httpRequestInProgress = false;

        this.dialogRef.close();
      },
      (error) => {
        console.log('error', error);
        alert('Виникла помилка при редагуванні інформації про туристичне місце');
        this.httpRequestInProgress = false;
      },
    );
  }

  onFileSelect(file: File): void {
    this.selectedTourLocationImage = file;
    console.log('selected file =>', file);
  }

  async uploadImage(file: File): Promise<string> {
    let signedURL = '';
    let imageName = '';

    if (file.type === 'image/jpg' || file.type === 'image/jpeg' || file.type === 'image/png') {
      try {
        const {UploadURL, Key} = await this.getSignedImageUploadURl(file.type);

        signedURL = UploadURL;
        imageName = Key;

        // create new file with required key name
        const newFileImage = new File([file], imageName, {
          type: file.type,
        });

        if (signedURL && imageName) {
          await Storage.put(imageName, newFileImage, {
            contentType: 'image/*',
            level: 'public',
          })
            .then((result: any) => {
              console.log('image uploaded');
            })
            .catch((err) => {
              alert('Помилка при завантажені зображення.');
            });
        } else {
          alert('Не очікувана помилка');
        }
      } catch (e) {
        alert('Сталась помилка при отриманні авторизованого посилання');
      }
    } else {
      alert('Не вірний формат зображення');
    }

    return imageName;
  }

  private getSignedImageUploadURl(
    fileExtension: 'image/jpg' | 'image/jpeg' | 'image/png',
  ): Promise<{ Key: string; UploadURL: string }> {
    const getImageSignedURLRequestObject: IImageSignedUrlRequestObjectModel = {
      FileExtension: fileExtension,
    };

    return new Promise((resolve, reject) => {
      this.touristLocationsPageService.getImageSignedURLToUploadImage(getImageSignedURLRequestObject).subscribe({
        next: (res) => {
          resolve(res);
        },
        error: () => {
          reject();
        },
      });
    });
  }
}
