import { Component, OnInit, OnDestroy } from '@angular/core';
import { RestService } from '../../services/restService.service';
import { Subscription } from 'rxjs';
import { CategoryResponseModel } from './models/CategoryModel';

@Component({
  selector: 'app-category-page',
  templateUrl: './category-page.component.html',
  styleUrls: ['./category-page.component.scss'],
})
export class CategoryPageComponent implements OnInit, OnDestroy {
  public categories: CategoryResponseModel;
  public activeCategoryView: 'touristLocationCategories' | 'businessTypeCategories' = 'businessTypeCategories';

  private subscription: Subscription = new Subscription();

  constructor(private restService: RestService) {}

  ngOnInit(): void {
    this.getCategories();
  }

  getCategories() {
    this.subscription.add(
      this.restService.getCategories().subscribe((result: CategoryResponseModel) => {
        if (result) {
          this.categories = result;
        }
      }),
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
