import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { API } from 'aws-amplify';

@Injectable({
  providedIn: 'root',
})
export class EventsService {
  constructor() {}

  public getEvents(countryId: string, cityId: string): Observable<any> {
    return from(API.get('cityOnPalm-service', `/admin/country/${countryId}/city/${cityId}/events/getEvents`, {}));
  }

  public getEventById(Id: string): Observable<any> {
    return from(API.get('cityOnPalm-service', `/admin/events/${Id}/getEventById`, {}));
  }

  public addEvent(eventObj: any, countryId: string, cityId: string): Observable<any> {
    return from(
      API.post('cityOnPalm-service', `/admin/country/${countryId}/city/${cityId}/events/addEvent`, { body: eventObj }),
    );
  }

  public updateEvent(updateObj: any, countryId: string, cityId: string): Observable<any> {
    return from(
      API.put('cityOnPalm-service', `/admin/country/${countryId}/city/${cityId}/events/updateEvent`, {
        body: updateObj,
      }),
    );
  }

  public uploadImageToBucket(images: any, id: string): Observable<any> {
    return from(
      API.post('cityOnPalm-service', '/admin/media/uploadImage', {
        body: {
          Images: images,
          Id: id,
        },
      }),
    );
  }

  public removeImageFromBucket(images: any, id: string): Observable<any> {
    return from(
      API.post('cityOnPalm-service', '/admin/media/deleteImage', {
        body: {
          imageKey: images,
          Id: id,
        },
      }),
    );
  }

  public getImageSignedURLToUploadImage(urlRequestObject: {
    FileExtension: string;
  }): Observable<{ Key: string; UploadURL: string }> {
    return from(
      API.post('cityOnPalm-service', '/admin/events/generateImageSignedURL', {
        body: urlRequestObject,
      }),
    );
  }
}
