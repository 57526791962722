import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { API } from 'aws-amplify';

@Injectable({
  providedIn: 'root',
})
export class RegisteredUsersPageService {
  public getBusinessUsers(): Observable<any> {
    return from(API.get('cityOnPalm-service', '/admin/account-management/businessUsers', {}));
  }

  public getMobileUsers(): Observable<any> {
    return from(API.get('cityOnPalm-service', '/admin/account-management/mobileUsers', {}));
  }
}
