import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { RestService } from '../../../services/restService.service';
import { environment } from '../../../../environments/environment';
import Swal from 'sweetalert2';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { MainPageTabs } from '../../../enums/mainPage.tabs';
import { Cities } from '../models/cities';
import { ExcursionData } from '../models/excursionData';
import { ImageService } from 'src/app/services/image.service';

@Component({
  selector: 'app-excursion-add',
  templateUrl: './excursion-add.page.html',
  styleUrls: ['./excursion-add.page.scss'],
})
export class ExcursionAddPageComponent implements OnInit, OnDestroy {
  public supportedLanguages = environment.supportedLanguages;
  public supportedCurrencies = environment.supportedCurrencies;
  public activeExcursionNameTab = 0;
  public activeShortDescriptionTab = 0;
  public activeLongDescriptionTab = 0;
  public cities: Cities[];
  public updating;
  public mainPageTabs = MainPageTabs;
  public excursionImageObject: { imageBase64: string; type: string };
  public excursionImages = [];

  public excursionData: ExcursionData;
  private subscription: Subscription = new Subscription();

  get ExcursionNameMultiLanguage(): UntypedFormArray {
    return this.excursionForm.get('ExcursionNameMultiLanguage') as UntypedFormArray;
  }

  public excursionForm: UntypedFormGroup = this.fb.group({
    ExcursionName: ['', Validators.required],
    ExcursionNameMultiLanguage: this.fb.array(this.generateFormControls('excursionName_')),
    ExcursionDuration: ['', Validators.required],
    ExcursionPrice: ['', Validators.required],
    ExcursionShortDescriptionMultiLanguage: this.fb.array(this.generateFormControls('excursionShortDescription_')),
    ExcursionLongDescriptionMultiLanguage: this.fb.array(this.generateFormControls('excursionLongDescription_')),
    Active: [true, Validators.required],
    ExcursionCityId: ['', Validators.required],
    ExcursionCurrency: ['', Validators.required],
  });

  constructor(
    private restService: RestService,
    private router: Router,
    private route: ActivatedRoute,
    private fb: UntypedFormBuilder,
    private imageService: ImageService,
  ) {}

  ngOnInit(): void {
    this.getCities();
    this.collectUrlParams();
    if (this.excursionData.AttachedImages) {
      const links = this.imageService.getLinkImages(this.excursionData.AttachedImages);
      Promise.all(links).then((images) => {
        console.log('images', images);
        this.excursionImages = images;
      });
      // this.getLinkImages(this.excursion.AttachedImages);
    }
  }

  collectUrlParams() {
    this.subscription.add(
      this.route.queryParams.subscribe((params) => {
        if (params.excursion) {
          this.excursionData = JSON.parse(params.excursion);
          this.excursionForm.patchValue(this.excursionData);
        }
      }),
    );
  }

  generateFormControls(key) {
    const formControls = [];

    this.supportedLanguages.forEach((language) => {
      const control = {};
      control[key + language.value] = new UntypedFormControl('', Validators.required);
      const group = new UntypedFormGroup(control);
      formControls.push(group);
    });
    return formControls;
  }

  getCities() {
    this.subscription.add(
      this.restService
        .getCities()
        .pipe(take(1))
        .subscribe((result) => {
          if (result) {
            this.cities = result;
          }
        }),
    );
  }

  updateExcursion() {
    const objToSubmit = this.excursionForm.value;
    if (objToSubmit.Active === 'true') {
      objToSubmit.Active = true;
    } else {
      objToSubmit.Active = false;
    }
    const id = this.excursionData.Id;
    const obj = { content: objToSubmit, id: id };

    this.subscription.add(
      this.restService.updateExcursion(obj).subscribe((result) => {
        if (result) {
          Swal.fire('Успіх!', 'Зміни збережено!', 'success');
          this.excursionForm.reset();
          this.router.navigate([`/main`], { queryParams: { activeTab: 'excursionTab' } });
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Помилка',
            text: `Виникла помилка під час редагування екскурсії`,
            confirmButtonColor: '#fcd900',
          });
        }
      }),
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
