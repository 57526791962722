<h1 mat-dialog-title><b>{{ 'AddEditTouristLocation#addLocation' | translate }}</b></h1>
<hr />
<div mat-dialog-content>
  <form [formGroup]="touristLocationForm" class="tourist-location-form">
    <section *ngIf="touristLocation" class="mb-3">
      <p class="mb-0"><b>{{ 'AddEditTouristLocation#inputActive' | translate }}</b></p>
      <select formControlName="Active">
        <option [value]="true">{{ 'Yes' | translate }}</option>
        <option [value]="false">{{ 'No' | translate }}</option>
      </select>
    </section>

    <section>
      <div class="form-group">
        <ul #navName="ngbNav" class="nav-pills" ngbNav>
          <li
            *ngFor="let language of supportedLanguagesList; index as i"
            [ngbNavItem]="i"
            formArrayName="TitleMultiLanguage"
          >
            <a ngbNavLink>{{ language.title }}</a>

            <ng-template *ngFor="let item of TitleMultiLanguage.controls | slice: i" ngbNavContent>
              <label [formGroupName]="i" class="form-group d-block" for="TitleMultiLanguage">
                <b>{{ 'AddEditTouristLocation#inputTitle' | translate }} ({{ "AppComponent#ML" | translate }})</b>
                <input
                  #MultiLanguageElement
                  class="general-input"
                  formControlName="titleMultiLanguage_{{ language.value }}"
                  id="TitleMultiLanguage"
                  maxlength="80"
                  type="text"
                />
              </label>
            </ng-template>
          </li>
        </ul>

        <div [ngbNavOutlet]="navName" class="mt-2"></div>
      </div>
    </section>

    <section>
      <div class="form-group">
        <ul #navDescription="ngbNav" class="nav-pills" ngbNav>
          <li
            *ngFor="let language of supportedLanguagesList; index as i"
            [ngbNavItem]="i"
            formArrayName="DescriptionMultiLanguage"
          >
            <a ngbNavLink>{{ language.title }}</a>

            <ng-template *ngFor="let item of DescriptionMultiLanguage.controls | slice: i" ngbNavContent>
              <label [formGroupName]="i" class="form-group d-block" for="DescriptionMultiLanguage">
                <b>{{ 'AddEditTouristLocation#inputDescription' | translate }} ({{ "AppComponent#ML" | translate }})</b>
                <textarea
                  #MultiLanguageElement
                  class="general-input"
                  formControlName="descriptionMultiLanguage_{{ language.value }}"
                  id="DescriptionMultiLanguage"
                  rows="10"
                  type="text"
                ></textarea>
              </label>
            </ng-template>
          </li>
        </ul>

        <div [ngbNavOutlet]="navDescription" class="mt-2"></div>
      </div>
    </section>

    <section *ngIf="!touristLocation">
      <p class="mb-0"><b>{{ 'AddEditTouristLocation#inputCountry' | translate }}</b></p>
      <select formControlName="CountryId">
        <option *ngFor="let country of countries" value="{{country.id}}">{{ country.countryInternalName }}</option>
      </select>
    </section>

    <section *ngIf="!touristLocation">
      <p class="mb-0 mt-3"><b>{{ 'AddEditTouristLocation#inputCity' | translate }}</b></p>
      <select formControlName="CityId">
        <option *ngFor="let city of cities" value="{{city.id}}">{{ city.cityInternalName }}</option>
      </select>
    </section>

    <section *ngIf="!touristLocation">
      <p class="mb-0 mt-3"><b>{{ 'AddEditTouristLocation#inputCategory' | translate }}</b></p>
      <select formControlName="CategoryId">
        <option *ngFor="let category of categories?.touristLocationTypeCategories" value="{{category.id}}">
          {{ category?.categoryMultiLanguageName.uk }}
        </option>
      </select>
    </section>

    <section class="mt-3">
      <div class="form-group">
        <ul #navAddress="ngbNav" class="nav-pills" ngbNav>
          <li
            *ngFor="let language of supportedLanguagesList; index as i"
            [ngbNavItem]="i"
            formArrayName="AddressMultiLanguage"
          >
            <a ngbNavLink>{{ language.title }}</a>

            <ng-template *ngFor="let item of AddressMultiLanguage.controls | slice: i" ngbNavContent>
              <label [formGroupName]="i" class="form-group d-block" for="AddressMultiLanguage">
                <b>{{ 'AddEditTouristLocation#inputAddress' | translate }} ({{ "AppComponent#ML" | translate }})</b>
                <input
                  #MultiLanguageElement
                  class="general-input"
                  formControlName="addressMultiLanguage_{{ language.value }}"
                  id="AddressMultiLanguage"
                  maxlength="80"
                  type="text"
                />
              </label>
            </ng-template>
          </li>
        </ul>

        <div [ngbNavOutlet]="navAddress" class="mt-2"></div>
      </div>
    </section>

    <section class="mt-3 d-flex align-items-end" formGroupName="Location">
      <div class="mr-3">
        <p class="mb-0"><b>{{ 'AddEditTouristLocation#inputLocationLat' | translate }}</b></p>
        <input class="general-input" formControlName="lat" type="number" />
      </div>

      <div class="mr-3">
        <p class="mb-0"><b>{{ 'AddEditTouristLocation#inputLocationLng' | translate }}</b></p>
        <input class="general-input" formControlName="lng" type="number" />
      </div>

      <p
        *ngIf="touristLocationForm.controls.Location.get('lat').value && touristLocationForm.controls.Location.get('lng').value"
        class="mb-0"
      >
        <a
          href="https://maps.google.com/?q={{touristLocationForm.controls.Location.get('lat').value}},{{touristLocationForm.controls.Location.get('lng').value}}"
          target="_blank"
          >Перевірити точку на карті</a
        >
      </p>
    </section>

    <section *ngIf="imageSignedURL" class="py-3">
      <p>
        <b> Зображення туристичної локації </b>
      </p>

      <img alt="tour location image" class="tour-location-image" src="{{imageSignedURL}}" />
    </section>

    <section class="py-3">
      <p><b>{{ 'AddEditTouristLocation#locationImage' | translate }}</b></p>
      <app-upload-to-s3-bucket (onFileSelect)="onFileSelect($event)"></app-upload-to-s3-bucket>
    </section>
  </form>
</div>

<hr />

<div mat-dialog-actions>
  <button cdkFocusInitial color="warn" mat-dialog-close mat-raised-button>{{ 'CANCEL' | translate }}</button>
  <button
    (click)="addNewTourLocation()"
    *ngIf="!touristLocation"
    [disabled]="touristLocationForm.invalid || httpRequestInProgress"
    color="primary"
    mat-raised-button
  >
    {{ 'SAVE' | translate }}
  </button>

  <button
    (click)="updateTourLocation()"
    *ngIf="touristLocation"
    [disabled]="!touristLocationForm.controls.Location.get('lat').value || !touristLocationForm.controls.Location.get('lng').value || httpRequestInProgress"
    color="primary"
    mat-raised-button
  >
    {{ 'UPDATE' | translate }}
  </button>
</div>
