import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { Shell } from '../shell/shell.service';
import { MainPageComponent } from './main-page.component';
import { ExcursionAddPageComponent } from '../../tabs/excursion-page/excursion-add-page/excursion-add.page';
import { EventsAddPageComponent } from '../../tabs/events-page/events-add-page/events-add.page';
import { StaticInformationViewComponent } from '../../tabs/static-information-view/static-information-view.component';
import { NotFoundComponent } from '../../shared/pages/notfound/notfound.component';
import { UserDetailsComponent } from '../../tabs/registered-users-view/user-details/user-details.component';

const routes: Routes = [
  Shell.childRoutes([
    { path: '', redirectTo: '/main', pathMatch: 'full' },
    { path: 'main', component: MainPageComponent, data: { title: 'Головна сторінка' } },
    { path: 'excursion-add', component: ExcursionAddPageComponent, data: { title: 'Додавання нової екскурсії' } },
    { path: 'event-add', component: EventsAddPageComponent, data: { title: 'Додавання нового заходу' } },
    { path: 'user-details', component: UserDetailsComponent, data: { title: 'Деталі зареєстрованого користувача' } },
    { path: 'static-info', component: StaticInformationViewComponent, data: { title: 'Статична інформація' } },
    {
      path: 'tourist-info',
      loadChildren: () =>
        import('../../tabs/tourist-locations-view/tourist-info-tab.module').then((m) => m.TouristInfoTabModule),
    },
    { path: 'notfound', component: NotFoundComponent },
    { path: '**', redirectTo: '/notfound' },
  ]),
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class MainRoutingModule {}
