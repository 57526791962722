import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { TablePage } from './tablePage';

@Injectable({
  providedIn: 'root',
})
export class PaginationServiceService {
  constructor() {}

  public pageItems(page, itemsPerPage, obj): Observable<TablePage> {
    var startIndex = itemsPerPage * (page - 1);
    return of(new TablePage(obj.length, obj.slice(startIndex, startIndex + itemsPerPage)));
  }
}
