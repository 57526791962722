import { StaticInfoConfigModel, StaticInfoTableColumnType } from '../models/static-info-config.model';

export const currencyExchangeConfig: StaticInfoConfigModel = Object.freeze({
  s3Key: 'currency-exchange',
  tableColumns: [
    {
      columnLabel: 'Id',
      columnKey: 'Id',
      type: StaticInfoTableColumnType.TEXT,
    },
    {
      columnLabel: 'Назва',
      columnKey: 'TitleMultiLanguage.titleMultiLanguage_uk',
      type: StaticInfoTableColumnType.TEXT,
    },
    {
      columnLabel: 'Активна',
      columnKey: 'Active',
      type: StaticInfoTableColumnType.BOOL,
    },
    {
      columnLabel: 'Адреса',
      columnKey: 'AddressMultiLanguage.addressMultiLanguage_uk',
      type: StaticInfoTableColumnType.TEXT,
    },
    {
      columnLabel: 'Локація',
      columnKey: 'Location',
      type: StaticInfoTableColumnType.LOCATION,
    },
  ],
});
