<div>
  <div class="d-flex">
    <label
      [for]="fileUploadEnabled ? 'file-upload' : ''"
      class="{{ fileUploadEnabled ? 'custom-file-upload' : 'custom-file-disabled' }}"
    >
      Завантажити зображення <i class="ml-2 font-semibold pi pi-upload"></i>
    </label>
    <input
      (change)="readImage($event)"
      [disabled]="!fileUploadEnabled"
      accept=".jpg, .jpeg, .png"
      id="file-upload"
      multiple
      type="file"
    />
  </div>

  <div *ngIf="urlPreview.length || preview.length">
    <p class="mt-2 text-gray-400">Максимум можна завантажити 1 зображення!</p>
    <div class="flex align-items-center flex-wrap">
      <div *ngFor="let image of urlPreview; let i = index" class="flex justify-content-start align-items-center mb-2">
        <img [src]="bucketLink + image" class="img-preview mr-1" width="100px" />
        <button
          (click)="removeS3Image(i)"
          class="mr-5 remove-button p-button-rounded p-button-danger p-button-text button-wrapper"
          icon="pi pi-times"
          pButton
        >
          Х
        </button>
      </div>
      <div *ngFor="let image of preview; let i = index" class="flex justify-content-start align-items-center mb-2">
        <img [src]="image.base64" class="img-preview mr-1" width="100px" />
        <button
          (click)="removeImage(i)"
          class="mr-5 remove-button p-button-rounded p-button-danger p-button-text button-wrapper"
          icon="pi pi-times"
        >
          X
        </button>
      </div>
    </div>
  </div>
</div>
