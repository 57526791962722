import { Component, OnDestroy, OnInit } from '@angular/core';
import { TouristLocation } from './models/touristLocations';
import { NewTouristLocation } from './models/newTouristLocation';
import { AddEditTouristLocation } from './add-edit-tourist-location/add-edit-tourist-location';
import { Subject } from 'rxjs';
import { TouristLocationsPageService } from './services/tourist-locations-page.service';
import { MatDialog } from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-tourist-locations-page',
  templateUrl: './tourist-locations-page.component.html',
  styleUrls: ['./tourist-locations-page.component.scss'],
})
export class TouristLocationsPageComponent implements OnInit, OnDestroy {
  public touristLocations: TouristLocation[] = [];
  public httpRequestInProgress = false;
  countryId: string;
  cityId: string;

  private unsubscribe$: Subject<void> = new Subject();

  constructor(
    public dialog: MatDialog,
    private touristLocationsPageService: TouristLocationsPageService,
  ) {}

  /* lifecycle hooks */
  ngOnInit(): void {
    this.getAllLocations();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /* lifecycle hooks - end */

  onCountrySelected(countryId: string): void {
    this.countryId = countryId;
    this.getAllLocations();
  }

  onCitySelected(cityId: string): void {
    this.cityId = cityId;
    this.getAllLocations();
  }

  public getAllLocations(): void {
    if (this.countryId && this.cityId) {
      this.touristLocationsPageService
        .getTouristLocations(this.countryId, this.cityId)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          (res) => {
            this.touristLocations = res;
          },
          (err) => {
            alert('Помилка при отриманні туристичних локацій ' + err);
          },
        );
    }
  }

  public addNewTouristLocation(): void {
    this.dialog
      .open(AddEditTouristLocation, {
        width: '50vw',
      })
      .afterClosed()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((newLocationData: NewTouristLocation) => {
        this.getAllLocations();
      });
  }

  public deleteTourLocation(UserId: string, ItemId: string): void {
    this.httpRequestInProgress = true;
    this.touristLocationsPageService
      .deleteTouristLocation({ UserId, ItemId })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (response) => {
          if (response) {
            alert('Видалено!');
          } else {
            alert('Виникла не передбачувана ситуація! Зверніться до адміністратора.');
          }
          this.httpRequestInProgress = false;
          this.getAllLocations();
        },
        () => {
          alert('Помилка при видаленні туристичного об`єкта');
          this.httpRequestInProgress = false;
          this.getAllLocations();
        },
      );
  }

  public editTourLocation(touristLocation: TouristLocation): void {
    this.dialog
      .open(AddEditTouristLocation, {
        width: '50vw',
        data: {
          touristLocation,
        },
      })
      .afterClosed()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((newLocationData: NewTouristLocation) => {
        this.getAllLocations();
      });
  }
}
