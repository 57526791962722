import { Injectable } from '@angular/core';
import { from, Observable, of, throwError } from 'rxjs';
import { environment } from '../../environments/environment.prod';
import { API } from 'aws-amplify';
import { AddNewTourLocationModel } from '../tabs/tourist-locations-view/models/AddNewTourLocation.model';
import { catchError } from 'rxjs/operators';
import { UpdateTourLocationModel } from '../tabs/tourist-locations-view/models/UpdateTourLocationModel';

@Injectable({
  providedIn: 'root',
})
export class RestService {
  generateBearerHeader() {
    let credentials: string;
    const credentialsKey = environment.credentialsKey;
    const savedCredentials = sessionStorage.getItem(credentialsKey) || localStorage.getItem(credentialsKey);
    if (savedCredentials) {
      credentials = JSON.parse(savedCredentials);
    }
    return {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + credentials,
      },
    };
  }

  constructor() {}

  public getCountries(): Observable<any> {
    return from(API.get('cityOnPalm-service', '/admin/general/getCountries', {}));
  }

  public getCities(): Observable<any> {
    return from(API.get('cityOnPalm-service', '/admin/general/getCities', {}));
  }

  public getCategories(): Observable<any> {
    return from(API.get('cityOnPalm-service', '/admin/general/getCategories', {}));
  }

  public getPlaces(): Observable<any> {
    return from(API.get('cityOnPalm-service', '/admin/businesses/getPlaces', {}));
  }

  public updatePlace(updateObj: any): Observable<any> {
    return from(API.put('cityOnPalm-service', '/admin/businesses/updatePlace', { body: updateObj }));
  }

  public getExcursions(): Observable<any> {
    return from(API.get('cityOnPalm-service', '/admin/excursions/getExcursions', {}));
  }

  public updateExcursion(updateObj: any): Observable<any> {
    return from(API.put('cityOnPalm-service', '/admin/excursions/updateExcursion', { body: updateObj }));
  }

  public moderate(obj: any): Observable<any> {
    return from(API.post('cityOnPalm-service', '/admin/general/moderate', { body: obj }));
  }

  public getTariffPlans(): Observable<any> {
    return from(API.get('cityOnPalm-service', '/admin/general/getTariffPlans', {}));
  }

  public getPlannedExcursions(): Observable<any> {
    return from(API.get('cityOnPalm-service', '/admin/excursions/getPlannedExcursions', {}));
  }

  login(loginObj: any): Observable<any> {
    return of();
    // const sendObj = JSON.parse(JSON.stringify(loginObj));
    // sendObj.grant_type = 'password';
    // return this.http.post(`${this.serverApiUrl}/oauth2/token`, sendObj, this.generateBasicHeader()
    // );
  }

  importPlaces(updateObj: any): Observable<any> {
    return of();
    // return this.http.post(`${this.serverApiUrl}/api/cms/Places/importPlaces`, { data: updateObj }, this.generateBearerHeader()).pipe(
    //     catchError(this.handleError<any>('/api/cms/Places/importPlaces', []))
    // );
  }
}
