import { Component, OnDestroy, OnInit } from '@angular/core';
import { StaticInfoService } from '../../shared/static-info.service';
import { IVeterinaryLocationsStaticInfoModel } from './models/i-veterinary-locations-static-info.model';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-veterinary-locations-component',
  templateUrl: 'veterinary-locations.component.html',
  styleUrls: ['./veterinary-locations.component.scss'],
})
export class VeterinaryLocationsComponent implements OnInit, OnDestroy {
  loading: boolean;
  countryId: string;
  cityId: string;
  staticInfo: IVeterinaryLocationsStaticInfoModel[] = [];
  protected readonly staticInfoType = 'veterinary-clinic';
  private unsubscribe$ = new Subject<void>();

  constructor(
    public dialog: MatDialog,
    private staticInfoService: StaticInfoService,
  ) {}

  // lifecycle hooks
  ngOnInit(): void {
    this.getData();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onCountrySelected(countryId: string): void {
    this.countryId = countryId;
    this.getData();
  }

  onCitySelected(cityId: string): void {
    this.cityId = cityId;
    this.getData();
  }

  getData(): void {
    if (this.countryId && this.cityId) {
      this.staticInfoService
        .getStaticInfo(this.staticInfoType, this.countryId, this.cityId)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((res: IVeterinaryLocationsStaticInfoModel[]) => (this.staticInfo = res));
    }
  }
}
