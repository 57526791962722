<div class="currency-exchange-wrapper">
  <app-import-data [staticInfoType]="staticInfoType" />
  <app-location-selector (citySelected)="onCitySelected($event)" (countrySelected)="onCountrySelected($event)" />

  <table class="table table-striped mt-3">
    <thead>
      <tr>
        <th scope="col">Id</th>
        <th scope="col">Назва</th>
        <th scope="col">Активна</th>
        <th scope="col">URL</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let staticInfoItem of staticInfo">
        <td>{{ staticInfoItem.Id }}</td>
        <td>{{ staticInfoItem.TitleMultiLanguage.titleMultiLanguage_uk || '-' }}</td>
        <td>{{ staticInfoItem.Active === true ? ('ACTIVE' | translate) : ('NOT#ACTIVE' | translate) }}</td>
        <td>{{ staticInfoItem.URL || '-' }}</td>
      </tr>
    </tbody>
  </table>
</div>
