// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
      label: 0,
      sent: function () {
        if (t[0] & 1) throw t[1];
        return t[1];
      },
      trys: [],
      ops: []
    },
    f,
    y,
    t,
    g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;
  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }
  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");
    while (g && (g = 0, op[0] && (_ = 0)), _) try {
      if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
      if (y = 0, t) op = [op[0] & 2, t.value];
      switch (op[0]) {
        case 0:
        case 1:
          t = op;
          break;
        case 4:
          _.label++;
          return {
            value: op[1],
            done: false
          };
        case 5:
          _.label++;
          y = op[1];
          op = [0];
          continue;
        case 7:
          op = _.ops.pop();
          _.trys.pop();
          continue;
        default:
          if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
            _ = 0;
            continue;
          }
          if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
            _.label = op[1];
            break;
          }
          if (op[0] === 6 && _.label < t[1]) {
            _.label = t[1];
            t = op;
            break;
          }
          if (t && _.label < t[2]) {
            _.label = t[2];
            _.ops.push(op);
            break;
          }
          if (t[2]) _.ops.pop();
          _.trys.pop();
          continue;
      }
      op = body.call(thisArg, _);
    } catch (e) {
      op = [6, e];
      y = 0;
    } finally {
      f = t = 0;
    }
    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};
import { ConsoleLogger as Logger, StorageAction } from '@aws-amplify/core';
import { putObject, createMultipartUpload, uploadPart, completeMultipartUpload, abortMultipartUpload, listParts } from '../AwsClients/S3';
import { SEND_DOWNLOAD_PROGRESS_EVENT, SEND_UPLOAD_PROGRESS_EVENT } from '../AwsClients/S3/utils';
import { EventEmitter } from 'events';
import { calculateContentMd5 } from '../common/MD5utils';
import { calculatePartSize, DEFAULT_PART_SIZE, DEFAULT_QUEUE_SIZE, MAX_OBJECT_SIZE, loadS3Config, getPrefix, credentialsProvider } from '../common/S3ClientUtils';
var logger = new Logger('AWSS3ProviderManagedUpload');
var AWSS3ProviderManagedUpload = /** @class */function () {
  function AWSS3ProviderManagedUpload(params, opts, emitter) {
    this.opts = null;
    this.completedParts = [];
    this.partSize = DEFAULT_PART_SIZE;
    // Progress reporting
    this.bytesUploaded = 0;
    this.totalBytesToUpload = 0;
    this.emitter = null;
    this.params = params;
    this.opts = __assign({
      isObjectLockEnabled: false
    }, opts);
    this.emitter = emitter;
    this.s3Config = loadS3Config(__assign(__assign({}, opts), {
      emitter: emitter,
      storageAction: StorageAction.Put
    }));
  }
  AWSS3ProviderManagedUpload.prototype.upload = function () {
    return __awaiter(this, void 0, void 0, function () {
      var isObjectLockEnabled, _a, _b, _c, _d, _e, numberOfPartsToUpload, parts, start, error_1;
      var _f;
      var _this = this;
      return __generator(this, function (_g) {
        switch (_g.label) {
          case 0:
            _g.trys.push([0, 12,, 14]);
            isObjectLockEnabled = this.opts.isObjectLockEnabled;
            if (!(isObjectLockEnabled === true)) return [3 /*break*/, 2];
            _a = this.params;
            return [4 /*yield*/, calculateContentMd5(
            // @ts-expect-error currently ReadableStream<any> is not being supported in put api
            this.params.Body)];
          case 1:
            _a.ContentMD5 = _g.sent();
            _g.label = 2;
          case 2:
            this.body = this.validateAndSanitizeBody(this.params.Body);
            this.totalBytesToUpload = this.byteLength(this.body);
            if (!(this.totalBytesToUpload <= DEFAULT_PART_SIZE)) return [3 /*break*/, 4];
            // Multipart upload is not required. Upload the sanitized body as is
            this.params.Body = this.body;
            _b = putObject;
            _c = [this.s3Config];
            _d = [__assign({}, this.params)];
            _f = {};
            return [4 /*yield*/, this.getObjectKeyWithPrefix(this.params.Key)];
          case 3:
            return [2 /*return*/, _b.apply(void 0, _c.concat([__assign.apply(void 0, _d.concat([(_f.Key = _g.sent(), _f)]))]))];
          case 4:
            // Step 1: Determine appropriate part size.
            this.partSize = calculatePartSize(this.totalBytesToUpload);
            // Step 2: Initiate the multi part upload
            _e = this;
            return [4 /*yield*/, this.createMultiPartUpload()];
          case 5:
            // Step 2: Initiate the multi part upload
            _e.uploadId = _g.sent();
            numberOfPartsToUpload = Math.ceil(this.totalBytesToUpload / this.partSize);
            parts = this.createParts();
            start = 0;
            _g.label = 6;
          case 6:
            if (!(start < numberOfPartsToUpload)) return [3 /*break*/, 9];
            // Upload as many as `queueSize` parts simultaneously
            return [4 /*yield*/, this.uploadParts(this.uploadId, parts.slice(start, start + DEFAULT_QUEUE_SIZE))];
          case 7:
            // Upload as many as `queueSize` parts simultaneously
            _g.sent();
            _g.label = 8;
          case 8:
            start += DEFAULT_QUEUE_SIZE;
            return [3 /*break*/, 6];
          case 9:
            parts.map(function (part) {
              _this.removeEventListener(part);
            });
            return [4 /*yield*/, this.finishMultiPartUpload(this.uploadId)];
          case 10:
            // Step 3: Finalize the upload such that S3 can recreate the file
            return [2 /*return*/, _g.sent()];
          case 11:
            return [3 /*break*/, 14];
          case 12:
            error_1 = _g.sent();
            // if any error is thrown, call cleanup
            return [4 /*yield*/, this.cleanup(this.uploadId)];
          case 13:
            // if any error is thrown, call cleanup
            _g.sent();
            logger.error('Error. Cancelling the multipart upload.');
            throw error_1;
          case 14:
            return [2 /*return*/];
        }
      });
    });
  };
  AWSS3ProviderManagedUpload.prototype.createParts = function () {
    try {
      var parts = [];
      for (var bodyStart = 0; bodyStart < this.totalBytesToUpload;) {
        var bodyEnd = Math.min(bodyStart + this.partSize, this.totalBytesToUpload);
        parts.push({
          bodyPart: this.body.slice(bodyStart, bodyEnd),
          partNumber: parts.length + 1,
          emitter: new EventEmitter(),
          _lastUploadedBytes: 0
        });
        bodyStart += this.partSize;
      }
      return parts;
    } catch (error) {
      logger.error(error);
      throw error;
    }
  };
  AWSS3ProviderManagedUpload.prototype.createMultiPartUpload = function () {
    return __awaiter(this, void 0, void 0, function () {
      var response, _a, _b, _c, error_2;
      var _d;
      return __generator(this, function (_e) {
        switch (_e.label) {
          case 0:
            _e.trys.push([0, 3,, 4]);
            _a = createMultipartUpload;
            _b = [this.s3Config];
            _c = [__assign({}, this.params)];
            _d = {};
            return [4 /*yield*/, this.getObjectKeyWithPrefix(this.params.Key)];
          case 1:
            return [4 /*yield*/, _a.apply(void 0, _b.concat([__assign.apply(void 0, _c.concat([(_d.Key = _e.sent(), _d)]))]))];
          case 2:
            response = _e.sent();
            logger.debug(response.UploadId);
            return [2 /*return*/, response.UploadId];
          case 3:
            error_2 = _e.sent();
            logger.error(error_2);
            throw error_2;
          case 4:
            return [2 /*return*/];
        }
      });
    });
  };
  /**
   * @private Not to be extended outside of tests
   * @VisibleFotTesting
   */
  AWSS3ProviderManagedUpload.prototype.uploadParts = function (uploadId, parts) {
    return __awaiter(this, void 0, void 0, function () {
      var allResults, i, error_3;
      var _this = this;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            _a.trys.push([0, 2,, 3]);
            return [4 /*yield*/, Promise.all(parts.map(function (part) {
              return __awaiter(_this, void 0, void 0, function () {
                var isObjectLockEnabled, _a, _b, Key, Bucket, SSECustomerAlgorithm, SSECustomerKey, SSECustomerKeyMD5, ContentMD5, res, _c, _d;
                var _e;
                return __generator(this, function (_f) {
                  switch (_f.label) {
                    case 0:
                      this.setupEventListener(part);
                      isObjectLockEnabled = this.opts.isObjectLockEnabled;
                      if (!isObjectLockEnabled) return [3 /*break*/, 2];
                      _a = this.params;
                      return [4 /*yield*/, calculateContentMd5(part.bodyPart)];
                    case 1:
                      _a.ContentMD5 = _f.sent();
                      _f.label = 2;
                    case 2:
                      _b = this.params, Key = _b.Key, Bucket = _b.Bucket, SSECustomerAlgorithm = _b.SSECustomerAlgorithm, SSECustomerKey = _b.SSECustomerKey, SSECustomerKeyMD5 = _b.SSECustomerKeyMD5, ContentMD5 = _b.ContentMD5;
                      _c = uploadPart;
                      _d = [__assign(__assign({}, this.s3Config), {
                        emitter: part.emitter
                      })];
                      _e = {
                        PartNumber: part.partNumber,
                        Body: part.bodyPart,
                        UploadId: uploadId
                      };
                      return [4 /*yield*/, this.getObjectKeyWithPrefix(this.params.Key)];
                    case 3:
                      return [4 /*yield*/, _c.apply(void 0, _d.concat([(_e.Key = _f.sent(), _e.Bucket = Bucket, _e.SSECustomerAlgorithm = SSECustomerAlgorithm, _e.SSECustomerKey = SSECustomerKey, _e.SSECustomerKeyMD5 = SSECustomerKeyMD5, _e.ContentMD5 = ContentMD5, _e)]))];
                    case 4:
                      res = _f.sent();
                      return [2 /*return*/, res];
                  }
                });
              });
            }))];
          case 1:
            allResults = _a.sent();
            // The order of resolved promises is the same as input promise order.
            for (i = 0; i < allResults.length; i++) {
              this.completedParts.push({
                PartNumber: parts[i].partNumber,
                ETag: allResults[i].ETag
              });
            }
            return [3 /*break*/, 3];
          case 2:
            error_3 = _a.sent();
            logger.error('Error happened while uploading a part. Cancelling the multipart upload');
            throw error_3;
          case 3:
            return [2 /*return*/];
        }
      });
    });
  };
  AWSS3ProviderManagedUpload.prototype.finishMultiPartUpload = function (uploadId) {
    return __awaiter(this, void 0, void 0, function () {
      var input, Key, error_4;
      var _a;
      return __generator(this, function (_b) {
        switch (_b.label) {
          case 0:
            _a = {
              Bucket: this.params.Bucket
            };
            return [4 /*yield*/, this.getObjectKeyWithPrefix(this.params.Key)];
          case 1:
            input = (_a.Key = _b.sent(), _a.UploadId = uploadId, _a.MultipartUpload = {
              Parts: this.completedParts
            }, _a);
            _b.label = 2;
          case 2:
            _b.trys.push([2, 4,, 5]);
            return [4 /*yield*/, completeMultipartUpload(__assign(__assign({}, this.s3Config), {
              emitter: undefined
            }), input)];
          case 3:
            Key = _b.sent().Key;
            return [2 /*return*/, Key];
          case 4:
            error_4 = _b.sent();
            logger.error('Error happened while finishing the upload.');
            throw error_4;
          case 5:
            return [2 /*return*/];
        }
      });
    });
  };
  AWSS3ProviderManagedUpload.prototype.cleanup = function (uploadId) {
    return __awaiter(this, void 0, void 0, function () {
      var input, data;
      var _a;
      return __generator(this, function (_b) {
        switch (_b.label) {
          case 0:
            // Reset this's state
            this.body = null;
            this.completedParts = [];
            this.bytesUploaded = 0;
            this.totalBytesToUpload = 0;
            if (!uploadId) {
              // This is a single part upload;
              return [2 /*return*/];
            }
            _a = {
              Bucket: this.params.Bucket
            };
            return [4 /*yield*/, this.getObjectKeyWithPrefix(this.params.Key)];
          case 1:
            input = (_a.Key = _b.sent(), _a.UploadId = uploadId, _a);
            return [4 /*yield*/, abortMultipartUpload(this.s3Config, input)];
          case 2:
            _b.sent();
            return [4 /*yield*/, listParts(this.s3Config, input)];
          case 3:
            data = _b.sent();
            if (data && data.Parts && data.Parts.length > 0) {
              throw new Error('Multipart upload clean up failed.');
            }
            return [2 /*return*/];
        }
      });
    });
  };
  AWSS3ProviderManagedUpload.prototype.removeEventListener = function (part) {
    part.emitter.removeAllListeners(SEND_UPLOAD_PROGRESS_EVENT);
    part.emitter.removeAllListeners(SEND_DOWNLOAD_PROGRESS_EVENT);
  };
  AWSS3ProviderManagedUpload.prototype.setupEventListener = function (part) {
    var _this = this;
    part.emitter.on(SEND_UPLOAD_PROGRESS_EVENT, function (progress) {
      _this.progressChanged(part.partNumber, progress.loaded - part._lastUploadedBytes);
      part._lastUploadedBytes = progress.loaded;
    });
  };
  AWSS3ProviderManagedUpload.prototype.progressChanged = function (partNumber, incrementalUpdate) {
    this.bytesUploaded += incrementalUpdate;
    this.emitter.emit(SEND_UPLOAD_PROGRESS_EVENT, {
      loaded: this.bytesUploaded,
      total: this.totalBytesToUpload,
      part: partNumber,
      key: this.params.Key
    });
  };
  AWSS3ProviderManagedUpload.prototype.byteLength = function (input) {
    if (input === null || input === undefined) return 0;
    if (typeof input.byteLength === 'number') {
      return input.byteLength;
    } else if (typeof input.length === 'number') {
      return input.length;
    } else if (typeof input.size === 'number') {
      return input.size;
    } else if (typeof input.path === 'string') {
      /* NodeJs Support
      return require('fs').lstatSync(input.path).size;
      */
    } else {
      throw new Error('Cannot determine length of ' + input);
    }
  };
  AWSS3ProviderManagedUpload.prototype.validateAndSanitizeBody = function (body) {
    var sanitizedBody = this.isGenericObject(body) ? JSON.stringify(body) : body;
    /* TODO: streams and files for nodejs
    if (
        typeof body.path === 'string' &&
        require('fs').lstatSync(body.path).size > 0
    ) {
        sanitizedBody = body;
    } */
    if (this.byteLength(sanitizedBody) > MAX_OBJECT_SIZE) {
      throw new Error("File size bigger than S3 Object limit of 5TB, got ".concat(this.totalBytesToUpload, " Bytes"));
    }
    return sanitizedBody;
  };
  AWSS3ProviderManagedUpload.prototype.isGenericObject = function (body) {
    if (body !== null && typeof body === 'object') {
      try {
        return !(this.byteLength(body) >= 0);
      } catch (error) {
        // If we cannot determine the length of the body, consider it
        // as a generic object and upload a stringified version of it
        return true;
      }
    }
    return false;
  };
  AWSS3ProviderManagedUpload.prototype.getObjectKeyWithPrefix = function (keyWithoutPrefix) {
    return __awaiter(this, void 0, void 0, function () {
      var _a, _b;
      var _c;
      return __generator(this, function (_d) {
        switch (_d.label) {
          case 0:
            _a = getPrefix;
            _b = [__assign({}, this.opts)];
            _c = {};
            return [4 /*yield*/, credentialsProvider()];
          case 1:
            return [4 /*yield*/, _a.apply(void 0, [__assign.apply(void 0, _b.concat([(_c.credentials = _d.sent(), _c)]))])];
          case 2:
            return [2 /*return*/, _d.sent() + keyWithoutPrefix];
        }
      });
    });
  };
  return AWSS3ProviderManagedUpload;
}();
export { AWSS3ProviderManagedUpload };