import { Component, OnInit } from '@angular/core';
import { Users } from '../../models/users';
import { RegisteredUsersPageService } from '../../registered-users-page.service';

@Component({
  selector: 'app-business-users',
  templateUrl: './business-users.component.html',
  styleUrls: ['./business-users.component.scss'],
})
export class BusinessUsersComponent implements OnInit {
  public businessUsers: Users[] = [];

  constructor(private registeredUsersPageService: RegisteredUsersPageService) {}

  ngOnInit(): void {
    this.getBusinessUsers();
  }

  getBusinessUsers() {
    this.registeredUsersPageService.getBusinessUsers().subscribe((res) => {
      this.businessUsers = res;
    });
  }
}
