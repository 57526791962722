<div class="static-information-wrapper">
  <mat-tab-group (selectedTabChange)="onTabChange($event)" mat-align-tabs="start" mat-stretch-tabs="false">
    <ng-container *ngFor="let configItem of tabConfig; let index = index">
      <mat-tab label="{{ configItem.label }}">
        <app-static-info *ngIf="activeTabIndex === index" [staticInfoConfig]="configItem.config" />
      </mat-tab>
    </ng-container>
    <!--    <mat-tab label="Обмінники">-->
    <!--      <app-exchange-currency-component></app-exchange-currency-component>-->
    <!--    </mat-tab>-->
    <!--    <mat-tab label="Сайти державних органів">-->
    <!--      <app-gov-websites-component></app-gov-websites-component>-->
    <!--    </mat-tab>-->
    <!--    <mat-tab label="Культурні центри">-->
    <!--      <app-culture-location-component></app-culture-location-component>-->
    <!--    </mat-tab>-->
    <!--    <mat-tab label="Укриття">-->
    <!--      <app-shelters-locations-component></app-shelters-locations-component>-->
    <!--    </mat-tab>-->
    <!--    <mat-tab label="Волонтерські центри">-->
    <!--      <app-volunteer-centers-component></app-volunteer-centers-component>-->
    <!--    </mat-tab>-->
    <!--    <mat-tab label="Центри незламності">-->
    <!--      <app-war-2022-component></app-war-2022-component>-->
    <!--    </mat-tab>-->
    <!--    <mat-tab label="Лікарні">-->
    <!--      <app-hospital-locations-component></app-hospital-locations-component>-->
    <!--    </mat-tab>-->
    <!--    <mat-tab label="Ветеринарні лікарні">-->
    <!--      <app-veterinary-locations-component></app-veterinary-locations-component>-->
    <!--    </mat-tab>-->
    <!--    <mat-tab label="Корисні телефони">-->
    <!--      <app-useful-phone-numbers-component></app-useful-phone-numbers-component>-->
    <!--    </mat-tab>-->
    <!--    <mat-tab label="Оренда транспорту">-->
    <!--      <app-vehicle-rent-component></app-vehicle-rent-component>-->
    <!--    </mat-tab>-->
    <!--    <mat-tab label="Тур. інф. центри">-->
    <!--      <app-tour-information-centers-component></app-tour-information-centers-component>-->
    <!--    </mat-tab>-->
    <!--    <mat-tab label="Радіостанції">-->
    <!--      <app-radio-stations-component></app-radio-stations-component>-->
    <!--    </mat-tab>-->
    <!--    <mat-tab label="Місцеві новинні джерела">-->
    <!--      <app-local-mass-media-sources-component></app-local-mass-media-sources-component>-->
    <!--    </mat-tab>-->
    <!--    <mat-tab label="Заправні станції">-->
    <!--      <app-fuel-stations-component></app-fuel-stations-component>-->
    <!--    </mat-tab>-->
    <!--    <mat-tab label="Аптеки">-->
    <!--      <app-pharmacy-store-component></app-pharmacy-store-component>-->
    <!--    </mat-tab>-->
    <!--    <mat-tab label="Банкомати">-->
    <!--      <app-atm-locations-component></app-atm-locations-component>-->
    <!--    </mat-tab>-->
  </mat-tab-group>
</div>
