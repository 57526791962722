export enum MainPageTabs {
  countryTab,
  cityTab,
  categoryTab,
  placeTab,
  eventTab,
  excursionTab,
  registeredUsersTab,
  touristLocations,
  staticInfo,
}
