import { Component, Inject, OnInit } from '@angular/core';
import { CityModel } from '../../../../components/city-page/models/CityModel';
import { CountryModel } from '../../../../components/country-page/models/CountryModel';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { RestService } from '../../../../services/restService.service';
import { IAddEditStaticInfoDataModel } from '../../models/IAddEditStaticInfoData.model';
import { FormGenerateService } from '../../../../services/form-generate.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-add-edit-static-info',
  templateUrl: 'add-edit-static-info-component.html',
  styleUrls: ['add-edit-static-info-component.scss'],
})
export class AddEditStaticInfoComponent implements OnInit {
  cities: CityModel[] = [];
  countries: CountryModel[] = [];
  httpRequestInProgress = false;
  staticInfoData: IAddEditStaticInfoDataModel;

  staticInfoDataForm: UntypedFormGroup = this.formBuilder.group({
    TitleMultiLanguage: this.formBuilder.array(
      this.formGenerateService.generateFormControlMultiLanguage('titleMultiLanguage_', '', [
        { type: 'maxLength', value: 80 },
      ]),
    ),
    DescriptionMultiLanguage: this.formBuilder.array(
      this.formGenerateService.generateFormControlMultiLanguage('descriptionMultiLanguage_', '', []),
    ),
    Address: this.formBuilder.array(
      this.formGenerateService.generateFormControlMultiLanguage('addressMultiLanguage_', '', []),
    ),
    CityId: ['', Validators.required],
    Country: ['', Validators.required],
    Location: this.formBuilder.group({
      lat: [''],
      lng: [''],
    }),
    StaticInfoType: [''],
    Id: [''],
    UrlLinks: this.formBuilder.array([]),
    Phones: this.formBuilder.array([]),
    WorkingHours: [''],
    SocialLinks: this.formBuilder.array([]),
    RadioFrequency: [''],
    Active: [false],
  });

  private subscription: Subscription = new Subscription();

  constructor(
    public dialogRef: MatDialogRef<AddEditStaticInfoComponent>,
    public restService: RestService,
    public formBuilder: UntypedFormBuilder,
    private formGenerateService: FormGenerateService,
    @Inject(MAT_DIALOG_DATA) data,
  ) {
    // init update data
    this.staticInfoData = data?.currencyExchange;

    //   if (this.touristLocation) {
    //     this.touristLocationForm.patchValue(this.touristLocation);
    //
    //     // get tour location image
    //     if (this.touristLocation.S3Link) {
    //       Storage.get(this.touristLocation.S3Link).then(res => this.imageSignedURL = res);
    //     }
    //   }
  }

  ngOnInit(): void {
    this.getCitiesList();
    this.getCountryList();
  }

  addCurrencyExchange(): void {
    // const newCurrencyExchange: IAddEditStaticInfoDataModel = {
    //   Location: this.staticInfoDataForm.value.Location,
    //   Address: this.staticInfoDataForm.value.Address,
    //   CityId: this.staticInfoDataForm.value.CityId,
    //   Country: this.staticInfoDataForm.value.Country
    // };
    //
    // this.httpRequestInProgress = true;
    //
    // console.log(newCurrencyExchange);
    //   this.subscription.add(this.touristLocationsPageService.addTouristLocation(newTourLocation).subscribe((response) => {
    //     if (response) {
    //       alert('Нову туристичне місце додано!');
    //     } else {
    //       alert('Виникла не передбачування ситуація! Зверніться до адміністратора.');
    //     }
    //     this.httpRequestInProgress = false;
    //
    //     this.dialogRef.close();
    //   }, (error) => {
    //     console.log('error', error);
    //     alert('Виникла помилка при створенні нового туристичного місця ');
    //     this.httpRequestInProgress = false;
    //   }));
  }

  updateCurrencyExchange(): void {
    // todo
  }

  getCitiesList() {
    this.subscription.add(
      this.restService.getCities().subscribe((cities: CityModel[]) => {
        this.cities = cities && cities.length ? cities : [];

        this.staticInfoDataForm.controls.CityId.patchValue(this.cities[0].id);
      }),
    );
  }

  getCountryList() {
    this.subscription.add(
      this.restService.getCountries().subscribe((countries: CountryModel[]) => {
        this.countries = countries && countries.length ? countries : [];

        this.staticInfoDataForm.controls.Country.patchValue(this.countries[0].countryInternalName);
      }),
    );
  }
}
