import { Component, OnDestroy, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { EventsService } from '../../services/events.service';
import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { Events } from './models/events';
import { FormBuilder, FormGroup } from '@angular/forms';
import { PaginationServiceService } from '../../services/pagination-service.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { debounceTime, switchMap } from 'rxjs/operators';
import { EventType } from './models/event-type.enum';
import { EventData } from './models/eventData';

@Component({
  selector: 'app-events-page',
  templateUrl: './events-page.component.html',
  styleUrls: ['./events-page.component.scss'],
})
export class EventsPageComponent implements OnInit, OnDestroy {
  public events: EventData[] = [];
  public filteredEvents: EventData[] = [];
  public eventSearchForm: FormGroup;
  public itemsPerPage: number;
  public ngbPagination: boolean;
  public page: number;
  public collectionSize: number;
  public countryId: string;
  public cityId: string;
  private selectedCity$ = new BehaviorSubject<string | null>(null);
  private selectedCountry$ = new BehaviorSubject<string | null>(null);
  private subscription: Subscription = new Subscription();

  constructor(
    private eventsService: EventsService,
    private fb: FormBuilder,
    private paginationService: PaginationServiceService,
    private modalService: NgbModal,
    private router: Router,
  ) {
    this.eventSearchForm = this.fb.group({
      searchId: [''],
      searchEventName: [''],
      itemsPerPage: [''],
      searchEventType: [EventType.AllEvents],
    });
  }

  ngOnInit(): void {
    this.page = 1;
    this.loadInitialData();
  }

  loadInitialData(): void {
    combineLatest([this.selectedCity$, this.selectedCountry$])
      .pipe(
        switchMap(([cityId, countryId]) => {
          if (cityId && countryId) {
            this.getEvents(countryId, cityId);
            return [];
          } else {
            return [];
          }
        }),
      )
      .subscribe((error) => {
        console.error('Помилка під час отримання подій:', error);
      });

    this.eventSearchForm.valueChanges.pipe(debounceTime(300)).subscribe(() => {
      this.filterEvents();
    });
  }

  addNewEvent(): void {
    this.router.navigate(['/event-add']);
  }

  eventEdit(value: Events): void {
    const Id = JSON.stringify(value.Id);
    this.router.navigate(['/event-add'], { queryParams: { Id } });
  }

  getEvents(countryId: string, cityId: string): void {
    this.subscription.add(
      this.eventsService.getEvents(countryId, cityId).subscribe((result) => {
        if (result) {
          this.events = result;
          this.filterEvents();
        }
      }),
    );
  }

  toggleActive(updateObj: any): void {
    const countryId: string = this.countryId;
    const cityId: string = this.cityId;
    const eventId: string = updateObj.Id;
    updateObj.Active = !updateObj.Active;
    updateObj.EventStartDate = this.extractDate(updateObj.EventStartISODateTime);
    updateObj.EventStartTime = this.extractTime(updateObj.EventStartISODateTime);
    delete updateObj.EventStartISODateTime;
    delete updateObj.ItemId;
    delete updateObj.UserId;
    delete updateObj.DateCreated;
    delete updateObj.LastActivity;
    delete updateObj.StatusChangeDate;
    delete updateObj.DateUpdated;
    delete updateObj.Id;
    updateObj = { content: updateObj, id: eventId };

    this.subscription.add(
      this.eventsService.updateEvent(updateObj, countryId, cityId).subscribe((result) => {
        if (result) {
          this.getEvents(countryId, cityId);
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Помилка',
            text: 'Виникла помилка під час активації/деактивації заходу',
            confirmButtonColor: '#fcd900',
          });
          this.getEvents(countryId, cityId);
        }
      }),
    );
  }

  filterEvents(): void {
    const searchOptions = this.eventSearchForm.value;
    this.filteredEvents = this.events.filter((event) => {
      return (
        (!searchOptions.searchId || event.Id.includes(searchOptions.searchId)) &&
        (!searchOptions.searchEventName ||
          event.InternalName.toLowerCase().includes(searchOptions.searchEventName.toLowerCase())) &&
        (searchOptions.searchEventType === EventType.AllEvents || event.Type === searchOptions.searchEventType)
      );
    });
  }

  paginationEvents(): void {
    const searchOptions = this.eventSearchForm.value;
    this.itemsPerPage = +searchOptions.itemsPerPage || 10;
    this.subscription.add(
      this.paginationService.pageItems(this.page, this.itemsPerPage, this.events).subscribe((page) => {
        if (page) {
          this.ngbPagination = true;
          this.filteredEvents = page.rows;
          this.collectionSize = page.totalCount;
        }
      }),
    );
  }

  open(content: any, event: Events): void {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' });
  }

  extractDate(isoDateString: string): string {
    return isoDateString.split('T')[0];
  }

  extractTime(isoDateString: string): string {
    return isoDateString.split('T')[1].split('.')[0];
  }

  onCitySelected(cityId: string): void {
    this.selectedCity$.next(cityId);
    this.cityId = cityId;
  }

  onCountrySelected(countryId: string): void {
    this.selectedCountry$.next(countryId);
    this.countryId = countryId;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
