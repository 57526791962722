import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MainPageComponent } from './main-page.component';
import { MainRoutingModule } from './main-routing.module';
import { HeaderComponent } from '../header/header.component';
import { CategoryPageComponent } from '../category-page/category-page.component';
import { CityPageComponent } from '../city-page/city-page.component';
import { CountryPageComponent } from '../country-page/country-page.component';
import { EventsPageComponent } from '../../tabs/events-page/events-page.component';
import { ExcursionAddPageComponent } from '../../tabs/excursion-page/excursion-add-page/excursion-add.page';
import { EventsAddPageComponent } from '../../tabs/events-page/events-add-page/events-add.page';
import { TranslateModule } from '@ngx-translate/core';
import { TouristLocationsPageComponent } from '../../tabs/tourist-locations-view/tourist-locations-page.component';

import { AddEditTouristLocation } from '../../tabs/tourist-locations-view/add-edit-tourist-location/add-edit-tourist-location';
import { MatIconModule } from '@angular/material/icon';
import { SharedModule } from '../../shared/shared.module';
import { TouristLocationsPageService } from '../../tabs/tourist-locations-view/services/tourist-locations-page.service';
import { StaticInformationViewComponent } from '../../tabs/static-information-view/static-information-view.component';
import { CurrencyExchangeComponent } from '../../tabs/static-information-view/components/currency-exchange/currency-exchange.component';
import { GovWebsitesComponent } from '../../tabs/static-information-view/components/gov-websites/gov-websites.component';
import { SheltersLocationsComponent } from '../../tabs/static-information-view/components/shelters-locations/shelters-locations.component';
import { VolunteerCentersComponent } from '../../tabs/static-information-view/components/volunteer-centers/volunteer-centers.component';
import { War2022SupportLocationsComponent } from '../../tabs/static-information-view/components/war-2022-support-locations/war-2022-support-locations.component';
import { HospitalLocationsComponent } from '../../tabs/static-information-view/components/hospital-locations/hospital-locations.component';
import { VeterinaryLocationsComponent } from '../../tabs/static-information-view/components/veterinary-locations/veterinary-locations.component';
import { UsefulPhoneNumbersComponent } from '../../tabs/static-information-view/components/useful-phone-numbers/useful-phone-numbers.component';
import { VehicleRentComponent } from '../../tabs/static-information-view/components/vehicle-rent/vehicle-rent.component';
import { TourInformationCentersComponent } from '../../tabs/static-information-view/components/tour-information-centers/tour-information-centers.component';
import { RadioStationsComponent } from '../../tabs/static-information-view/components/radio-stations/radio-stations.component';
import { LocalMassMediaSourcesComponent } from '../../tabs/static-information-view/components/local-mass-media-sources/local-mass-media-sources.component';
import { FuelStationsComponent } from '../../tabs/static-information-view/components/fuel-stations/fuel-stations.component';
import { PharmacyStoreComponent } from '../../tabs/static-information-view/components/pharmacy-store/pharmacy-store.component';
import { AtmLocationsComponent } from '../../tabs/static-information-view/components/atm-locations/atm-locations.component';
import { AddEditStaticInfoComponent } from '../../tabs/static-information-view/shared/add-edit-static-info-component/add-edit-static-info-component';
import { StaticInfoService } from '../../tabs/static-information-view/shared/static-info.service';
import { CultureLocationComponent } from '../../tabs/static-information-view/components/culture-location/culture-location.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { StaticInfoComponent } from '../../tabs/static-information-view/components/static-info/static-info.component';
import { LocationSelectorComponent } from '../../shared/components/location-selector/location-selector.component';
import { ImportDataComponent } from '../../shared/components/import-data/import-data.component';
import { RegisteredUsersPageComponent } from '../../tabs/registered-users-view/registered-users-page.component';
import { UserDetailsComponent } from '../../tabs/registered-users-view/user-details/user-details.component';
import { BusinessUsersComponent } from '../../tabs/registered-users-view/components/business-users/business-users.component';
import { MobileUsersComponent } from '../../tabs/registered-users-view/components/mobile-users/mobile-users.component';
import { ExcursionPageComponent } from '../../tabs/excursion-page/excursion-page.component';
import { ImportTouristLocationsComponent } from '../../shared/components/import-tourist-locations/import-tourist-locations.component';
import { ImageUploadComponent } from '../../shared/components/image-upload/image-upload.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgbModule,
    FormsModule,
    MainRoutingModule,
    TranslateModule.forChild(),
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    SharedModule,
    MatTabsModule,
    StaticInfoComponent,
    LocationSelectorComponent,
    ImportDataComponent,
    ExcursionPageComponent,
    ImportTouristLocationsComponent,
    ImageUploadComponent,
  ],
  declarations: [
    MainPageComponent,
    AddEditStaticInfoComponent,
    HeaderComponent,
    EventsPageComponent,
    EventsAddPageComponent,
    CountryPageComponent,
    CityPageComponent,
    CategoryPageComponent,
    ExcursionAddPageComponent,
    RegisteredUsersPageComponent,
    UserDetailsComponent,
    TouristLocationsPageComponent,
    AddEditTouristLocation,
    StaticInformationViewComponent,
    CurrencyExchangeComponent,
    GovWebsitesComponent,
    SheltersLocationsComponent,
    VolunteerCentersComponent,
    War2022SupportLocationsComponent,
    HospitalLocationsComponent,
    VeterinaryLocationsComponent,
    UsefulPhoneNumbersComponent,
    VehicleRentComponent,
    TourInformationCentersComponent,
    RadioStationsComponent,
    LocalMassMediaSourcesComponent,
    FuelStationsComponent,
    PharmacyStoreComponent,
    AtmLocationsComponent,
    CultureLocationComponent,
    BusinessUsersComponent,
    MobileUsersComponent,
  ],
  providers: [TouristLocationsPageService, StaticInfoService],
})
export class MainModule {}
