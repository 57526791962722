<app-header></app-header>

<div class="mx-3 mt-3">
  <ul #nav="ngbNav" (navChange)="updateUrl($event)" [(activeId)]="activeIdString" class="nav-pills" ngbNav>
    <li [ngbNavItem]="'countryTab'">
      <a ngbNavLink>{{ 'main-page#titleCountries' | translate }}</a>
      <ng-template ngbNavContent>
        <app-country-page></app-country-page>
      </ng-template>
    </li>
    <li [ngbNavItem]="'cityTab'">
      <a ngbNavLink>{{ 'main-page#titleCity' | translate }}</a>
      <ng-template ngbNavContent>
        <app-city-page></app-city-page>
      </ng-template>
    </li>
    <li [ngbNavItem]="'categoryTab'">
      <a ngbNavLink>{{ 'main-page#titleCategory' | translate }}</a>
      <ng-template ngbNavContent>
        <app-category-page></app-category-page>
      </ng-template>
    </li>
    <li [ngbNavItem]="'eventTab'">
      <a ngbNavLink>{{ 'main-page#titleEvents' | translate }}</a>
      <ng-template ngbNavContent>
        <app-events-page></app-events-page>
      </ng-template>
    </li>
    <li [ngbNavItem]="'excursionTab'">
      <a ngbNavLink>{{ 'main-page#titleExcursions' | translate }}</a>
      <ng-template ngbNavContent>
        <app-excursion-page />
      </ng-template>
    </li>
    <li [ngbNavItem]="'registeredUsersTab'">
      <a ngbNavLink>{{ 'main-page#titleRegisteredUsers' | translate }}</a>
      <ng-template ngbNavContent>
        <app-registered-users-page></app-registered-users-page>
      </ng-template>
    </li>
    <li [ngbNavItem]="'touristLocations'">
      <a ngbNavLink>{{ 'main-page#titleTouristLocations' | translate }}</a>
      <ng-template ngbNavContent>
        <app-tourist-info-tab></app-tourist-info-tab>
      </ng-template>
    </li>
    <li [ngbNavItem]="'staticInfo'">
      <a ngbNavLink>{{ 'main-page#titleStaticInfo' | translate }}</a>
      <ng-template ngbNavContent>
        <app-static-information-view-component></app-static-information-view-component>
      </ng-template>
    </li>
  </ul>

  <div [ngbNavOutlet]="nav"></div>
</div>
