<div class="currency-exchange-wrapper">
  <app-import-data [staticInfoType]="staticInfoType" />
  <app-location-selector (citySelected)="onCitySelected($event)" (countrySelected)="onCountrySelected($event)" />

  <table class="table table-striped mt-3">
    <thead>
      <tr>
        <th scope="col">Id</th>
        <th scope="col">Назва</th>
        <th scope="col">Активна</th>
        <th scope="col">Адреса</th>
        <th scope="col">Локація</th>
        <th scope="col">Телефони</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let staticInfoItem of staticInfo">
        <td>{{ staticInfoItem.Id }}</td>
        <td>{{ staticInfoItem.TitleMultiLanguage.titleMultiLanguage_uk || '-' }}</td>
        <td>{{ staticInfoItem.Active === true ? ('ACTIVE' | translate) : ('NOT#ACTIVE' | translate) }}</td>
        <td>{{ staticInfoItem.AddressMultiLanguage.addressMultiLanguage_uk || '-' }}</td>
        <td>
          <a
            *ngIf="staticInfoItem.Location.lat && staticInfoItem.Location.lng"
            href="https://maps.google.com/?q={{ staticInfoItem.Location.lat }},{{ staticInfoItem.Location.lng }}"
            target="_blank"
            ><{{ staticInfoItem.Location.lat }} , {{ staticInfoItem.Location.lng }}></a
          >

          <span *ngIf="!staticInfoItem.Location.lat || !staticInfoItem.Location.lng">-</span>
        </td>
        <td>
          <p *ngFor="let phone of staticInfoItem.Phones">{{ phone }}</p>
        </td>
      </tr>
    </tbody>
  </table>
</div>
