import { Component, OnDestroy, OnInit } from '@angular/core';
import { StaticInfoService } from '../../shared/static-info.service';
import { ICurrencyExchangeStaticInfoModel } from './models/i-currency-exchange-static-info.model';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-exchange-currency-component',
  templateUrl: 'currency-exchange.component.html',
  styleUrls: ['./currency-exchange.component.scss'],
})
export class CurrencyExchangeComponent implements OnInit, OnDestroy {
  loading: boolean;
  countryId: string;
  cityId: string;
  staticInfo: ICurrencyExchangeStaticInfoModel[] = [];
  protected readonly staticInfoType = 'currency-exchange';
  private unsubscribe$ = new Subject<void>();

  constructor(
    public dialog: MatDialog,
    private staticInfoService: StaticInfoService,
  ) {}

  // lifecycle hooks
  ngOnInit(): void {
    this.getData();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onCountrySelected(countryId: string): void {
    this.countryId = countryId;
    this.getData();
  }

  onCitySelected(cityId: string): void {
    this.cityId = cityId;
    this.getData();
  }

  getData(): void {
    if (this.countryId && this.cityId) {
      this.staticInfoService
        .getStaticInfo(this.staticInfoType, this.countryId, this.cityId)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((res: ICurrencyExchangeStaticInfoModel[]) => (this.staticInfo = res));
    }
  }

  exportData(): void {}
}
