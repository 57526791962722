<!-- animate.css -->
<link href="https://s3-us-west-2.amazonaws.com/s.cdpn.io/67239/animate.min.css" rel="stylesheet" />

<div class="overlay">
  <div class="ui-panel login-panel animated bounceInDown">
    <header>
      <div class="left logo">
        <img src="assets/images/logo.png" />
      </div>
    </header>

    <div class="login-form">
      <div class="subtitle">{{ 'login-page#loginAdmin' | translate }}</div>
      <form [formGroup]="loginForm">
        <input type="email" placeholder="Email" formControlName="username" required />

        <div *ngIf="loginForm.controls.username.touched && loginForm.controls.username.errors?.required">
          {{ 'login-page#loginRequiredEmail' | translate }}
        </div>
        <div *ngIf="loginForm.controls.username.touched && loginForm.controls.username.errors?.pattern">
          {{ 'login-page#loginCorrectFormatEmail' | translate }}
        </div>

        <input type="password" placeholder="Пароль" formControlName="password" required />

        <div *ngIf="loginForm.controls.username.touched && loginForm.controls.password.errors?.required">
          {{ 'login-page#loginRequiredPassword' | translate }}
        </div>
      </form>
    </div>

    <footer class="position-relative">
      <div class="right form-actions">
        <button
          (click)="loginAttempt()"
          class="btn btn-secondary btn-company mb-3 mr-3 font-big"
          [disabled]="loginForm.controls.username.invalid || loginForm.controls.password.invalid"
        >
          {{ 'login-page#loginBtnLogIn' | translate }}
        </button>
      </div>

      <div class="version-container">
        {{ environment.version }}
      </div>
    </footer>
  </div>
</div>
