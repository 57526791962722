import { Component, OnInit, OnDestroy } from '@angular/core';
import { RestService } from '../../services/restService.service';
import { Subscription } from 'rxjs';
import { ReactiveFormsModule, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Cities } from './models/cities';
import { CalendarExcursion } from './models/calendarExcursion';
import { DatePipe, NgForOf, NgIf } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-excursion-page',
  standalone: true,
  templateUrl: './excursion-page.component.html',
  styleUrls: ['./excursion-page.component.scss'],
  imports: [
    DatePipe,
    TranslateModule,
    ReactiveFormsModule,
    NgIf,
    NgForOf,
  ],
})
export class ExcursionPageComponent implements OnInit, OnDestroy {
  public excursions: CalendarExcursion[];
  public excursion: CalendarExcursion;
  public id: string;
  public filter: boolean;
  public filterExcursions: CalendarExcursion[];
  public cities: Cities[];
  public excursionSearchForm: UntypedFormGroup = new UntypedFormGroup({
    searchId: new UntypedFormControl(''),
    searchExcursionName: new UntypedFormControl(''),
    searchExcursionCity: new UntypedFormControl(''),
    searchExcursionStartDate: new UntypedFormControl(''),
    searchExcursionEndDate: new UntypedFormControl(this.dateWithoutTime(new Date().toISOString())),
  });

  private readonly subscription: Subscription = new Subscription();

  constructor(private restService: RestService) {}

  ngOnInit(): void {
    this.getExcursions();
    this.getCities();
  }

  getCities() {
    this.subscription.add(
      this.restService.getCities().subscribe((result) => {
        if (result) {
          this.cities = result;
        }
      }),
    );
  }

  getExcursions(): void {
    this.subscription.add(
      this.restService.getPlannedExcursions().subscribe((result) => {
        if (result) {
          this.excursions = result;
        }
      }),
    );
  }

  searchExcursion(event?) {
    const searchOptions = JSON.parse(JSON.stringify(this.excursionSearchForm.value));
    const filter = [];

    if (searchOptions.searchId) {
      for (const excursion of this.excursions) {
        if (searchOptions.searchId === excursion.Id) {
          filter.push(excursion);
        }
      }
      this.filterExcursions = filter;
      this.filter = true;
    }

    if (searchOptions.searchExcursionName) {
      for (const excursion of this.excursions) {
        if (searchOptions.searchExcursionName === excursion.Title) {
          filter.push(excursion);
        }
      }
      this.filterExcursions = filter;
      this.filter = true;
    }

    if (searchOptions.searchExcursionCity) {
      for (const excursion of this.excursions) {
        if (searchOptions.searchExcursionCity === excursion.CityId) {
          filter.push(excursion);
        }
      }
      this.filterExcursions = filter;
      this.filter = true;
    }

    if (searchOptions.searchExcursionStartDate) {
      const startDate = new Date(searchOptions.searchExcursionStartDate);
      const endDate = new Date(searchOptions.searchExcursionEndDate);

      for (const excursion of this.excursions) {
        const excursionDate = new Date(this.dateWithoutTime(excursion.Start));

        if (excursionDate >= startDate && excursionDate <= endDate) {
          filter.push(excursion);
        }
      }
      this.filterExcursions = filter;
      this.filter = true;
    }

    if (
      !searchOptions.searchId &&
      !searchOptions.searchExcursionName &&
      !searchOptions.searchExcursionCity &&
      !searchOptions.searchExcursionStartDate &&
      !searchOptions.searchExcursionEndDate &&
      !event
    ) {
      this.filter = false;
    }
  }

  private dateWithoutTime(date: string) {
    return date.split('T')[0];
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
