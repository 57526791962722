import { atmLocationsConfig } from '../config/atm-locations.config';
import { StaticInfoTabConfigModel } from './static-info-tab-config.model';
import { cultureLocationsConfig } from '../config/culture-location.config';
import { currencyExchangeConfig } from '../config/currency-exchange.config';
import { govWebsitesConfig } from '../config/gov-websites.config';
import { sheltersLocationsConfig } from '../config/shelters-locations.config';
import { veterinaryLocationsConfig } from '../config/veterinary-locations.config';
import { petrolStationConfig } from '../config/petrol-station.config';
import { pharmacyStoreConfig } from '../config/pharmacy-store.config';
import { usefulPhonesConfig } from '../config/useful-phones.config';
import { tourCenterConfig } from '../config/tour-center.config';
import { hospitalLocationConfig } from '../config/hospital-location.config';
import { radioStationConfig } from '../config/radio-station.config';
import { vehicleRentConfig } from '../config/vehicle-rent.config';
import { localMassMediaConfig } from '../config/local-mass-media.config';

export const staticInfoTabConfig: StaticInfoTabConfigModel[] = [
  {
    label: 'Культурні локації',
    config: cultureLocationsConfig,
  },
  {
    label: 'Культурні локації',
    config: cultureLocationsConfig,
  },
  {
    label: 'Обмінники',
    config: currencyExchangeConfig,
  },
  {
    label: 'Банкомати',
    config: atmLocationsConfig,
  },
  {
    label: 'Сайти державних органів',
    config: govWebsitesConfig,
  },
  {
    label: 'Укриття',
    config: sheltersLocationsConfig,
  },
  {
    label: 'Ветеринарні лікарні',
    config: veterinaryLocationsConfig,
  },
  {
    label: 'АЗС',
    config: petrolStationConfig,
  },
  {
    label: 'Аптеки',
    config: pharmacyStoreConfig,
  },
  {
    label: 'Корисні телефони',
    config: usefulPhonesConfig,
  },
  {
    label: 'Туристичні центри',
    config: tourCenterConfig,
  },
  {
    label: 'Лікарні',
    config: hospitalLocationConfig,
  },
  {
    label: 'Радіо станції',
    config: radioStationConfig,
  },
  {
    label: 'Оренда транспорту',
    config: vehicleRentConfig,
  },
  {
    label: 'ЗМІ',
    config: localMassMediaConfig,
  },
];
