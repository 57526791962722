<div class="page-header">
  <h4 class="modal-title" id="modal-basic-title">{{ 'user-details#title' | translate }}</h4>
</div>
<div class="container-fluid">
  <div class="row">
    <div class="col-lg p-4">
      <table class="table">
        <tbody>
          <tr>
            <th scope="row">{{ 'user-details#uniqueId' | translate }}</th>
            <td>{{ user.id }}</td>
          </tr>
          <tr>
            <th scope="row">{{ 'user-details#firstName' | translate }}</th>
            <td>{{ user.firstName }}</td>
          </tr>
          <tr>
            <th scope="row">{{ 'user-details#lastName' | translate }}</th>
            <td>{{ user.lastName }}</td>
          </tr>
          <tr>
            <th scope="row">{{ 'user-details#email' | translate }}</th>
            <td>{{ user.email }}</td>
          </tr>
          <tr>
            <th scope="row">{{ 'user-details#birthData' | translate }}</th>
            <td>{{ user.birthData }}</td>
          </tr>
          <tr>
            <th scope="row">{{ 'user-details#activityCreatedAt' | translate }}</th>
            <td>{{ user.activity.createdAt }}</td>
          </tr>
          <tr>
            <th scope="row">{{ 'user-details#activityTimeLog' | translate }}</th>
            <td *ngFor="let time of user.activity.activityTimeLog">{{ time.activityTime }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="col-lg p-4">
      <table class="table">
        <tbody>
          <tr>
            <th scope="row">{{ 'user-details#country' | translate }}</th>
            <td>{{ user.address.country }}</td>
          </tr>
          <tr>
            <th scope="row">{{ 'user-details#region' | translate }}</th>
            <td>{{ user.address.region }}</td>
          </tr>
          <tr>
            <th scope="row">{{ 'user-details#city' | translate }}</th>
            <td>{{ user.address.city }}</td>
          </tr>
          <tr>
            <th scope="row">{{ 'user-details#street' | translate }}</th>
            <td>{{ user.address.street }}</td>
          </tr>
          <tr>
            <th scope="row">{{ 'user-details#address' | translate }}</th>
            <td>{{ user.address.address }}</td>
          </tr>
          <tr>
            <th scope="row">{{ 'user-details#zip' | translate }}</th>
            <td>{{ user.address.zip }}</td>
          </tr>
          <tr>
            <th scope="row">{{ 'user-details#gdprAccepted' | translate }}</th>
            <td>{{ user.gdprAccepted }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="page-footer mt-5 mb-3 d-flex text-center justify-content-center">
    <button
      type="button"
      class="d-block btn btn-outline-primary"
      [routerLink]="'/main'"
      [queryParams]="{ activeTab: 'registeredUsersTab' }"
    >
      {{ 'user-details#back' | translate }}
    </button>
  </div>
</div>
