<div class="currency-exchange-wrapper">
  <div class="d-flex flex-column justify-content-start align-items-start mt-3">
    <!--    <button (click)="exportData()"-->
    <!--            [disabled]="loading"-->
    <!--            class="btn btn-warning mr-3">Експортувати дані-->
    <!--    </button>-->
    <app-import-data [staticInfoType]="staticInfoType" />
    <app-location-selector (citySelected)="onCitySelected($event)" (countrySelected)="onCountrySelected($event)" />

    <div [style.visibility]="loading ? 'visible' : 'hidden'" class="spinner-border" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>

  <table class="table table-striped mt-3">
    <thead>
      <tr>
        <th scope="col">Id</th>
        <th scope="col">Назва</th>
        <th scope="col">Активна</th>
        <th scope="col">Адреса</th>
        <th scope="col">Локація</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let staticInfoItem of staticInfo">
        <td>{{ staticInfoItem.Id }}</td>
        <td>{{ staticInfoItem.TitleMultiLanguage.titleMultiLanguage_uk || '-' }}</td>
        <td>{{ staticInfoItem.Active === true ? ('ACTIVE' | translate) : ('NOT#ACTIVE' | translate) }}</td>
        <td>{{ staticInfoItem.AddressMultiLanguage.addressMultiLanguage_uk || '-' }}</td>
        <td>
          <a
            *ngIf="staticInfoItem.Location.lat && staticInfoItem.Location.lng"
            href="https://maps.google.com/?q={{ staticInfoItem.Location.lat }},{{ staticInfoItem.Location.lng }}"
            target="_blank"
            ><{{ staticInfoItem.Location.lat }} , {{ staticInfoItem.Location.lng }}></a
          >

          <span *ngIf="!staticInfoItem.Location.lat || !staticInfoItem.Location.lng">-</span>
        </td>
      </tr>
      <tr>
        <td></td>
      </tr>
    </tbody>
  </table>
</div>
