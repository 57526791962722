var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
      label: 0,
      sent: function () {
        if (t[0] & 1) throw t[1];
        return t[1];
      },
      trys: [],
      ops: []
    },
    f,
    y,
    t,
    g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;
  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }
  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");
    while (g && (g = 0, op[0] && (_ = 0)), _) try {
      if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
      if (y = 0, t) op = [op[0] & 2, t.value];
      switch (op[0]) {
        case 0:
        case 1:
          t = op;
          break;
        case 4:
          _.label++;
          return {
            value: op[1],
            done: false
          };
        case 5:
          _.label++;
          y = op[1];
          op = [0];
          continue;
        case 7:
          op = _.ops.pop();
          _.trys.pop();
          continue;
        default:
          if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
            _ = 0;
            continue;
          }
          if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
            _.label = op[1];
            break;
          }
          if (op[0] === 6 && _.label < t[1]) {
            _.label = t[1];
            t = op;
            break;
          }
          if (t && _.label < t[2]) {
            _.label = t[2];
            _.ops.push(op);
            break;
          }
          if (t[2]) _.ops.pop();
          _.trys.pop();
          continue;
      }
      op = body.call(thisArg, _);
    } catch (e) {
      op = [6, e];
      y = 0;
    } finally {
      f = t = 0;
    }
    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};
// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
import { Category, Credentials, Logger, getAmplifyUserAgent } from '@aws-amplify/core';
import { localTestingStorageEndpoint } from './StorageConstants';
var logger = new Logger('S3ClientUtils');
// placeholder credentials in order to satisfy type requirement, always results in 403 when used
var INVALID_CRED = {
  accessKeyId: '',
  secretAccessKey: ''
};
export var getPrefix = function (config) {
  var credentials = config.credentials,
    level = config.level,
    customPrefix = config.customPrefix,
    identityId = config.identityId;
  var resolvedCustomPrefix = customPrefix || {};
  var resolvedIdentityId = identityId || credentials.identityId;
  var privatePath = (resolvedCustomPrefix.private !== undefined ? resolvedCustomPrefix.private : 'private/') + resolvedIdentityId + '/';
  var protectedPath = (resolvedCustomPrefix.protected !== undefined ? resolvedCustomPrefix.protected : 'protected/') + resolvedIdentityId + '/';
  var publicPath = resolvedCustomPrefix.public !== undefined ? resolvedCustomPrefix.public : 'public/';
  switch (level) {
    case 'private':
      return privatePath;
    case 'protected':
      return protectedPath;
    default:
      return publicPath;
  }
};
export var credentialsProvider = function () {
  return __awaiter(void 0, void 0, void 0, function () {
    var credentials, cred, error_1;
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          _a.trys.push([0, 2,, 3]);
          return [4 /*yield*/, Credentials.get()];
        case 1:
          credentials = _a.sent();
          if (!credentials) return [2 /*return*/, INVALID_CRED];
          cred = Credentials.shear(credentials);
          logger.debug('credentials provider get credentials', cred);
          return [2 /*return*/, cred];
        case 2:
          error_1 = _a.sent();
          logger.warn('credentials provider error', error_1);
          return [2 /*return*/, INVALID_CRED];
        case 3:
          return [2 /*return*/];
      }
    });
  });
};
/**
 * A function that persists the s3 configs, so we don't need to
 * assign each config parameter for every s3 API call.
 *
 * @inernal
 */
export var loadS3Config = function (config) {
  if (!config.region) {
    // Same error thrown by aws-sdk
    throw new Error('Region is missing.');
  }
  return __assign(__assign(__assign({}, config), {
    region: config.region,
    credentials: config.credentials ? function () {
      return Promise.resolve(config.credentials);
    } : credentialsProvider,
    userAgentValue: getAmplifyUserAgent({
      category: Category.Storage,
      action: config.storageAction
    })
  }), config.dangerouslyConnectToHttpEndpointForTesting ? {
    customEndpoint: localTestingStorageEndpoint,
    forcePathStyle: true
  } : {});
};
var MiB = 1024 * 1024;
var GiB = 1024 * MiB;
var TiB = 1024 * GiB;
export var DEFAULT_PART_SIZE = 5 * MiB;
export var MAX_OBJECT_SIZE = 5 * TiB;
export var MAX_PARTS_COUNT = 10000;
export var DEFAULT_QUEUE_SIZE = 4;
export var calculatePartSize = function (totalSize) {
  var partSize = DEFAULT_PART_SIZE;
  var partsCount = Math.ceil(totalSize / partSize);
  while (partsCount > MAX_PARTS_COUNT) {
    partSize *= 2;
    partsCount = Math.ceil(totalSize / partSize);
  }
  return partSize;
};