import { Component } from '@angular/core';
import { staticInfoTabConfig } from './models/static-info-tab-config';
import { StaticInfoTabConfigModel } from './models/static-info-tab-config.model';

@Component({
  selector: 'app-static-information-view-component',
  templateUrl: 'static-information-view.component.html',
  styleUrls: ['./static-information-view.component.scss'],
})
export class StaticInformationViewComponent {
  // config
  readonly tabConfig: StaticInfoTabConfigModel[] = staticInfoTabConfig;
  activeTabIndex = 0;

  onTabChange(event: any): void {
    this.activeTabIndex = event.index;
  }
}
