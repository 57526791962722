import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { RestService } from '../../../services/restService.service';
import { CountryModel } from '../../../components/country-page/models/CountryModel';
import { forkJoin, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Cities } from '../../../tabs/excursion-page/models/cities';
import { TranslateModule } from '@ngx-translate/core';
import { NgForOf } from '@angular/common';

@Component({
  selector: 'app-location-selector',
  standalone: true,
  templateUrl: './location-selector.component.html',
  styleUrls: ['./location-selector.component.scss'],
  imports: [TranslateModule, NgForOf],
})
export class LocationSelectorComponent implements OnInit, OnDestroy {
  @Output() countrySelected = new EventEmitter<string>();
  @Output() citySelected = new EventEmitter<string>();

  cities: Cities[] = [];
  countries: CountryModel[] = [];
  selectedCountryId: string;
  selectedCityId: string;
  loading = true;
  private unsubscribe$ = new Subject<void>();

  constructor(private restService: RestService) {}

  ngOnInit(): void {
    this.loadInitialData();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  loadInitialData(): void {
    forkJoin({
      countries: this.restService.getCountries(),
      cities: this.restService.getCities(),
    })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(({ countries, cities }) => {
        this.countries = countries;
        this.cities = cities;

        this.selectedCountryId = countries[0]?.id;
        this.selectedCityId = cities[0]?.id;

        this.emitSelections();
        this.loading = false;
      });
  }

  onCountryChange(event: Event): void {
    const selectElement = event.target as HTMLSelectElement;
    this.selectedCountryId = selectElement.value;
    this.emitSelections();
  }

  onCityChange(event: Event): void {
    const selectElement = event.target as HTMLSelectElement;
    this.selectedCityId = selectElement.value;
    this.emitSelections();
  }

  private emitSelections(): void {
    this.countrySelected.emit(this.selectedCountryId);
    this.citySelected.emit(this.selectedCityId);
  }
}
