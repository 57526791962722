import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { environment } from '../../../../environments/environment';
import { FormGenerateService } from '../../../services/form-generate.service';
import { RestService } from '../../../services/restService.service';
import { Subscription } from 'rxjs';
import { CityModel } from '../../../components/city-page/models/CityModel';
import { CountryModel } from '../../../components/country-page/models/CountryModel';
import { CategoryModel, CategoryResponseModel } from '../../../components/category-page/models/CategoryModel';
import { AddNewTourLocationModel } from '../models/AddNewTourLocation.model';
import { TouristLocation } from '../models/touristLocations';
import { UpdateTourLocationModel } from '../models/UpdateTourLocationModel';
import { TouristLocationsPageService } from '../services/tourist-locations-page.service';
import { IImageSignedUrlRequestObjectModel } from '../models/i-image-signed-url-request-object.model';

import { Storage } from 'aws-amplify';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-add-edit-modal',
  templateUrl: 'add-edit-tourist-location.html',
  styleUrls: ['add-edit-tourist-location.scss'],
})
export class AddEditTouristLocation implements OnInit {
  public supportedLanguagesList = environment.supportedLanguages;
  public cities: CityModel[] = [];
  public countries: CountryModel[] = [];
  public categories: CategoryResponseModel;

  public touristLocation: TouristLocation;

  public httpRequestInProgress = false;
  touristLocationForm: UntypedFormGroup = this.formBuilder.group({
    TitleMultiLanguage: this.formBuilder.array(
      this.formGenerateService.generateFormControlMultiLanguage('titleMultiLanguage_', '', [
        { type: 'maxLength', value: 80 },
      ]),
    ),
    DescriptionMultiLanguage: this.formBuilder.array(
      this.formGenerateService.generateFormControlMultiLanguage('descriptionMultiLanguage_', '', []),
    ),
    AddressMultiLanguage: this.formBuilder.array(
      this.formGenerateService.generateFormControlMultiLanguage('addressMultiLanguage_', '', []),
    ),
    CityId: ['', Validators.required],
    CountryId: ['', Validators.required],
    CategoryId: ['', Validators.required],
    Location: this.formBuilder.group({
      lat: ['', Validators.required],
      lng: ['', Validators.required],
    }),
    Active: [false],
    S3Link: [''],
  });
  imageSignedURL: string;
  // file upload functionality
  private selectedTourLocationImage: File;
  private subscription: Subscription = new Subscription();

  constructor(
    public dialogRef: MatDialogRef<AddEditTouristLocation>,
    public formGenerateService: FormGenerateService,
    public restService: RestService,
    public formBuilder: UntypedFormBuilder,
    private touristLocationsPageService: TouristLocationsPageService,
    @Inject(MAT_DIALOG_DATA) data,
  ) {
    this.touristLocation = data?.touristLocation;

    if (this.touristLocation) {
      this.touristLocationForm.patchValue(this.touristLocation);

      // get tour location image
      if (this.touristLocation.S3Link) {
        Storage.get(this.touristLocation.S3Link).then((res) => (this.imageSignedURL = res));
      }
    }
  }

  @ViewChild('MultiLanguageElement', { read: ElementRef }) set setFocusElement(input: ElementRef | null) {
    this.formGenerateService.setFocus(input);
  }

  get TitleMultiLanguage(): UntypedFormArray {
    return this.touristLocationForm.get('TitleMultiLanguage') as UntypedFormArray;
  }

  get DescriptionMultiLanguage(): UntypedFormArray {
    return this.touristLocationForm.get('DescriptionMultiLanguage') as UntypedFormArray;
  }

  get AddressMultiLanguage(): UntypedFormArray {
    return this.touristLocationForm.get('AddressMultiLanguage') as UntypedFormArray;
  }

  ngOnInit(): void {
    this.getCitiesList();
    this.getCategoryList();
    this.getCountryList();
  }

  getCitiesList() {
    this.subscription.add(
      this.restService.getCities().subscribe((cities: CityModel[]) => {
        this.cities = cities && cities.length ? cities : [];
      }),
    );
  }

  // TODO змінити на отримування туристичних категорій локацій
  getCategoryList() {
    this.subscription.add(
      this.restService.getCategories().subscribe((categories: CategoryResponseModel) => {
        this.categories = categories
          ? categories
          : {
              touristLocationTypeCategories: [],
              businessTypeCategories: [],
            };
      }),
    );
  }

  getCountryList() {
    this.subscription.add(
      this.restService.getCountries().subscribe((countries: CountryModel[]) => {
        this.countries = countries && countries.length ? countries : [];
      }),
    );
  }

  addNewTourLocation() {
    const newTourLocation: AddNewTourLocationModel = {
      TitleMultiLanguage: this.touristLocationForm.value.TitleMultiLanguage,
      DescriptionMultiLanguage: this.touristLocationForm.value.DescriptionMultiLanguage,
      Location: this.touristLocationForm.value.Location,
      CityId: this.touristLocationForm.value.CityId,
      CountryId: this.touristLocationForm.value.CountryId,
      AddressMultiLanguage: this.touristLocationForm.value.AddressMultiLanguage,
      AdditionalInfo: {
        City: this.cities.find((city: CityModel) => city.id === this.touristLocationForm.value.CityId).cityInternalName,
        Category: this.categories.touristLocationTypeCategories.find(
          (category: CategoryModel) => category.id === this.touristLocationForm.value.CategoryId,
        ).categoryInternalName,
        CategoryId: this.touristLocationForm.value.CategoryId,
      },
    };

    this.httpRequestInProgress = true;
    this.subscription.add(
      this.touristLocationsPageService.addTouristLocation(newTourLocation).subscribe(
        (response) => {
          if (response) {
            alert('Нову туристичне місце додано!');
          } else {
            alert('Виникла не передбачування ситуація! Зверніться до адміністратора.');
          }
          this.httpRequestInProgress = false;

          this.dialogRef.close();
        },
        (error) => {
          console.log('error', error);
          alert('Виникла помилка при створенні нового туристичного місця ');
          this.httpRequestInProgress = false;
        },
      ),
    );
  }

  async updateTourLocation() {
    const updatedTourLocation: UpdateTourLocationModel = {
      TitleMultiLanguage: this.touristLocationForm.value.TitleMultiLanguage,
      DescriptionMultiLanguage: this.touristLocationForm.value.DescriptionMultiLanguage,
      Location: this.touristLocationForm.value.Location,
      AddressMultiLanguage: this.touristLocationForm.value.AddressMultiLanguage,
      ItemId: this.touristLocation.ItemId,
      UserId: this.touristLocation.UserId,
      Active: !!this.touristLocationForm.value.Active,
      S3Link: this.touristLocationForm.value.S3Link,
    };

    // upload image to s3 bucket and update tour location s3 property with link
    if (this.selectedTourLocationImage) {
      const key = await this.uploadImage(this.selectedTourLocationImage);

      updatedTourLocation.S3Link = key;
    }

    this.httpRequestInProgress = true;
    this.subscription.add(
      this.touristLocationsPageService.updateTouristLocation(updatedTourLocation).subscribe(
        (response) => {
          if (response) {
            alert('Оновлено інформацію про туристичне місце!');
          } else {
            alert('Виникла не передбачування ситуація! Зверніться до адміністратора.');
          }
          this.httpRequestInProgress = false;

          this.dialogRef.close();
        },
        (error) => {
          console.log('error', error);
          alert('Виникла помилка при редагуванні інформації про туристичне місце');
          this.httpRequestInProgress = false;
        },
      ),
    );
  }

  onFileSelect(file: File): void {
    this.selectedTourLocationImage = file;
    console.log('selected file =>', file);
  }

  async uploadImage(file: File): Promise<string> {
    let signedURL = '';
    let imageName = '';

    if (file.type === 'image/jpg' || file.type === 'image/jpeg' || file.type === 'image/png') {
      try {
        const { UploadURL, Key } = await this.getSignedImageUploadURl(file.type);

        signedURL = UploadURL;
        imageName = Key;

        // create new file with required key name
        const newFileImage = new File([file], imageName, {
          type: file.type,
        });

        // const preparedBlobToUpload = await this.prepareImageForUpload(newFileImage);

        if (signedURL && imageName) {
          // const uploadResult = await fetch(signedURL, {
          //   method: 'PUT',
          //   body: preparedBlobToUpload
          // });
          await Storage.put(imageName, newFileImage, {
            contentType: 'image/*',
            level: 'public',
          })
            .then((result: any) => {
              console.log('image uploaded');
            })
            .catch((err) => {
              alert('Помилка при завантажені зображення.');
            });
        } else {
          alert('Не очікувана помилка');
        }
      } catch (e) {
        alert('Сталась помилка при отриманні авторизованого посилання');
      }
    } else {
      alert('Не вірний формат зображення');
    }

    // upload image
    // simple way
    // await Storage.put(`${key}.${file.type}`, file).then((result: any) => {
    //   console.log('image uploaded');
    // }).catch((err) => {
    //   alert('Error during image upload.');
    // });

    return imageName;
  }

  private prepareImageForUpload(fileImage: File): Promise<BodyInit> {
    return new Promise((resolve, reject) => {
      let blobData;

      const reader = new FileReader();
      reader.onload = (e) => {
        const preparedImage = e.target.result;

        let binary;
        if (typeof preparedImage === 'string') {
          binary = atob(preparedImage.split(',')[1]);
        }
        const array = [];
        for (let i = 0; i < binary.length; i++) {
          array.push(binary.charCodeAt(i));
        }
        blobData = new Blob([new Uint8Array(array)], { type: `image/${fileImage.type}` });

        resolve(blobData);
      };
      reader.readAsDataURL(fileImage);
    });
  }

  private getSignedImageUploadURl(
    fileExtension: 'image/jpg' | 'image/jpeg' | 'image/png',
  ): Promise<{ Key: string; UploadURL: string }> {
    const getImageSignedURLRequestObject: IImageSignedUrlRequestObjectModel = {
      FileExtension: fileExtension,
    };

    return new Promise((resolve, reject) => {
      this.touristLocationsPageService.getImageSignedURLToUploadImage(getImageSignedURLRequestObject).subscribe({
        next: (res) => {
          resolve(res);
        },
        error: () => {
          reject();
        },
      });
    });
  }
}
