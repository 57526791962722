import { Component, OnInit, OnDestroy } from '@angular/core';
import { RestService } from '../../services/restService.service';
import { Subscription } from 'rxjs';
import { CountryModel } from './models/CountryModel';

@Component({
  selector: 'app-country-page',
  templateUrl: './country-page.component.html',
  styleUrls: ['./country-page.component.scss'],
})
export class CountryPageComponent implements OnInit, OnDestroy {
  public countries: CountryModel[];

  private subscription: Subscription = new Subscription();

  constructor(private restService: RestService) {}

  ngOnInit(): void {
    this.getCountries();
  }

  getCountries() {
    this.subscription.add(
      this.restService.getCountries().subscribe((result: CountryModel[]) => {
        if (result) {
          this.countries = result;
        }
      }),
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
