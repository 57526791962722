<div class="registered-users-wrapper">
  <mat-tab-group>
    <mat-tab label="Бізнес користувачі">
      <app-business-users></app-business-users>
    </mat-tab>

    <mat-tab label="Мобільні користувачі">
      <app-mobile-users></app-mobile-users>
    </mat-tab>
  </mat-tab-group>
</div>
