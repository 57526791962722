<section class="business-users-wrapper">
  <table class="table table-striped mt-3">
    <thead>
      <tr>
        <th scope="col">UserId</th>
        <th scope="col">Назва бізнесу</th>
        <th scope="col">Ім'я</th>
        <th scope="col">Прізвище</th>
        <th scope="col">Юридичний статус</th>
        <th scope="col">Контактний телефон</th>
        <th scope="col">Резервний контактний телефон</th>
        <th scope="col">Верифікація</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let user of businessUsers">
        <td>{{ user.UserId || '-' }}</td>
        <td>{{ user.BusinessName || '-' }}</td>
        <td>{{ user.FirstName || '-' }}</td>
        <td>{{ user.LastName || '-' }}</td>
        <td>{{ user.LawStatus || '-' }}</td>
        <td>{{ user.Phone || '-' }}</td>
        <td>{{ user.PhoneReserved || '-' }}</td>
        <td>{{ user.isVerified ? user.IsVerified : false }}</td>
      </tr>
    </tbody>
  </table>
</section>
