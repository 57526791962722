<div class="d-flex justify-content-start align-content-center mt-3">
  <label class="form-group d-block width-fit-content mr-3" for="searchExcursionCountry"
    >Фільтрація по країні
    <select (change)="onCountryChange($event)" class="form-control" id="searchExcursionCountry">
      <option *ngFor="let country of countries" [value]="country.id">
        {{ country.countryMultilanguageName.uk }}
      </option>
    </select>
  </label>

  <label class="form-group d-block width-fit-content mr-3" for="searchExcursionCity"
    >{{ 'excursion-page#filterCity' | translate }}
    <select (change)="onCityChange($event)" class="form-control" id="searchExcursionCity">
      <option *ngFor="let city of cities" [value]="city.id">
        {{ city.cityMultilanguageName.uk }}
      </option>
    </select>
  </label>
</div>
