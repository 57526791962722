<div class="d-flex mt-3">
  <button
    mat-raised-button
    class="mr-3"
    [color]="activeCategoryView === 'businessTypeCategories' ? 'primary' : undefined"
    (click)="activeCategoryView = 'businessTypeCategories'"
  >
    Категорії підтримуваних бізнесів
  </button>
  <button
    mat-raised-button
    [color]="activeCategoryView === 'touristLocationCategories' ? 'primary' : undefined"
    (click)="activeCategoryView = 'touristLocationCategories'"
  >
    Категорії туристичних місць
  </button>
</div>

<table class="table table-striped mt-3" *ngIf="activeCategoryView === 'businessTypeCategories'">
  <thead>
    <tr>
      <th scope="col" class="d-none d-md-table-cell">{{ 'category-page#uniqueId' | translate }}</th>
      <th scope="col">{{ 'category-page#categoryInternalName' | translate }}</th>
      <th scope="col" class="d-none d-xl-table-cell">{{ 'category-page#categoryNameUk' | translate }}</th>
      <th scope="col" class="d-none d-sm-table-cell">{{ 'category-page#categoryNameEn' | translate }}</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let category of categories?.businessTypeCategories">
      <th scope="row" class="d-none d-md-table-cell">{{ category?.id }}</th>
      <td>
        {{ category?.categoryInternalName }}
      </td>
      <td class="d-none d-xl-table-cell">{{ category?.categoryMultiLanguageName.uk }}</td>
      <td class="d-none d-sm-table-cell">{{ category?.categoryMultiLanguageName.en }}</td>
    </tr>
  </tbody>
</table>

<table class="table table-striped mt-3" *ngIf="activeCategoryView === 'touristLocationCategories'">
  <thead>
    <tr>
      <th scope="col" class="d-none d-md-table-cell">{{ 'category-page#uniqueId' | translate }}</th>
      <th scope="col">{{ 'category-page#categoryInternalName' | translate }}</th>
      <th scope="col" class="d-none d-xl-table-cell">{{ 'category-page#categoryNameUk' | translate }}</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let category of categories?.touristLocationTypeCategories">
      <th scope="row" class="d-none d-md-table-cell">{{ category?.id }}</th>
      <td>
        {{ category?.categoryInternalName }}
      </td>
      <td class="d-none d-xl-table-cell">{{ category?.categoryMultiLanguageName.uk }}</td>
    </tr>
  </tbody>
</table>
