import { Component, OnInit, OnDestroy } from '@angular/core';
import { RestService } from '../../services/restService.service';
import { Subscription } from 'rxjs';
import { CityModel } from './models/CityModel';

@Component({
  selector: 'app-city-page',
  templateUrl: './city-page.component.html',
  styleUrls: ['./city-page.component.scss'],
})
export class CityPageComponent implements OnInit, OnDestroy {
  public cities: CityModel[];

  private subscription: Subscription = new Subscription();

  constructor(private restService: RestService) {}

  ngOnInit(): void {
    this.getCities();
  }

  getCities() {
    this.subscription.add(
      this.restService.getCities().subscribe((result: CityModel[]) => {
        if (result) {
          this.cities = result;
        }
      }),
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
