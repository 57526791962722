/// <reference types="@angular/localize" />

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { Amplify, Auth } from 'aws-amplify';

Sentry.init({
  dsn: environment.sentryUrl,
  release: 'cityonpalm_admin@' + environment.version,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  tracesSampleRate: 1.0,
  autoSessionTracking: true,
  tracePropagationTargets: ['localhost', 'https://cityonpalm.com'],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

Amplify.configure(environment.amplifyConfig);
Auth.configure(environment.amplifyConfig);

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
