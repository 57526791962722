<table class="table table-striped mt-3">
  <thead>
    <tr>
      <th scope="col" class="d-none d-lg-table-cell">{{ 'city-page#uniqueId' | translate }}</th>
      <th scope="col">{{ 'city-page#cityInternalName' | translate }}</th>
      <th scope="col" class="d-none d-md-table-cell">{{ 'city-page#cityNameUk' | translate }}</th>
      <th scope="col" class="d-none d-sm-table-cell">{{ 'city-page#cityNameEn' | translate }}</th>
      <th scope="col" class="d-none d-xl-table-cell">{{ 'city-page#cityId' | translate }}</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let city of cities">
      <th scope="row" class="d-none d-lg-table-cell">{{ city.id }}</th>
      <td>
        {{ city.cityInternalName }}
      </td>
      <td class="d-none d-md-table-cell">{{ city.cityMultilanguageName.uk }}</td>
      <td class="d-none d-sm-table-cell">{{ city.cityMultilanguageName.en }}</td>
      <td class="d-none d-xl-table-cell">{{ city.countryId }}</td>
    </tr>
  </tbody>
</table>
