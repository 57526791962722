<div class="static-info-wrapper">
  <app-import-data [staticInfoType]="staticInfoConfig.s3Key" />
  <app-location-selector (citySelected)="onCitySelected($event)" (countrySelected)="onCountrySelected($event)" />

  <table class="table table-striped mt-3">
    <thead>
      <tr>
        <th *ngFor="let column of staticInfoConfig.tableColumns" scope="col">{{ column.columnLabel }}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let staticInfoItem of staticInfo">
        <ng-container *ngFor="let column of staticInfoConfig.tableColumns">
          <ng-container [ngSwitch]="column.type">
            <!-- text -->
            <ng-container *ngSwitchCase="StaticInfoTableColumnType.TEXT">
              <td>{{ staticInfoItem | nestedAttrRender: column.columnKey }}</td>
            </ng-container>

            <!-- bool -->
            <ng-container *ngSwitchCase="StaticInfoTableColumnType.BOOL">
              <td>
                {{ staticInfoItem[column.columnKey] === true ? ('ACTIVE' | translate) : ('NOT#ACTIVE' | translate) }}
              </td>
            </ng-container>

            <!-- location -->
            <ng-container *ngSwitchCase="StaticInfoTableColumnType.LOCATION">
              <td>
                <a
                  *ngIf="staticInfoItem.Location.lat && staticInfoItem.Location.lng"
                  href="https://maps.google.com/?q={{ staticInfoItem.Location.lat }},{{ staticInfoItem.Location.lng }}"
                  target="_blank"
                  ><{{ staticInfoItem.Location.lat }} , {{ staticInfoItem.Location.lng }}></a
                >
              </td>
            </ng-container>

            <ng-container *ngSwitchCase="StaticInfoTableColumnType.LINKS">
              <td>
                <p *ngIf="staticInfoItem.SocialLinks.fb">FB - {{ staticInfoItem.SocialLinks.fb }}</p>
                <p *ngIf="staticInfoItem.SocialLinks.instagram">
                  Instagram - {{ staticInfoItem.SocialLinks.instagram }}
                </p>
                <p *ngIf="staticInfoItem.SocialLinks.youtube">Youtube - {{ staticInfoItem.SocialLinks.youtube }}</p>
              </td>
            </ng-container>

            <ng-container *ngSwitchCase="StaticInfoTableColumnType.PHONES">
              <td>
                <p *ngFor="let phone of staticInfoItem.Phones">{{ phone }}</p>
              </td>
            </ng-container>
          </ng-container>
        </ng-container>
      </tr>
    </tbody>
  </table>
</div>
